<chq-step-header [chqModel]="header"
                 [hideHeader]="hideHeader"
                 (uploadBackHandler)="goBackToSelection();"
                 [hideBack]="hideBack"
                 (back)="showReview()"
                 *ngIf="!isReviewSection"
                 [hideDelete]="true"></chq-step-header>
<div class="main-container"
     (scroll)="onScroll();"
     [ngClass]="{'no-border': isReviewSection, 'document-container': inspection !== 'photos' && (header?.isRepairModule || header?.isClaimModule), 'upload-wizard': (!isReviewSection && !isMassUpload)}">
    <chq-upload [currentStep]="currentStep"
                [currentPageName]="inspection"
                [currentFileNumber]="nonAdditional"
                [isCurrentAdditionalModeMultiple]="currentAdditionalModeMultiple"
                [maxFileAllowed]="maximumImages || 20"
                [ngClass]="{'disabled':currentStep?.uploadInProgress}"
                #chqUpload
                (skipped)="skipUpload();"
                [showSkip]="!currentStep?.mandatory && (!showReTakeDelete || (currentStep?.stepName.indexOf('Additional') !== -1) || (!currentStep?.image && !currentStep?.url))"
                [isMultiple]="isMassUpload"
                (imageOutput)="handleImageOutput($event);"></chq-upload>

    <div class="info-container"
         *ngIf="!isReviewSection && !isMassUpload">

        <div class="header-div">
            <h3 class="header-label text-black">
                {{(currentStep?.isAdditionalDocumentFromReviewPageOnly?(inspection === 'photos'? 'add_additional_image'
                :'add_additional_documents'):currentStep?.stepName) | translate}}
            </h3>
            <mat-icon *ngIf="(currentStep?.uploadStatus ==='success' || currentStep?.url) else unchecked"
                      [svgIcon]="'checked'"
                      aria-hidden="false"
                      aria-label="checked icon"></mat-icon>
            <ng-template #unchecked>
                <mat-icon [svgIcon]="'unchecked'"
                          aria-hidden="false"
                          aria-label="unchecked icon"></mat-icon>
            </ng-template>
        </div>
        <div class="span-container">
            <span class="text-dark-gray">{{currentStep?.isUploadSuccess?'Uploaded':(currentStep?.uploadInProgress?'upload_inprogress':"not_uploaded")
                | translate}}</span>
        </div>
        <div class="block-image">
            <ng-container *ngIf="currentStep?.contentType !=='application/pdf'">
                <div class="car-image-container"
                     *ngIf="currentStep?.image else serverImage">
                    <img [src]="currentStep?.image"
                         (load)="imageLoading[currentStep?.stepName]=true"
                         alt="car" />
                </div>
                <ng-template #serverImage>
                    <div class="car-image-container"
                         *ngIf="currentStep?.url else defaultImage">
                        <img [src]="currentStep?.url"
                             (load)="imageLoading[currentStep?.stepName]=true"
                             alt="container" />
                    </div>
                </ng-template>
                <ng-template #defaultImage>
                    <div class="drag-drop-container">
                        <div class="dd-image-container">
                            <div class="car-image-container default-image">
                                <img [src]="currentStep?.placeHolderUrl || '/assets/icons/additional-image.svg'"
                                     (load)="imageLoading[currentStep?.stepName]=true"
                                     alt="default" />
                            </div>
                        </div>
                        <div class="dd-content">
                            <h3 *ngIf="!platform.ANDROID && !platform.IOS">Drag and Drop</h3>
                            <span>Select file to upload</span>
                        </div>
                    </div>
                </ng-template>
                <chq-custom-loader [showLoading]="!imageLoading[currentStep?.stepName] && (currentStep?.url ? true : false)"></chq-custom-loader>
            </ng-container>

            <!-- PDF Placeholder  -->
            <ng-container *ngIf="currentStep?.contentType ==='application/pdf'">
                <div class='pdf-container'>
                    <mat-icon [svgIcon]="'pdf-file'"
                              aria-hidden="false"
                              aria-label="pdf-file icon"></mat-icon>
                    <div class='pdf-title'>
                        <h3>{{currentStep?.imageFile?.name?.split('.pdf')[0] || currentStep?.fileName?.split('.pdf')[0]}}</h3>
                        <span>Click below to preview the document</span>
                    </div>
                    <chq-button (click)="openPDFinNewTab(currentStep?.url)"
                                [buttonModel]="previewPDFButtonModel"></chq-button>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="vehicle-status-container"
         *ngIf="!isReviewSection && !isMassUpload">
        <chq-vertical-image-preview [maximumImages]="maximumImages"
                                    [page]="this.inspection"
                                    [steps]="imageStatusSteps"
                                    [isMultipleUpload]="isMassUpload"
                                    (selection)="setCurrentStep($event);"></chq-vertical-image-preview>
    </div>
    <div class="mass-upload"
         *ngIf="!isReviewSection && isMassUpload">
        <img src="assets/icons/no-document.svg"
             alt="document" />
        <ng-container *ngIf="!platform.ANDROID && !platform.IOS; else elseTemplate">
            <label for="">{{ 'drag_drop' | translate }}</label>
        </ng-container>
        <ng-template #elseTemplate>
            <label for="">{{ 'upload_here' | translate }}</label>
        </ng-template>


    </div>
    <div class="footer-full-wrapper"
         *ngIf="!isReviewSection">
        <div class="footer-image-wrapper">
            <div class="image-wrapper">
                <img src="assets/img/{{ + (header?.isFnolModule || header?.isQuoteAssessmentModule) ? 'addenda.png': (header?.isRepairModule ?'addenda-repair.png': (header?.isClaimModule) ? 'addenda-claims.png' : 'addenda-quote.png')}}"
                     alt="banner" />
            </div>
        </div>
        <ng-container *chqPermission="!footerOptions?.section ? 'newBooking' : 'repairStages'">
            <div class="footer-button-wrapper"
                 *ngIf="!isMassUpload">
                <xa-button type="outline"
                           color="gray"
                           (click)="commonService.requestCustomer.next(true)"
                           *ngIf="sendRequest === 1 || sendRequest === 2">
                    <span *ngIf="sendRequest === 2"> {{'send_request' | translate }}</span>
                    <span *ngIf="sendRequest === 1"> {{'request_missing_information' | translate }}</span>
                </xa-button>


                <xa-button type="outline"
                           color="gray"
                           *ngIf="header?.isQuoteAssessmentModule && !footerOptions?.showUploadOption"
                           (click)="showReview()">
                    <span> {{'back' | translate }}</span>
                </xa-button>
                <chq-button [buttonModel]="cancelButtonModel"
                            *ngIf="showDelete && header?.isRepairModule && !commonService.isViewOnly"></chq-button>
                <chq-button [buttonModel]="backButtonModel"
                            *ngIf="footerOptions?.showUploadOption === true && !header?.isRepairModule && bothOptionEnabled"></chq-button>
                <chq-button [buttonModel]="skipAllButtonModel"
                            *ngIf="getNonMandatory()"></chq-button>
                <chq-button [buttonModel]="skipButtonModel"
                            *ngIf="!currentStep?.mandatory && (!showReTakeDelete || (currentStep?.stepName.indexOf('Additional') !== -1) || (!currentStep?.image && !currentStep?.url))"></chq-button>
                <chq-button (click)="isCameraEnabled?uploadFile('integrated'):uploadFile()"
                            [buttonModel]="uploadButtonModel"
                            *ngIf="(!showReTakeDelete ||  (currentStep?.stepName.indexOf('Additional') !== -1) || (!currentStep?.image && !currentStep?.url))  && !commonService.isViewOnly && currentStatus"></chq-button>
                <chq-button [buttonModel]="deleteButton"
                            *ngIf="!currentStep?.isLocked && showReTakeDelete  && !commonService.isViewOnly && currentStatus && (currentStep?.stepName.indexOf('Additional') === -1) && (currentStep?.image || currentStep?.url)"></chq-button>
                <chq-button [buttonModel]="retakeButton"
                            (click)="isCameraEnabled?uploadFile('integrated'):uploadFile()"
                            *ngIf="!currentStep?.isLocked && showReTakeDelete  && !commonService.isViewOnly && currentStatus && (currentStep?.stepName.indexOf('Additional') === -1) && (currentStep?.image || currentStep?.url)"></chq-button>

                <ng-container *chqPermission="'newBooking'">
                    <chq-button [buttonModel]="notifyButtonModel"
                                *ngIf="showNotify"></chq-button>
                </ng-container>
            </div>
            <div class="footer-button-wrapper"
                 *ngIf="isMassUpload">
                <chq-button [buttonModel]="cancelButtonModel"
                            *ngIf="showDelete && header?.isRepairModule && !commonService.isViewOnly"></chq-button>
                <chq-button [buttonModel]="backButtonModel"
                            *ngIf="footerOptions?.showUploadOption === true && !header?.isRepairModule && bothOptionEnabled"></chq-button>
                <chq-button [buttonModel]="uploadButtonModel"
                            (click)="uploadFile()"
                            *ngIf="(!showReTakeDelete || this.hideBack ||  (currentStep?.stepName.indexOf('Additional') !== -1) || (!currentStep?.image && !currentStep?.url)) && currentStatus && !commonService.isViewOnly"></chq-button>
                <ng-container *chqPermission="'newBooking'">
                    <chq-button [buttonModel]="notifyButtonModel"
                                *ngIf="showNotify"></chq-button>
                </ng-container>
            </div>
        </ng-container>
    </div>

    <chq-review-section [maximumImages]="maximumImages"
                        [hideAI]="hideAI"
                        [isRepairStage]="isRepairStage"
                        [minimumPhotosRequired]="minimumImagesRequired"
                        (backToUpload)="goBackToSelection();"
                        (handleDeleteAllEvent)="handleAllImagesDelete();"
                        [footerOptions]="footerOptions"
                        [currentAdditionalModeMultiple]="currentAdditionalModeMultiple"
                        [isMultipleUpload]="isMassUpload"
                        [inspectionId]="inspectionId"
                        *ngIf="isReviewSection"
                        #reviewSection
                        [quoteAssessmentStatus]="quoteAssessmentStatus"
                        [header]="header"
                        [filteredSteps]="filteredSteps"
                        [displayOutline]="displayOutline"
                        (completeRepair)="this.completeRepair.emit();"
                        (notify)="this.notify.emit();"
                        [showComplete]="showComplete"
                        [showNotify]="showNotify"
                        (selection)="handleReviewAction($event);"
                        [isQuoteAssessmentModule]="this.header?.isQuoteAssessmentModule"
                        [isRepairComplete]="isRepairComplete"
                        [activateRepairButton]="activateRepairButton"
                        [steps]="imageStatusSteps"
                        (damageAnalysisComplete)="damageAnalysisComplete.emit($event)"
                        (updateAiEstimateStatus)="updateAiEstimateStatus.emit($event)"
                        [sendRequest]="sendRequest"
                        [submitSection]="submitSection"></chq-review-section>
</div>