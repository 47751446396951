<div class="dialog-container-add-manufacturer-suppliers">
    <div class="header">
        <h3 *ngIf="!data.isSupplierModule">{{ 'add_new_manufacturer' | translate }}</h3>
        <h3 *ngIf="data.isSupplierModule">{{ 'add_new_supplier' | translate }}</h3>
        <div class="close-icon"
             (click)="cancel();">
            <mat-icon [svgIcon]="'cross'"></mat-icon>
        </div>
    </div>
    <div mat-dialog-content
         class="adjustment-body">
        <div class="form-container">
            <div class="input-wrapper"
                 [formGroup]="formGroup">
                <xa-text [placeholder]="data.isSupplierModule ? 'supplier_name': 'manufacturer_name'"
                         [label]="data.isSupplierModule ? 'supplier_name': 'manufacturer_name'"
                         formControlName="name"
                         ngDefaultControl
                         [disabled]="this.data?.rowData?.Id"
                         [required]="true"></xa-text>
                <xa-select *ngIf="data.isSupplierModule"
                           formControlName="type"
                           [options]="data.typeList"
                           [config]="marketTypeConfig"
                           class="type-select">
                </xa-select>
            </div>
        </div>
        <div class="btn-container">
            <ng-container>
                <xa-button size="md"
                           class="right"
                           [disabled]="!name || (data.isSupplierModule && (!typeId || typeId?.length ===0))"
                           (click)="submitForm()">
                    <mat-icon [svgIcon]="this.data?.rowData ? 'edit':'add-circle-outline'"></mat-icon>
                    {{ this.data?.rowData ? 'update_supplier' : 'add' |translate}}
                </xa-button>
            </ng-container>
        </div>
    </div>
</div>