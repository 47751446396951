<table mat-table
       matSort
       [dataSource]="dataSource"
       matSortDirection="asc"
       (matSortChange)="sortData($event)"
       matSortDisableClear>
  <ng-container *ngFor="let column of (columns$ | async); index as i;"
                matColumnDef="{{column.id}}">
    <mat-header-cell *matHeaderCellDef
                     mat-sort-header
                     [disabled]="!column.sortable"
                     [style.min-width]="getColumnWidthPlusSpacing(column)"
                     [style.max-width]="getColumnWidthPlusSpacing(column)"
                     [style.padding-right]="this.config?.columnSpacing ?? '5px'"
                     [style]="column.style">
      <div class="mat-header-container"
           *ngIf="column.header.text === ''">
        <div [style]="column.header.style">
          <mat-checkbox #masterCheckbox
                        (change)="toggleMasterSelection($event.checked)"
                        [checked]="allSelected"></mat-checkbox>
        </div>
      </div>
      <div class="mat-header-container"
           *ngIf="column.header.text !== ''">
        <span [style]="column.header.style">{{ column.header.text | translate:{currency: '(' + i18n.currencySymbol + ')'} | uppercase}}</span>
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let row"
              (click)="handleCellClicked(row, column, $event)"
              [style]="column?.cellStyle"
              [style.min-width]="getColumnWidthPlusSpacing(column)"
              [style.max-width]="getColumnWidthPlusSpacing(column)"
              [style.padding-right]="column.sortable ? '17px': '17px'">
      <div [style]="column?.widget?.config?.style"
           *ngIf="!column?.childColumns">
        <div *ngIf="column?.widget?.type === 'text'"
             [ngStyle]="{'font-weight': checkStyle(row)}"
             class="text-ellipsis-2">{{row[i]?.result?.value}}</div>
        <div *ngIf="column?.widget?.type === 'date'">{{row[i]?.result?.value | date:'dd/MM/YYYY'}}</div>
        <div *ngIf="column?.widget?.type === 'dateTime'">{{row[i]?.result?.value | date: column?.widget?.dateFormat || 'dd/MM/YYYY HH:mm:ss'}}</div>
        <div *ngIf="column?.widget?.type === 'number'">
          {{row[i]?.result?.value | number : i18n.digitsInfo : i18n.locale }}
        </div>
        <div *ngIf="column?.widget?.type === 'currency'">
          {{row[i]?.result?.value | xaCurrency:i18n.currencySymbol:i18n.digitsInfo:i18n.locale:i18n.position}}
        </div>
        <div *ngIf="column?.widget?.type === 'html'"
             [innerHTML]="row[i]?.result?.value"></div>
        <div *ngIf="column?.widget?.type.toLowerCase() === 'status'">
          <div class="case-status "
               [ngClass]="removeSpacing(row[i]?.result?.value?.split('$#$')[0])"
               style="margin-bottom: 5px;">
            {{row[i]?.result?.value?.split('$#$')[0]}}
          </div>
          <span *ngIf="row[i]?.result?.value?.split('$#$')[1] && row[i]?.result?.value?.split('$#$')[1] !== '-'"
                class="case-status integrated">
            {{row[i]?.result?.value?.split('$#$')[1]}}
          </span>
        </div>
        <div *ngIf="column?.widget?.type === 'checkbox'">
          <mat-checkbox [checked]="selection.isSelected(row[i])"
                        (change)="selection.toggle(row[i])"></mat-checkbox>
        </div>
        <div *ngIf="column?.widget?.type === 'status-check-icon' && checkStatus(row)">
          <mat-icon class="cross"
                    [svgIcon]="column.widget.config.svgIcon"
                    [style]="column?.widget?.config?.style"></mat-icon>
        </div>
        <div *ngIf="column?.widget?.type === 'icon'">
          <mat-icon [svgIcon]="column.widget.config.svgIcon"
                    [style]="column?.widget?.config?.style"></mat-icon>
        </div>
        <div *ngIf="column?.widget?.type === 'iconGroup'"
             class="row-icons"
             [style]="column?.widget?.config?.style">
          <div *ngFor="let item of row[i]?.result?.value">
            <mat-icon color="secondary"
                      [svgIcon]="item"></mat-icon>
          </div>
        </div>
        <div *ngIf="column?.widget?.type === 'dropdown'">
          <div class="flex align-center justify-space-between">
            <div *ngIf="row[actionIndex]?.result?.value !== 'edit'">
              {{row[i]?.result?.value?.value}}
            </div>
            <div *ngIf="row[actionIndex]?.result?.value === 'edit'"
                 class="cell-dropdown">
              <chq-input [inputModel]="row[i]?.result.value"
                         (controlOutput)="ondropdownChange($event, row[i], row)">
              </chq-input>
            </div>
            <div *ngIf="!row[i]?.result?.value?.editHide">
              <mat-icon class="cross"
                        [svgIcon]="row[actionIndex]?.result?.value === 'edit' ? 'check' : 'edit'"
                        (click)="changeValue(row[i], i, row)"></mat-icon>
            </div>
          </div>

        </div>
        <div *ngIf="column?.widget?.type === 'AIAnalysis'">
          <ng-container *ngIf="row[i]?.result?.value === null; else showFlag">
            -
          </ng-container>
          <ng-template #showFlag>
            <mat-icon svgIcon="report-flag" [ngClass]="{'green-flag': row[i]?.result?.value?.toLowerCase() === 'damage not detected', 'red-flag': row[i]?.result?.value?.toLowerCase() === 'damage detected', 'gray-flag': row[i]?.result?.value?.toLowerCase() === 'failed' }" [matTooltip]="row[i]?.result?.value"></mat-icon>
          </ng-template>
          
        </div>
      </div>
      <div [style]="column?.widget?.config?.style"
           *ngIf="column?.childColumns">
        <ng-container *ngFor="let child of column.childColumns">
          <mat-icon color="secondary"
                    title="{{(!row[i]?.result?.value) ? child.selector.field + ' is not available': ''}}"
                    [ngClass]="{ 'disable-icons': !row[i]?.result?.value && child.disableColumns}"
                    [svgIcon]="child?.widget?.config?.svgIcon"
                    (click)="handleCellClicked(row, child, $event)"></mat-icon>
        </ng-container>
      </div>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>

  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  <div *matNoDataRow
       class="no-data"><span *ngIf="tableData && tableData.length === 0">{{'no_data_available' | translate}}</span></div>
</table>