import { Component, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImgDialogComponent } from 'src/app/dialogs/img-dialog/img-dialog.component';
import { VideoPlayerComponent } from 'src/app/dialogs/video-player/video-player.component';
import { uploadEventResult, UploadStepModel } from 'src/app/model/chq-upload-model';
import { CommonService } from 'src/app/services/common/common.service';
import { VideoComponent } from '../video/video.component';
import { CameraComponent } from '../camera/camera.component';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { allowExtension, minimumSizeLimit, pdfSizeLimit, photosSizeLimit } from 'src/app/config/constants/app.constants';
import { IconActions, XADocuments } from '../xa-photo-upload-mode-selecter/xa-file-upload.model';
import { XaDocumentComponent } from '../xa-documents/xa-documents.component';
import { QuoteManagementService } from 'src/app/services/quote-management/quote-management.service';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'media-upload',
  templateUrl: './media-upload.component.html',
  styleUrls: [ './media-upload.component.scss' ]
})
export class MediaUploadComponent implements OnInit, OnChanges {
  @Input() title:string;
  @Input() image:string = 'assets/img/video_placeholder.png';
  @Input() outlineImage:string = 'assets/img/outline_video.png';
  @Input() name:string;
  @Input() subtitle:string;
  @Input() description:string;
  @Input() buttonText:string;
  @Input() reviewTitle:string;
  @Input() module:'document' | 'image' | 'video';
  @Input() uploadStatus: string = 'Not Uploaded';
  @Input() maxFileSize: number = 100000000 * 2;
  @Input() status: string;
  @Input() steps:UploadStepModel[] = [];
  @Input() objectId:number;
  @Input() domainID:number;
  @Input() hideOutlineImg:boolean;
  @Input() options:any;
  @ViewChild('video') video: VideoComponent;
  @ViewChild('camera') camera: CameraComponent;
  @ViewChild('document') document:XaDocumentComponent;

  @Output() videoUploaded: EventEmitter<{ 'file'?: File, timeStamp?: string, 'action'?: string }> = new EventEmitter();
  @Output() fileUploaded: EventEmitter<uploadEventResult> = new EventEmitter();
  @Output() documentStepsLoaded: EventEmitter<any> = new EventEmitter();
  @Output() documentFileUploaded: EventEmitter<any> = new EventEmitter();
  @Output() nextStep: EventEmitter<any> = new EventEmitter();

  platform = inject(Platform);

  public selectedFile:File;

  public processingVideo = true;
  public localVideoUrl = '';
  public serverVideoUrl = '';
  private videoTimeStamp = '';
  public currentStep:UploadStepModel;
  private currentIndex = 0;
  private isFromReview:boolean;

  private documentPlaceHolder = {
    'vehicle_registration': 'registration_card',
    'driving_license': 'driving_license'
  }
  

  files:UploadStepModel[] = [];

  public videoSteps = [ {
    text: '<strong>Capture Odometer:</strong> Click on record to capture Odometer',
    isCompleted: false,
    title: 'ODOMETER'
  }, {
    text: '<strong>Capture Plate Number:</strong> Continue recording to capture Plate Number',
    isCompleted: false,
    title: 'Plate Number'
  }, {
    text: '<strong>Capture VIN:</strong> Continue recording to capture VIN',
    isCompleted: false,
    title: 'VIN Number'
  }, {
    text: '<strong>Capture 360 Vehicle:</strong> Continue recording to capture 360 Vehicle',
    isCompleted: false,
    title: '360 Video'
  } ];

  xaDocuments: XADocuments = {
    objectId: 0,
    domainId: 4100,
    currentPage: 'document',
    displayReviewSection: true,
    onDocumentDataLoad: this.emitDocumentLoad.bind(this),
    fileReviewSection: {
      domainId: 4100,
      inspectionId: 0,
      displayDialog: true,
      files: [],
      type: 'document',
      multipleUpload: false,
      permissionTag: '',
      onFileSelection: this.handleUploadDocument.bind(this),
      iconContainerClass: '',
      maxFileLength: 20,
      minFileLength: 8,
      enableBulk: false,
      enableSPI: true,
      isEditable: true,
      bulkUploadInspectionItemId: 0,
      objectId: 0,
      templateId: 0,
      aiReviewSection: false,
      footerActions: [
          
      ],
      headerActions: [
      ],
      actions: [
          
      ],
      objectType: '',
      label: ''
    }
  }

  /**
   * ngOnChanges
   */
  ngOnChanges(changes: SimpleChanges): void {
    this.monitorService.logEvent('ngOnChanges', [ 'Media upload component', 'addenda', {
      changes
    } ]);
    if (changes['steps']?.currentValue && !this.currentStep) {
      const hasFiles = this.steps?.some(x=>x?.url);
      if(hasFiles){
        this.files = [ ...this.steps ];
      }
      this.currentStep = this.steps[this.currentIndex];
    }
  }

  /**
   * 
   * @param url 
   */
  download(document): void {
    let documentUrl = '';
    if(document.image) {
      documentUrl = document.image;
    } else if(document.url) {
      documentUrl = document.url;
    } 
    
    if(documentUrl) {
      window.open(documentUrl, '_blank');
    }
  }

  /**
   * get placeholder
   */
  get documentPlaceHolderURL():string{
    if(this.module === 'document'){
      const stepName = this.currentStep?.stepName;
      if(!stepName){
        return '';
      }
      const keys = Object.keys(this.documentPlaceHolder);
      const currentKey = keys.find(x=>stepName?.toLowerCase().replace(' ', '_').includes(x));
      return currentKey ? `assets/img/${this.documentPlaceHolder[currentKey]}.png`: this.image;
    }

    return this.image;
  }

  /**
   * emit document load
   */
  emitDocumentLoad(media, steps):void{
    this.documentStepsLoaded.emit({ steps });
  }

  /**
   * 
   * @param url constructor
   */
  constructor(private dialog:MatDialog, private commonService:CommonService,
    private quoteAssessmentService:QuoteManagementService,
    private monitorService:MonitorService
  ) { }

  /**
   * ng onInit
   */
  ngOnInit(): void {
    if(this.steps?.length > 0){
      this.currentStep = this.steps[this.currentIndex];
    }
    if(this.module === 'document'){
      this.xaDocuments = { ...this.xaDocuments, objectId: this.objectId, domainId: this.domainID, options: this.options };
      this.xaDocuments.fileReviewSection = { ...this.xaDocuments.fileReviewSection, domainId: this.domainID, objectId: this.objectId, label: '' };
      this.xaDocuments = { ...this.xaDocuments, objectId: this.objectId, domainId: this.domainID };
    }
    const hasFiles = this.steps?.some(x=>x?.url);
    if(hasFiles){
      this.files = [ ...this.steps ];
    }
  }
  
  /**
   * handle document upload
   */
  handleUploadDocument(file?:any):void{ 
    if(this.currentIndex < (this.steps.length -1)){
      this.steps[this.currentIndex].size = file?.size/ (1000 * 1000);
      this.steps[this.currentIndex].extension= file?.type?.replace('image/', '')?.replace('application/', '');
      this.currentIndex++;
      this.currentStep = this.steps[this.currentIndex];
      this.documentFileUploaded.emit(this.steps);
    }else{
      this.steps[this.currentIndex].size = file?.size / (1000 * 1000);
      this.steps[this.currentIndex].extension= file?.type?.replace('image/', '')?.replace('application/', '');
      this.files = this.steps;
    }
  }

  /**
   * outline url
   */
  get outlineUrl():string{
    return this.currentStep ? `${this.module === 'image' ? ((this.currentStep?.overlayPrefilled || this.currentStep?.overlayImage?.rawAzureBlobUrl) || 
      `/assets/icons/outlines/${this.currentStep.stepName?.replace(' ', '-')?.toLowerCase()}.svg`)
      : '/assets/icons/outlines/document.svg'}` : '/assets/img/addenda-white.svg';
  }

  /**
   * handle image skip
   */
  handleImageSkip():void{
    if(this.currentIndex < (this.steps.length -1) && !this.isFromReview){
      this.currentIndex++;
      this.currentStep = this.steps[this.currentIndex];
      this.currentStep = { ...this.currentStep };
      setTimeout(()=>{
        this.camera?.fillImage();
        if(this.camera){
          this.camera.currentImageIndex = this.currentIndex;
        }
        
      });
    }else{
      this.isFromReview = false;
      this.files = this.steps;
      this.camera?.closeCamera();
    }
  }

  /**
   * Opens a dialog with the media content.
   * @param url the media url
   */
  openAttachment(url?:any, name?:string, state?:string, step?:UploadStepModel, isDocument?:boolean):void{
    if(isDocument){
      if(this.module === 'video'){
        if(this.currentStep?.url){
          this.videoUploaded.emit({ 'file': this.selectedFile, 'action': 'delete' });
        }else{
          this.showDeleteConfirmation(this.currentStep, this.module);
        } 
      }else if(this.module === 'image'){
        this.fileUploaded.emit({
          action: 'delete', step,
          image: '',
          fileName: '',
          file: undefined
        });
      }else{
        this.document.showDeleteConfirmation(step);
      }
      return;
    }
    if(this.module === 'video'){
      this.dialog.open(VideoPlayerComponent, {
        data: {
          videoUrl: this.localVideoUrl || step?.url,
          delete: true
        }, 
        autoFocus: false,
        height: '40vh',
        width: '80vw'
      }).afterClosed().subscribe((res)=>{
        if(res?.delete){
          if(this.currentStep?.url){
            this.videoUploaded.emit({ 'file': this.selectedFile, 'action': 'delete' });
          }else{
            this.showDeleteConfirmation(this.currentStep, this.module);
          } 
          
        }
      });
    }else{
      const dialogRef = this.dialog.open(ImgDialogComponent, {
        data: {
          state: step.isUploadSuccess ? 'Uploaded': state,
          delete: true,
          title: name,
          image: url
        }, autoFocus: false
      });
      dialogRef.afterClosed().subscribe((res)=>{
        if(res?.delete){
          if(this.module === 'image'){
            this.fileUploaded.emit({
              action: 'delete', step,
              image: '',
              fileName: '',
              file: undefined
            });

          }else{
            this.document.showDeleteConfirmation(step);
          }
        }
      });
    }
  }

  /**
   * handle file upload
   * @param event 
   */
  handleUpload(event: any): void {
    const currentFile = event.target.files || event.srcElement.files;
    this.processingVideo = true;
    
    if (currentFile !== null && currentFile !== '' && currentFile.length > 0) {
      if (!this.checkFileSize(currentFile[0])) {
        const message = 'Video size is above the allowed 200MB limit, try again with a smaller video';
        this.commonService.showToast(0, message);
        this.processingVideo = false;
        return;
      }
      this.localVideoUrl = '';
      setTimeout(() => {
        this.serverVideoUrl = '';
        if(event.timestamps){
          this.videoTimeStamp = JSON.stringify(event.timestamps);
        }
        
        this.selectedFile = currentFile[0];
        this.localVideoUrl = URL.createObjectURL(this.selectedFile);
        this.processingVideo = false;
        this.files = this.steps;
        if(this.files[0]){
          this.files[0].size = this.selectedFile?.size/(1000 * 1000);
          this.files[0].extension = this.selectedFile.name.substring(this.selectedFile.name.lastIndexOf('.') + 1, this.selectedFile.name.length);
        }
      }, 10);
    }
  }

  /**
   * checks file size
   * @param blob 
   * @returns boolean
   */
  checkFileSize(file: Blob): boolean {
    if (file.size > this.maxFileSize) {
      return false;
    }
    return true;
  }
  
  /**
   * max mb
   */
  get maxMbAllowed(): number {
    return this.maxFileSize/(1000*1000);
  }

  /**
     * checks file size
     * @param blob
     * @returns boolean
     */
  checkImageFileSize(img: Blob): boolean {
    const sizeLimit = img.type === 'application/pdf' ? pdfSizeLimit : photosSizeLimit;
    const sidesToSkip = [ 'chassis number', 'mileage' ]
    if (img.size > sizeLimit || (img.size < minimumSizeLimit && this.module === 'image' && !sidesToSkip.includes(this.currentStep?.stepName?.toLowerCase()))) {
      this.monitorService.logEvent('checkFileSize', [ 'ChqUploadComponent', 'addenda-quote', {
        message: 'invalid file size.'
      } ]);
      return false;
    }
    return true;
  }

  /**
   * sides
   */
  get sides():UploadStepModel[]{
    return this.steps?.filter(x=>!x.stepName?.toLowerCase().includes('video')) || [];
  }

  /**
   * return if step is mandatory
   * @param step
   */
  isStepMandatory(step): boolean {
    if (this.module === 'document') {
      return step.mandatory;
    } else {
      return !step.isSkipEnabled;
    }
  }

  /**
   * return if any one step is mandatory
   * @param step
   */
  isMandatory(): boolean {
    if (this.module === 'document') {
      return this.files?.some(x => x.mandatory);
    } else {
      return this.files?.some(x => !x.isSkipEnabled);
    }
  }

  /**
   * save Video and next
   */
  triggerNext():void{
    if(this.module === 'video'){
      let isValid = true
      if(!this?.files[0]?.url && !this?.localVideoUrl && !this?.files[0]?.isSkipEnabled) {
        isValid = false;
        this.commonService.showToast(0, 'Required fields are missing or invalid');
      }
      if(!isValid){
        return;
      }
      if(this.selectedFile && this.localVideoUrl){
        this.videoUploaded.emit({ 'file': this.selectedFile, 'timeStamp': this.videoTimeStamp, 'action': 'add' });
      }else{
        this.nextStep.emit();
      }
    }else{
      if(this.module ==='image'){
        let isValid = true;
        this.files?.forEach((ele: any) => {
          if(!ele.url && !ele.isSkipEnabled) {
            isValid = false;
            this.commonService.showToast(0, 'Required fields are missing or invalid');
            return;
          }
        });
        if(!isValid){
          return;
        }
      }else{
        let isValid = true;
        this.files?.forEach((ele: any) => {
          if(!ele.url && ele.mandatory) {
            isValid = false;
            this.commonService.showToast(0, 'Required fields are missing or invalid');
            return;
          }
        });
        if(!isValid){
          return;
        }
      }
      this.nextStep.emit();
    }
  }

  /**
   * reset files
   */
  resetFiles():void{
    const hasFiles = this.steps?.some(x=>x.url);
    if(hasFiles){
      this.files = [ ...this.steps ];
    }else{
      this.files = [];
    }
    this.currentIndex = 0;
    this.currentStep = this.steps[this.currentIndex];
  }

  /**
   * set current camera index
   */
  setCameraCurrentIndex():void{
    setTimeout(()=>{
      if(this.camera){
        this.camera.currentImageIndex = this.currentIndex;
      }
    });
  }

  /**
   * reset files
   */
  setFile(index:number):void{
    this.currentIndex = index;
    this.currentStep = this.steps[this.currentIndex];
  }

  /**
   * show delete confirmation
   */
  showDeleteConfirmation(uploadStepModel: UploadStepModel, module:string): void {
    this.commonService.openDeleteDialog((module == 'image') ? 'photo' : module, '')
      .afterClosed().subscribe((data) => {
        if (data) {
          if(!uploadStepModel.url){
            uploadStepModel.size = null;
            uploadStepModel.extension = '';
            this.selectedFile = null;
            this.videoTimeStamp = '';
            this.localVideoUrl = '';
            this.serverVideoUrl = '';
            this.status = '';
          }
        }
      });
  }

  /**
   * trigger upload
   */
  triggerUpload(index?:number):void{
    if(index || index === 0){
      this.currentIndex = index;
      this.currentStep = this.steps[this.currentIndex];
    }
    if(this.module === 'video'){
      this.video.startCamera();
    }else if(this.module === 'image'){
      if(index || index === 0){
        this.isFromReview = true;
      }
      this.camera.currentImageIndex = this.currentIndex;
      this.camera.startCamera();
    }else{
      this.document.handleAction({ step: this.currentStep, type: 'upload', action: { iconAction: IconActions.upload } });
    }
  }

  /**
     * checkx filename
     * @param filename
     * @returns
     */
  public isValidImageFile(filename: string): boolean {
    const regex: RegExp = new RegExp('^.*\\.[a-zA-Z]+$', 'gm');
    if (regex.test(filename)) {
      const extension = filename.split('.').pop();
      const fileExtension = this.currentStep ? this.currentStep.allowExtension : allowExtension;
      return fileExtension.includes(extension?.toLowerCase());
    }
    return false;
  
  }

  /**
     * handle file upload
     * @param event
     */
  handleImageUpload(event: any): void {
    this.monitorService.logEvent('handleUpload', [ 'ChqUploadComponent', 'addenda-quote', {
      event
    } ]);
    const currentFile = event.target.files || event.srcElement?.files || event.dataTransfer?.files;
  
    if (currentFile !== null && currentFile !== '' && currentFile?.length > 0) {
      this.commonService.showLoading();
      if (!this.isValidImageFile(currentFile[0].name)) {
        const message = 'Failed to upload file; the format is not supported.';
        this.commonService.showToast(0, message);
        this.commonService.hideLoading();
        return;
      }

      if (!this.checkImageFileSize(currentFile[0])) {
        let message = 'For an accurate A.I. analysis please upload image which are not smaller than 200 KB and do not exceed 5 MB';
        if(this.module !== 'image'){
          if(currentFile[0].type === 'application/pdf'){
            message = `pdf is not of the correct size.  Please provide valid file size  (Max : ${Math.round(pdfSizeLimit/(1024*1024))} MB)`;
          }else{
            message = `Image is not of the correct size.  Please provide valid file size  (Max : ${Math.round(photosSizeLimit/(1024*1024))} MB)`;
          }
        }
        this.commonService.showToast(0, message);
        this.commonService.hideLoading();
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(currentFile[0]);
      reader.onload = (_event: any): void => {
        this.commonService.hideLoading();
        if(event.target.step){
          const targetIndex = this.steps.findIndex(x=>x.stepName === event.target.step.stepName);
          if(this.steps[targetIndex]){
            this.steps[targetIndex].extension = currentFile[0].type.replace('image/', '');
            this.steps[targetIndex].size = currentFile[0].size/(1000 * 1000);
          }
          
        }
        this.fileUploaded.emit({ image: reader.result, action: 'add', fileName: currentFile[0].name, file: currentFile[0], step: event.target.step });
        if(this.currentIndex < (this.steps.length - 1) && !this.isFromReview){
          this.currentIndex++;
          this.currentStep = this.steps[this.currentIndex];
          this.currentStep = { ...this.currentStep };
          setTimeout(()=>{
            this.camera?.fillImage();
            this.camera.currentImageIndex = this.currentIndex;
          });
        }else{
          this.isFromReview = false;
          this.files = this.steps;
          this.camera?.closeCamera();
        }
      }

      reader.onerror = (_event:any):void =>{
        this.commonService.hideLoading();
      }
  
    } else {
      return;
    }
  }

  /**
   * processing video
   */
  processingVideoHandler():void{
    this.processingVideo = true;
    this.localVideoUrl = '';
    this.serverVideoUrl = '';
    this.status = '';
  }
}
