import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, } from 'rxjs';
import { ApiPagination } from 'src/app/widgets/xa-paginator/xa-paginator.component';
import { environment } from 'src/environments/environment';
import { FileService } from '../file/file.service';
import * as dayjs from 'dayjs';

@Injectable({
  providedIn: 'root'
})
export class ClaimsService {
  claimDashboardFilters$ = new BehaviorSubject({});
  dashboardGarageList$ = new BehaviorSubject([]);
  /**
   * @constructor
   * @param {HttpClient} http
   */
  constructor(private http: HttpClient, private fileService: FileService) { }

  /**
 * getCustomer
 * @returns Observable
 */
  public getCustomer(id: number): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/customer?$filter=OrgId eq ${id}`;
    return this.http.get(url);
  }

  /**
   * get claim detail
   * @param id claim guid
   * @returns observable
   */
  public getClaimDetail(id: string, headers: any = {}): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Claim/details/${id}`;
    return this.http.get(url, { headers });
  }

  /**
   * get claim detail
   * @param id claim guid
   * @returns observable
   */
  public updateClaimDetails(payload: any): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Claim/update`;
    return this.http.put(url, payload);
  }

  /**
   * getClaimList
   * @returns Observable
   *
   * @param pagination
   * @param queryParams
   * @returns
   */
  getClaimList(pagination: ApiPagination, queryParams: string = null): Observable<any> {
    const skip = (pagination.currentPage - 1) * pagination.pageSize;
    queryParams = (!queryParams) ? `$top=${pagination.pageSize}&$skip=${skip}` : `$top=${pagination.pageSize}&$skip=${skip}&${queryParams}`
    const orderBy = `&$orderby=${pagination.orderBy}`
    const select = '$select=AssigneeId, AssigneeName, ClaimGuid, ClaimNo, CreatedDate, CustomerName, FnolId, Id, PolicyNo, Severity, Status, VehicleName, VehicleNumber, CreatedByUserName, IsAssignedBack, CreatedUserId&';
    const url = `${environment.fnolApiUrl}/odata/Claim?${select}${queryParams}${orderBy}`;
    return this.http.get(url);
  }

  /**
   *
   * @param queryParams
   * @returns
   */
  getTotalCountClaim(queryParams: string = ''): Observable<any> {
    const url = `${environment.fnolApiUrl}/odata/Claim/$count?${queryParams}`;
    return this.http.get(url);
  }

  /**
   * getListClaimReviewers
   * @returns
   */
  getListClaimReviewers(): Observable<any> {
    const url = `${environment.autoCoreApi}/api/Users/claim-reviewer`;
    return this.http.get(url);
  }

  /**
   * getListClaimReviewers
   * @returns
   */
  setAssignee(AssigneeObject: any): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Claim/assignment`;
    return this.http.put(url, AssigneeObject);
  }

  /**
   * get top 3 latest claim list
   * @returns
   */
  getTopFiveClaimList(): Observable<any> {
    const url = `${environment.fnolApiUrl}/odata/Claim?$top=5&$orderby=CreatedDate desc`;
    return this.http.get(url);
  }

  /**
    Returns an Observable that emits any data or error that occurs during the execution of the function.
    @returns {Observable<any>}
  */
  getLookup(lookupName: string): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/lookup/${lookupName}`;
    return this.http.get(url);
  }

  /**
    Returns an Observable that emits any data or error that occurs during the execution of the function.
    @returns {Observable<any>}
  */
  getLookupByType(lookupName: string): Observable<any> {
    const url = `${environment.autoCoreApi}/api/lookup/type?type=${lookupName}`;
    return this.http.get(url);
  }

  /**
    Returns an Observable that emits any data or error that occurs during the execution of the function.
    @returns {Observable<any>}
  */
  getStates(countyId: number): Observable<any> {
    const url = `${environment.autoCoreApi}/api/Countries/county/${countyId}`;
    return this.http.get(url);
  }

  /**
    Returns an Observable that emits any data or error that occurs during the execution of the function.
    @returns {Observable<any>}
  */
  getCountries(): Observable<any> {
    const url = `${environment.autoCoreApi}/api/Countries`;
    return this.http.get(url);
  }

  /**
    Returns an Observable that emits any data or error that occurs during the execution of the function.
    @returns {Observable<any>}
  */
  getCities(stateId: number): Observable<any> {
    const url = `${environment.autoCoreApi}/api/Countries/county/city/${stateId}`;
    return this.http.get(url);
  }

  /**
   * @param queryParams
   * @returns
   */
  getAssignedClaimList(pagination: ApiPagination, queryParams: string = null): Observable<any> {
    const skip = (pagination.currentPage - 1) * pagination.pageSize;
    queryParams = (!queryParams) ? `$top=${pagination.pageSize}&$skip=${skip}` : `$top=${pagination.pageSize}&$skip=${skip}&${queryParams}`
    const orderBy = `&$orderby=${pagination.orderBy}`
    const select = '$select=AssigneeId, AssigneeName, ClaimGuid, ClaimNo, CreatedDate, CustomerName, FnolId, Id, PolicyNo, Severity, Status, VehicleName, VehicleNumber, CreatedByUserName, IsAssignedBack, CreatedUserId&';
    const url = `${environment.fnolApiUrl}/odata/Claim?${select}${queryParams}${orderBy}`;
    const headers = new HttpHeaders().append('isAssigned', 'true');
    return this.http.get(url, { headers: headers });
  }

  /**
   *
   */
  getTotalCountAssignedClaim(queryParams: string = ''): Observable<any> {
    const url = `${environment.fnolApiUrl}/odata/Claim/$count?${queryParams}`;
    const headers = new HttpHeaders().append('isAssigned', 'true');
    return this.http.get(url, { headers: headers });
  }

  /**
   *
   * @returns
   */
  getCustomerByOrgId(orgId: string): Observable<any> {
    const url = `${environment.fnolApiUrl}/odata/Claim?$apply=filter(OrgId eq ${orgId} AND CustomerName ne null)/groupby((CustomerName))`;
    return this.http.get(url);
  }

  /**
   *
   * @returns
   */
  getAssignee(): Observable<any> {
    const url = `${environment.autoCoreApi}/api/Users/org-users`;
    return this.http.get(url);
  }


  /**
   * getAssignedStatusCount
   * @returns
   */
  getAssignedStatusCount(): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Claim/statusCount`;
    return this.http.get(url);
  }

  /**
   * getPolicyDetails
   * @returns
   */
  getPolicyDetails(payload): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Claim/policyDetails`;
    return this.http.get(url, {
      headers: payload
    });
  }

  /**
   * Save new Claim
   * @returns Observable
   */
  public createClaim(claimFormData: any): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Claim/create`;
    return this.http.post(url, claimFormData);
  }

  /**
   * getClaimLookups
   * @returns
   */
  getClaimLookups(LookupName: any): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/lookup/${LookupName}`;
    return this.http.get(url);
  }

  /**
   * getGarageList
   * @returns
   */
  getGarageList(query = '', payload = {}): Observable<any> {
    const url = `${environment.autoCoreApi}/odata/garage?${query}`;
    return this.http.get(url, {
      headers: payload
    });
  }

  /**
   * getClaimsOfAGarage
   * @returns
   */
  getClaimsOfAGarage(query = ''): Observable<any> {
    const url = `${environment.fnolApiUrl}/odata/Garage?${query}`;
    return this.http.get(url);
  }

  /**
   * uploadDocument
   * @returns
   */
  uploadDocument(payload): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Claim/document`;
    const formData = new FormData();
    formData.append('claimGuid', payload.claimGuid);
    if (payload.files && payload.files.length > 0) {
      payload.files.forEach((file) => {
        formData.append('Attachments', file);
      });
    }
    return this.fileService.uploadFile(url, formData, false);
  }

  /**
   * uploadDocument
   * @returns
   */
  removeDocument(payload): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Claim/document`;
    return this.http.delete(url, {
      headers: payload
    });
  }

  /**
   * uploadDocument
   * @returns
   */
  assignGarage(type, payload): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Claim/garageAssignment `;
    return this.http[type](url, payload);
  }

  /**
   *
   * @param claimGuid
   * @param status
   * @param reason
   * @returns
   */
  updateClaimStatus(claimGuid: string, status: string, reason?: string): Observable<any> {
    const request = {
      status,
      reason
    }
    const url = `${environment.fnolApiUrl}/api/Claim/${claimGuid}/status`;
    return this.http.put(url, request);
  }

  /**
   *
   * @param inspectionId
   */
  getMissingMediaFields(inspectionId: any): Observable<any> {
    const url = `${environment.autoApi}/api/v1/Inspection/items/${inspectionId}`;
    return this.http.get(url);
  }

  /**
   * getMissingDocumentFields
   * @returns
   */
  getMissingDocumentFields(): Observable<any> {
    const url = `${environment.mediaManagementUrl}/api/MediaDocuments/documents`;
    return this.http.get(url);
  }

  /**
   *
   * @param type
   * @returns
   */
  getMissingCustomFields(type: string): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/lookup/custom/${type}`;
    return this.http.get(url);
  }

  /**
   *
   * @param params
   * @returns
   */
  sendMissingInfo(params: any): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/claim/sendMissingInformation`;
    return this.http.post(url, params);
  }

  /**
   * get missing info
   */
  getMissingInfo(claimGuid: string): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Claim/missing-information/${claimGuid}`;
    return this.http.get(url);
  }
  /**
   *
   * @param payload
   * @returns
   */
  getGarageDashboardDetail(payload: any = {}): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Claim/garagedashboarddetail`;
    return this.http.get(url, {
      headers: payload
    });
  }

  /**
   * getEstimateLog
   *
   */
  getEstimateLog(claimId: any): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/integrationrepair/estimate-log/${claimId}`;
    return this.http.get(url);
  }

  /**
   * get fraud detection vehicle
   * @param claimGuid
   * @param version
   * @param inspectionId
   * @returns
   */
  getFraudDetectionVehicle(claimGuid: string, version: string, inspectionId: number): Observable<any> {
    const url = `${environment.xaAggregationApi}/claiminspectionfrauddetails/${claimGuid}/${version}/${inspectionId}`;
    return this.http.get(url);
  }

  /**
   * get fraud detection pdf
   * @param params 
   * @returns 
   */
  getFraudDetectionPdf(params: any): Observable<any> {
    const url = `${environment.communicationApi}/api/Report/fraud-detection`;
    return this.http.post(url, params);
  }

  /**
   * download fraud detection pdf
   * @param params 
   */
  downloadFraudDetectionPdf(params): void {
    const url = `${environment.communicationApi}/api/Report/fraud-detection`;

    this.http
      .post(url, params, { responseType: 'blob' as 'json' },)
      .subscribe({
        next: (res: any) => {
          const file = new Blob([res], { type: res.type });

          // IE
          const nav = (window.navigator as any);
          if (nav.msSaveOrOpenBlob) {
            nav.msSaveOrOpenBlob(file);
            return
          }

          const blob = window.URL.createObjectURL(file);
          const link = document.createElement('a');

          const createdDate = dayjs(new Date()).format('MMDDYYYY');
          link.href = blob;
          link.download = `Fraud_Detection_${createdDate}.pdf`;

          // Version link.click() to work at firefox
          link.dispatchEvent(
            new MouseEvent('click', {
              bubbles: true,
              cancelable: true,
              view: window
            })
          );

          setTimeout(() => {
            // firefox
            window.URL.revokeObjectURL(blob);
            link.remove();
          }, 100);
        },
        error: (error: any) => {
          console.log(error);
        }
      });
  }

  /**
   * getEstimateDetails
   */
  getLatestEstimateDetails(claimId: any): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/integrationrepair/estimate-details/${claimId}`;
    return this.http.get(url);
  }

  /**
   * approveRejectEstimate
   */
  approveRejectEstimate(claimId: any, paramObj: any): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/integrationrepair/estimate-status/${claimId}`;
    return this.http.post(url, paramObj);
  }

  /**
   * editEstimateAddRevision
   *
   */
  editEstimateAddRevision(claimId: any, paramObj: any): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/integrationrepair/estimate-revision/${claimId}`;
    return this.http.post(url, paramObj);
  }

  /**
   * submitGarageAssignmentAction
   *
   */
  submitGarageAssignmentAction(payload, headers): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/claim/garageAssignment/notify`;
    return this.http.post(url, payload, { headers });
  }

  /**
   * submitGarageAssignmentAction
   *
   */
  requestForEstimate(claimGuid, actionType): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/claim/request/${claimGuid}/${actionType}`;
    return this.http.get(url);
  }

  /**
   * submitRegisteredGarageEstimate
   *
   */
  submitRegisteredGarageEstimate(formData, headers): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/claim/claim-estimate`;
    return this.http.post(url, formData, { headers });
  }

  /**
   * submitRegisteredGarageInvoice
   *
   */
  submitRegisteredGarageInvoice(formData, headers): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/claim/claim-invoice`;
    return this.http.post(url, formData, { headers });
  }

  /**
   * getEstimateThirdPartyRegisteredGarage
   *
   */
  getEstimateThirdPartyRegisteredGarage(claimGuid): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/claim/claim-estimate/${claimGuid}`;
    return this.http.get(url);
  }


  /**
   * get dashboard data
   * @returns
   */
  getDashboardData(payload): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Dashboard`;
    return this.http.post(url, payload);
  }

  /**
   * getInvoiceThirdPartyRegisteredGarage
   *
   */
  getInvoiceThirdPartyRegisteredGarage(claimGuid): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/claim/claim-invoice/${claimGuid}`;
    return this.http.get(url);
  }

  /**
   * getInvoiceInternalGarage
   *
   */
  getInvoiceInternalGarage(claimGuid): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/integrationrepair/invoice/${claimGuid}`;
    return this.http.get(url);
  }

  /**
   *
   */
  getAskForRevisionEmailTemplate(claimGuid: any, domainId: any): Observable<any> {
    const url = `${environment.xaAggregationApi}/claimcommunicationtemplate/${claimGuid}/${domainId}/ClaimAskForRevision`
    return this.http.get(url);
  }

  /**
   * getInvoiceInternalGarage
   *
   */
  saveGarageAssignmentSettings(payload): Observable<any> {
    const url = `${environment.autoCoreApi}/api/settings`;
    return this.http.post(url, payload);
  }

  /**
   * getGarageAssignmentSettings
   *
   */
  getGarageAssignmentSettings(settingsType: string): Observable<any> {
    const url = `${environment.autoCoreApi}/api/settings/${settingsType}`;
    return this.http.get(url);
  }

  /**
   * sendWhatsappAskForRevisionNotification
   *
   */
  sendWhatsappAskForRevisionNotification(payload): Observable<any> {
    const url = `${environment.communicationApi}/api/message`;
    return this.http.post(url, payload);
  }

  /**
   * sendEmailAskForRevisionNotification
   *
   */
  sendEmailAskForRevisionNotification(payload): Observable<any> {
    const url = `${environment.communicationApi}/api/message/email`;
    return this.http.post(url, payload);
  }

  /**
   * getGarageAssignmentSettings
   *
   */
  getAutoGarageAssignment(payload): Observable<any> {
    const url = `${environment.autoCoreApi}/api/garage/auto-garage-recommendation`;
    return this.http.get(url, {
      params: payload,
      headers: {
        rejectedGarageIds: payload.rejectedGarageIds || ''
      }
    });
  }

  /**
   * saveGaragePreference
   *
   */
  saveGaragePreference(payload): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/GaragePreference`;
    return this.http.post(url, payload);
  }

  /**
   * get fraud detection and damage severity
   */
  updateFraudDetection(claimGuid: string): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Claim/fraud-detection/${claimGuid}`;
    return this.http.put(url, null);
  }

  /**
   * update fraud detection document
   * @param claimGuid 
   * @returns 
   */
  updateFraudDetectionDocument(claimGuid: string): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Claim/document-fraud-detection/${claimGuid}`;
    return this.http.put(url, null);
  }

  /**
   * third party approve estimate
   *
   */
  thirdPartyApproveEstimate(claimGuid, status): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/claim/update-estimatestatus/${claimGuid}/${status}`;
    return this.http.put(url, {});
  }

  /**
   * third party approve estimate
   * 
   */
  getCustomerSatisfactionGraphData(feedbackType: string, payload: any): Observable<any> {
    const url = `${environment.autoCoreApi}/api/feedback/satisfaction/${feedbackType}`;
    return this.http.post(url, payload);
  }
  /**
   * get surveyor list
   */
  getSurveyorList(): Observable<any> {
    const url = `${environment.autoCoreApi}/api/Users/claim-surveyor`;
    return this.http.get(url);
  }

  /**
 * assign surveyor
 */
  assignSurveyor(payload: any): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/claim/assignment`;
    return this.http.put(url, payload);
  }

  /**
 * get garages rating
 * @param claimGuid 
 * @returns 
 */
  getGarageRatings(garageId: string): Observable<any> {
    const url = `${environment.autoCoreApi}/api/feedback/garage-rating/${garageId}`;
    return this.http.get(url);
  }

  /**
   * lock estimation 
   */
  lockEstimation(claimId: number, status: string): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/integrationrepair/${claimId}/lock/${status}`;
    return this.http.put(url, null);
  }

  /** 
   * get survey pdf 
   */
  getSurveyPdf(claimGuid: string, inspectionId: string, objectId: string): Observable<any> {
    const url = `${environment.xaAggregationApi}/claimSurveyorinspectiondamagereportdata/${claimGuid}/1/${inspectionId}/${objectId}`;
    return this.http.get(url);
  }

  /** 
   * get survey pdf 
   */
  getSettingsTypes(): Observable<any> {
    const url = `${environment.autoCoreApi}/api/Lookup/type?type=SettingType&isEnum=true`;
    return this.http.get(url);
  }

  /**
   * get dashboard data
   * @returns
   */
  getClaimAvgProcessingTime(payload): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/dashboard/avg-time`;
    return this.http.post(url, payload);
  }

  /**
     * get dashboard data
     * @returns
     */
  getClaimStatusBreakdown(payload): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/dashboard/status-breakdown`;
    return this.http.post(url, payload);
  }


  /**
   *  getAvgTurnAroundTimeThirdParty
   * 
   * @param payload 
   * @returns 
   */
  getAvgTurnAroundTimeThirdParty(payload: any): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Dashboard/garage-avg-time`;
    return this.http.post(url, payload);
  }

  /**
   *  getAvgTurnAroundTimeInternal
   * 
   * @param payload 
   * @returns 
   */
  getAvgTurnAroundTimeInternal(payload: any): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/dashboard/7`;
    return this.http.post(url, payload);
  }

  /**
   *  getPartnershipGarages
   * 
   * @param payload 
   * @returns 
   */
  getPartnershipGarages(): Observable<any> {
    const url = `${environment.autoCoreApi}/api/garage/garage-list`;
    return this.http.get(url);
  }

  /**
   *  getGaragesByPerformance
   * 
   * @param payload 
   * @returns 
   */
  getGaragesByPerformance(payload): Observable<any> {
    const url = `${environment.xaAggregationApi}/GaragePerformance`;
    return this.http.get(url, {
      headers: {
        dashboardRequest: JSON.stringify(payload)
      }
    });
  }

  /**
   *  getGaragesByCost
   * 
   * @param payload 
   * @returns 
   */
  getGaragesByCost(payload): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Dashboard/garage-avg-cost`;
    return this.http.post(url, payload);
  }

  /**
   *  saveFraudDetectionSettings
   * 
   * @param payload 
   * @returns 
   */
  saveFraudDetectionSettings(payload): Observable<any> {
    const url = `${environment.autoApi}/api/v1/Inspection/update-config`;
    return this.http.put(url, payload);
  }

  /**
   *  getFraudDetectionSettings
   * 
   * @param payload 
   * @returns 
   */
  getFraudDetectionSettings(): Observable<any> {
    const url = `${environment.autoApi}/api/v1/Inspection/fraud-config`;
    return this.http.get(url);
  }

  /**
   * get quote assessments 
   * @param id 
   */
  getQuoteAssessments(id: string): Observable<any> {
    return of({
      'data': {
        'quoteId': '22222',
        'quoteAssessmentId': '66b6e139-05ab-4758-a329-e2b130e00583',
        'customer': {
          'firstName': 'Dhirendra',
          'lastName': 'Dhakal',
          'email': 'dhiren@gmail.com',
          'phoneCountryCode': '+971',
          'mobileNumber': '576839281'
        },
        'vehicle': {
          'vin': '12345678901234563',
          'makeId': 1,
          'modelId': 1,
          'mileage': '30',
          'registrationDate': '',
          'manufacturingDate': '',
          'bodyTypeId': 1,
          'plateNumber': 'AH78972'
        },
        'communicationChannel': [
          'whatsapp',
          'sms'
        ]
      }
    })
    // const url = `${environment.fnolApiUrl}/api/quote-assessment/garage-avg-cost`;
    // return this.http.get(url);
  }
}
