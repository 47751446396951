<div class="approval-form">
  <div class="wrapper" *ngIf="!cancelled && !success">
      <div class="wrapper-header">
          <h3>{{translate.instant("supplier_accept_confirmation").replace('{{value}}', bodyshopName)}}</h3>
          <span>{{"select_an_option" | translate}}</span>
      </div>
      <div class="radio-container">
          <div class="radio-wrapper" [ngClass]="{'selected': checkedStatus === 'Approved'}">
              <mat-icon [svgIcon]="'check'" aria-hidden="false" aria-label="edit icon"></mat-icon>
              <label>Approve</label>
              <chq-input class="company-input col-3" [inputModel]="approveRadio"
                  (controlOutput)="onFormUpdate($event)"></chq-input>
          </div>
          <div class="radio-wrapper" [ngClass]="{'selected': checkedStatus === 'declined'}">
              <mat-icon [svgIcon]="'cross'" aria-hidden="false" aria-label="edit icon"></mat-icon>
              <label>Decline</label>
              <chq-input class="company-input col-3" [inputModel]="declineRadio"
                  (controlOutput)="onFormUpdate($event)"></chq-input>
          </div>
      </div>
      <div class="button-container">
          <chq-button [buttonModel]="cancelButtonModel"></chq-button>
          <chq-button [buttonModel]="nextButtonModel"></chq-button>
      </div>

  </div>
  <div class="result" *ngIf="cancelled || success">
      <div class="result-content" *ngIf="cancelled">
          <h3>{{"the_request_has_been_cancelled" | translate}}</h3>
      </div>
      <div class="result-content-success" *ngIf="success">
          <h3>{{"the_supplier_was" | translate}} {{status}}</h3>
      </div>
  </div>
</div>
