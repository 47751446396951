import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { ChqWidgetsInputModel, IControlValue } from 'src/app/model/chq-widgets-input-model';
import { CommonService } from 'src/app/services/common/common.service';
import { SupplierService } from 'src/app/services/supplier/supplier.service';

@Component({
  selector: 'app-supplier-approval',
  templateUrl: './supplier-approval.component.html',
  styleUrls: [ './supplier-approval.component.scss' ]
})
export class SupplierApprovalComponent implements OnInit {


  public approveRadio: ChqWidgetsInputModel = {
    placeHolder: 'Approve',
    label: 'Approve',
    name: 'approve',
    idField: '',
    value: '1',
    type: 'radio',
    hideLabel: true,
    options: [ { label: 'approve', id: 1 } ]
  }

  private urlToken: string = '';
  public checkedStatus = '';
  public status = '';
  public partnershipGuid = '';
  public orgId = 0;
  public bodyshopName = '';

  public cancelled: boolean = false;
  public success: boolean = false;

  public declineRadio: ChqWidgetsInputModel = {
    placeHolder: 'Decline',
    label: 'Decline',
    name: 'decline',
    idField: '',
    value: '2',
    type: 'radio',
    hideLabel: true,
    options: [ { label: 'decline', id: 2 } ]
  }

  public cancelButtonModel: ChqWidgetsButtonModel = {
    label: 'Cancel',
    type: 'outline'
  }
  public nextButtonModel: ChqWidgetsButtonModel = {
    label: 'Send',
    type: 'disabled'
  }

  /**
    Constructor for the component.
    @param {CommonService} commonService - The CommonService instance.
    @param {BookingService} repairEstimateService - The RepairEstimateService instance.
    @param {ActivatedRoute} route - The ActivatedRoute instance.
  */
  constructor(
    public commonService: CommonService,
    private route: ActivatedRoute,
    private supplierService: SupplierService,
    public translate: TranslateService
  ) {

  }

  /**
   * cancelled
   */
  cancel(): void {
    this.cancelled = true;
  }

  /**
   * approve/reject repair
   */
  updateSupplierStatus(): void {
    const status = this.checkedStatus === 'Approved' ? 'Approved' : 'Rejected';
    this.status = status;
    this.commonService.showLoading();

    const body = {
      status: status,
    }

    this.supplierService.activateSupplier(body, this.partnershipGuid, this.orgId, this.urlToken).subscribe({ next: (res) => {
      this.success = true;
      this.commonService.hideLoading();
    },
    error: () => {
      this.commonService.hideLoading();
    } })

  }

  /**
   * on init
   */
  ngOnInit(): void {
    const el: any = document.getElementById('custom-loader');
    if (el) {
      el.style.display = 'none';
    }
    this.urlToken = this.route?.snapshot?.queryParams['token'];
    this.partnershipGuid = this.route?.snapshot?.paramMap?.get('partnershipGUID');
    this.orgId = +this.route?.snapshot?.paramMap?.get('orgId');
    this.bodyshopName = this.route?.snapshot?.paramMap?.get('bodyshopName');
    this.nextButtonModel = { ...this.nextButtonModel, onclick: this.updateSupplierStatus.bind(this) };
    this.cancelButtonModel = { ...this.cancelButtonModel, onclick: this.cancel.bind(this) };
  }

  /**
   * onFormUpdate
   */
  onFormUpdate($event: IControlValue): void {
    const { name, value } = $event;
    if (name === 'approve') {
      if (this.declineRadio.value !== 2 && value) {
        this.checkedStatus = 'Approved';
        this.nextButtonModel = { ...this.nextButtonModel, type: 'primary' };
        this.declineRadio = { ...this.declineRadio, value: '' };
      }
    } else if (name === 'decline') {
      if (this.approveRadio.value !== 1 && value) {
        this.checkedStatus = 'declined';
        this.nextButtonModel = { ...this.nextButtonModel, type: 'primary' };
        this.approveRadio = { ...this.approveRadio, value: '' };
      }
    }
  }
}
