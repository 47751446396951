import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { serviceURLMapper } from 'src/app/config/constants/app.constants';
import { ControlTypes, DynamicFormBase } from 'src/app/config/core/dynamic-form/dynamic-form-base';
import { Observable, of } from 'rxjs';
import { IParameterPayload, IParameters } from 'src/app/widgets/parameters/parameters/parameters.component';
import { ApiPagination } from 'src/app/widgets/xa-paginator/xa-paginator.component';
import { PartNode } from 'src/app/widgets/xa-tree-view/tree-component';
import { environment } from 'src/environments/environment';
import { FileService } from '../file/file.service';

interface IGroup {
  Id: number;
  GroupGuid: string;
  Name: string;
  Description: string;
}

interface ICustomerParameter {
  customParameterId: number;
  parameterName: string;
  title: string;
  isDefault: boolean;
  isRequired: boolean;
  customParameterMappingId: number;
  fieldValue: string;
  dataSource: string;
  dataType: string;
  serviceName: string;
  serviceURL: string;
  parentFieldId?:number
}

export interface ICustomerParameterField {
  customParameterId: number;
  parameterName: string;
  title: string;
  customParameterMappingId: number;
  fieldValue: string | null;
  dataSource: string | null;
  dataType: string;
  serviceName: string | null;
  serviceURL: string | null;
  controlTypeId: number;
  defaultValue: string;
  itemSortOrder: number;
  isDefault: boolean;
  isRequired: boolean;
  allowMultipleValues: boolean;
  fieldFormat: string | null;
  fieldMsg: string | null;
  minLength: number;
  maxLength: number;
  parentFieldId: number | null;
  controlType: string;
  sectionId: number;
  sectionName: string;
  information:string;
  widthMultiplier?: number
}

interface ICategory {
  Id: number;
  CategoryGuid: string;
  Name: string;
  Description: string;
  ObjectId: number;
  Groups: IGroup[];
}
@Injectable({
  providedIn: 'root'
})
export class InventoryService {
/**
   * @constructor
   * @param {HttpClient} http
   */
  constructor(private readonly http: HttpClient, private readonly fileService:FileService) { }

  /**
     * getManufacturerSettingsList
     * @returns Observable
     * @param pagination
     * @param queryParams
     * @returns
     */
  getManufacturerSettingsList(pagination: ApiPagination, queryParams: string = null, isManufacturer: string = 'true'): Observable<any> {
    const skip = (pagination.currentPage - 1) * pagination.pageSize;
    queryParams = (!queryParams) ? `$top=${pagination.pageSize}&$skip=${skip}` : `$top=${pagination.pageSize}&$skip=${skip}&${queryParams}`
    const orderBy = `&$orderby=${pagination.orderBy} ${pagination.sortDirection}`
    const url = `${environment.autoApi}/odata/v1/Manufacturer?$count=true&${queryParams}${orderBy}`;
    const headers = new HttpHeaders({
      isManufacturer
    });
    return this.http.get(url, {
      headers
    });
  }

  /**
   * save inventory item
   */
  saveInventoryItem(jsonObj:any):Observable<any> {
    const url = `${environment.autoApi}/api/v1/Inventory/add-items`;
    return this.http.post(url, jsonObj);
  }

  /**
   * inventory to control mapper
   */
  inventoryControlMapper(apiObj:ICustomerParameterField, childControl?:DynamicFormBase<any>):DynamicFormBase<any>{
    const serviceName = apiObj.serviceName;
    let service = '';
    if(serviceName?.toLowerCase() === 'fnol'){
      service = 'fnol';
    }else if(serviceName?.toLowerCase() === 'autocore'){
      service = 'autoCore';
    }else if(serviceName?.toLowerCase() === 'auto'){
      service = 'autoFoundation';
    }else {
      service = serviceName;
    }


    const controlType = apiObj.controlType?.toLowerCase();
    let selectedControlType = '';
    if(controlType === 'textbox'){
      if(apiObj.dataType?.toLowerCase() === 'numeric'){
        selectedControlType = 'numeric';
      }else{
        selectedControlType = controlType;
      }
    }else if(controlType === 'dimension'){
      selectedControlType = 'textbox-group';
    }else if(controlType === 'colorpicker'){
      selectedControlType = 'color-picker';
    }else{
      selectedControlType = controlType;
    }
    const fullWidthControls = [ 'supplier', 'price calculation' ];
    const hiddenControls = [ 'opening stock', 'status' ]
    return {
      controlType: selectedControlType as ControlTypes,
      label: apiObj.title,
      placeholder: apiObj.parameterName,
      required: hiddenControls.includes(apiObj.parameterName?.toLowerCase())? false: apiObj.isRequired,
      disabled: false,
      visible: hiddenControls.includes(apiObj.parameterName?.toLowerCase())? false: true,
      maxLength: apiObj.maxLength,
      minLength: apiObj.minLength,
      order: apiObj.itemSortOrder,
      visibleElement: 'control',
      blockMaxLength: true,
      showInput: true,
      step: 2,
      hintIcon: 'i-circle',
      hint: apiObj.information? apiObj.information: '',
      layout: {
        minWidth: '33%',
        maxWidth: '33%',
        width: fullWidthControls.includes(apiObj.parameterName?.toLowerCase())? '50%': '33%',
        fullWidth: fullWidthControls.includes(apiObj.parameterName?.toLowerCase())? true: false,
        alignment: 'left'
      },
      optionsByUrl: {
        service: serviceURLMapper[service] || '',
        url: apiObj.serviceURL || '',
        placeHolderUrl: apiObj.serviceURL,
        preventExecutionByDefault: false
      },
      optionKeys: {
        label: 'name',
        value: 'id'
      },
      key: `${apiObj.parameterName}_${apiObj.customParameterId}`,
      textPrefillValue: apiObj.fieldValue,
      defaultValue: '',
      value: apiObj.fieldValue,
      options: [],
      icon: '',
      childControl: childControl
    };
  }

  /**
   * delete item
   */
  deleteItem(guid:string):Observable<any> {
    const url = `${environment.autoApi}/api/v1/Inventory/${guid}`;
    return this.http.delete(url);
  }

  /**
   * upload document
   * @returns 
   */
  uploadImage(file: File, mediaCollection: any): Observable<any> {
    const { domainId, objectId } = mediaCollection;
    const formData = new FormData();
    formData.append('domainId', domainId);
    formData.append('objectId', objectId);
    formData.append('file', file);
    const url = `${environment.mediaManagementUrl}/api/MediaDocuments/upload-document`;
    return this.fileService.uploadFile(url, formData);
  }

  /**
   * delete item
   */
  disableItem(guid:string):Observable<any> {
    const url = `${environment.autoApi}/api/v1/Inventory/${guid}`;
    return this.http.patch(url, null);
  }

  /**
   * dynamic field mapping
   */
  dynamicFieldMapper(fields:ICustomerParameterField[]): DynamicFormBase<any>[] {
    let currentSectionName = '';
    
    const sectionIndex = {
      'item_details': 1,
      'stock_details': 2,
      'pricing_details': 3,
      'vehicle_linked': 4,
      'notes': 5,
      'images': 6
    }

    fields.sort((a, b)=>{
      if(sectionIndex[a.sectionName?.toLowerCase().replace(' ', '_')] > sectionIndex[b.sectionName?.toLowerCase().replace(' ', '_')]) return 1;
      if(sectionIndex[a.sectionName?.toLowerCase().replace(' ', '_')] < sectionIndex[b.sectionName?.toLowerCase().replace(' ', '_')]) return -1;
      if(a.itemSortOrder > b.itemSortOrder) return 1;
      if(a.itemSortOrder < b.itemSortOrder) return -1;
      return 0;
    });
    
    const hasNotes = fields.filter(x=>x.parameterName?.toLowerCase() === 'notes').length > 0;
    const dynamicFields: DynamicFormBase<any>[] = [];
    const labelControls = [ 'available', 'items used', 'stock in hand', 'reserved' ];
    const hiddenControls = [ 'status', 'opening stock' ];
    const stockDetailControls = fields.filter(x=>x.sectionName?.toLowerCase() === 'stock details' && !hiddenControls.includes(x.parameterName?.toLowerCase()) && !x.parentFieldId && !labelControls.includes(x.parameterName?.toLowerCase()));
    const controlLength = stockDetailControls?.length;
    
    const lastControl = stockDetailControls.reduce((max, ctrl) => max.itemSortOrder > ctrl.itemSortOrder ? max : ctrl);
    const maxSortOrder = lastControl.itemSortOrder;
    if(controlLength%3 !== 0 && controlLength > 0){
      lastControl.widthMultiplier = 3 - (controlLength%3);     
    }
    const labelControlList = fields.filter(x=>labelControls.includes(x.parameterName?.toLowerCase()));
    labelControlList.forEach(x=>x.itemSortOrder = maxSortOrder + x.itemSortOrder);

    fields.sort((a, b)=>{
      if(sectionIndex[a.sectionName?.toLowerCase().replace(' ', '_')] > sectionIndex[b.sectionName?.toLowerCase().replace(' ', '_')]) return 1;
      if(sectionIndex[a.sectionName?.toLowerCase().replace(' ', '_')] < sectionIndex[b.sectionName?.toLowerCase().replace(' ', '_')]) return -1;
      if(a.itemSortOrder > b.itemSortOrder) return 1;
      if(a.itemSortOrder < b.itemSortOrder) return -1;
      return 0;
    });

    fields.filter(x=>!x.parentFieldId).forEach((apiObj:ICustomerParameterField) => {
      
      if(apiObj.sectionName?.toLowerCase() === 'vehicle linked' || apiObj.sectionName?.toLowerCase() === 'images'){
        return;
      }
     
      if(!currentSectionName || currentSectionName !== apiObj.sectionName){
        currentSectionName = apiObj.sectionName;
        const labelControl:DynamicFormBase<any> = {
          'controlType': 'title',
          'label': apiObj.sectionName,
          'icon': 'title',
          'visible': true,
          'textPrefillValue': apiObj.sectionName,
          'placeholder': '',
          'hintIcon': 'i-circle',
          'required': false,
          'defaultValue': '',
          'layout': {
            width: '100%',
            fullWidth: true,
            maxWidth: '',
            minWidth: '100%',
            alignment: 'left'
          },
          'key': ''
        };
        if(currentSectionName?.toLowerCase().includes('notes')){
          labelControl.prepend = true;

        }
        if(currentSectionName?.toLowerCase().includes('item details')){
          const btnControl:DynamicFormBase<any> = {
            'controlType': 'button',
            'label': '',
            'icon': '',
            'visible': true,
            'textPrefillValue': '',
            'placeholder': '',
            'required': false,
            'btnType': 'radius',
            'btnSize': 'sm',
            'defaultValue': '',
            'layout': {
             
            },
            'key': ''
          };
          labelControl.childControls = [];
          labelControl.childControls.push(btnControl);
        }
        if(currentSectionName?.toLowerCase().includes('stock')){
          const btnControl:DynamicFormBase<any> = {
            'controlType': 'button',
            'label': 'update_stock',
            'icon': 'inventory-plus',
            'visible': true,
            'textPrefillValue': '',
            'placeholder': '',
            'required': false,
            'btnType': 'outline',
            'defaultValue': '',
            'layout': {
             
            },
            'key': ''
          };
          const btnControl1:DynamicFormBase<any> = {
            'controlType': 'button',
            'label': '',
            'icon': '',
            'visible': true,
            'textPrefillValue': '',
            'placeholder': '',
            'required': false,
            'btnType': 'radius',
            'btnSize': 'sm',
            'defaultValue': '',
            'layout': {
             
            },
            'key': ''
          };
          labelControl.childControls = [];
          labelControl.childControls.push(btnControl1);
          labelControl.childControls.push(btnControl);
        }
        dynamicFields.push(labelControl);
      }
      const childControl = fields.find(x=>x.parentFieldId === apiObj.customParameterId);
      let childControlDynamicFormBase = undefined;
      if(childControl){
        childControlDynamicFormBase = this.inventoryControlMapper(childControl);
        childControlDynamicFormBase.layout = {
          minWidth: '90px',
          maxWidth: '90px',
          width: '90px',
          fullWidth: false,
          alignment: 'left'
        };
      }
      const parentObj = this.inventoryControlMapper(apiObj, childControlDynamicFormBase);
      if(apiObj.parameterName?.toLowerCase() === 'notes'){
        parentObj.layout = {
          minWidth: '100%',
          maxWidth: '100%',
          width: '100%',
          fullWidth: true,
          alignment: 'left'
        }
      }

      if(parentObj.key?.toLowerCase().includes('supplier_')){
        const btnControl:DynamicFormBase<any> = {
          'controlType': 'button',
          'label': 'add_supplier',
          'icon': 'add-business',
          'visible': true,
          'textPrefillValue': '',
          'placeholder': '',
          'required': false,
          'btnType': 'outline-radius',
          'btnSize': 'sm',
          'defaultValue': '',
          'layout': {
           
          },
          'key': ''
        };
        parentObj.layout = {
          minWidth: '66%',
          maxWidth: '66%',
          width: '66%',
          fullWidth: true,
          alignment: 'left'
        }
        parentObj.childControl = btnControl;
      }
      if(apiObj.widthMultiplier){
        parentObj.layout ={
          minWidth: '33%',
          maxWidth: `${33 * apiObj.widthMultiplier}%`,
          width: `${33 * apiObj.widthMultiplier}%`,
          fullWidth: true,
          alignment: 'left'
        }
      }
      dynamicFields.push(parentObj);
    });
    
    if(!hasNotes){
      const btnControl:DynamicFormBase<any> = {
        'controlType': 'button',
        'label': '',
        'icon': '',
        'visible': false,
        'textPrefillValue': '',
        'placeholder': '',
        'required': false,
        'prepend': true,
        'btnType': 'radius',
        'btnSize': 'sm',
        'defaultValue': '',
        'layout': {
         
        },
        'key': ''
      };
      dynamicFields.push(btnControl);
    }
   
    return dynamicFields;
  }

  /**
   * get unique code
   */
  getUniqueCode(categoryId:string, groupId:string):Observable<any>{
    const url = `${environment.autoApi}/api/v1/Inventory/unique-code/${categoryId}/${groupId}`;
    return this.http.get(url);
  }

  /**
     * getMarketTypes
     * @returns Observable
     * @param pagination
     * @param queryParams
     * @returns
     */
  getMarketTypes(): Observable<any> {
    const url = `${environment.autoApi}/api/v1/Lookup/MarketType`;
    return this.http.get(url);
  }

  /**
     * getMarketTypes
     * @returns Observable
     * @param pagination
     * @param queryParams
     * @returns
     */
  getItemDetail(guid:string): Observable<any> {
    const url = `${environment.autoApi}/api/v1/Inventory/detail/${guid}`;
    return this.http.get(url);
  }

  /**
     * addManufacturer
     * @returns
     */
  addManufacturer(payload): Observable<any> {
    const url = `${environment.autoApi}/api/v1/Manufacturer`;
    return this.http.post(url, payload);
  }

  /**
     * deleteManufacturer
     * @returns
     */
  deleteManufacturer(id): Observable<any> {
    const url = `${environment.autoApi}/api/v1/Manufacturer/${id}`;
    return this.http.delete(url);
  }

  /**
     * deleteManufacturer
     * @returns
     */
  updateManufacturer(id): Observable<any> {
    const url = `${environment.autoApi}/api/v1/Manufacturer/${id}`;
    return this.http.put(url, {});
  }

  /**
   * get common value url
   */
  getCommonValueUrl(url:string):Observable<any>{
    return this.http.get(url);
  }

  /**
   * delete supplier manufactoere type
   */
  deleteManufacturingType(id:number):Observable<any>{
    const url = `${environment.autoApi}/api/v1/Manufacturer/mapping/${id}`;
    return this.http.delete(url);
  }

  
  /**
   * save RepairEstimationSettings
   * @returns {Observable<any>}
   */
  getInventorySettings(extraFilter?:string): Observable<any> {
    const url = `${environment.autoApi}/odata/v1/Setting?$count=true${extraFilter || ''}`;
    return this.http.get(url);
  }

  /**
   * save RepairEstimationSettings
   * @returns {Observable<any>}
   */
  getGroupParameterFields(groupGuid:string): Observable<any> {
    const url = `${environment.autoApi}/api/v1/Setting/parameters-items/${groupGuid}`;
    return this.http.get(url);
  }

  /**
   * parameterMapper
   */
  parameterMapper(data: ICustomerParameter[]): IParameters[] {
    return data?.map((value:ICustomerParameter) => {
      return {
        name: value.parameterName,
        parameterValue: value.isDefault || !!value.customParameterMappingId,
        isDisabled: value.isDefault,
        serviceName: value.serviceName,
        optionValue: value.fieldValue,
        customParameterId: value.customParameterId,
        customerParameterMappingId: value.customParameterMappingId,
        serviceUrl: value.serviceURL,
        isRequired: value.isRequired,
        parentFieldId: value.parentFieldId
      } as IParameters;
    }) || [];
  }

  /**
   * mapper 
   */
  inventoryMapper(category:ICategory[]):PartNode[]{
    return category?.map((value:ICategory) => {
      return {
        name: value.Name,
        isParent: true,
        guid: value.CategoryGuid,
        children: value.Groups?.map((group:IGroup) => {
          return {
            name: group.Name,
            guid: group.GroupGuid
          }
        })
      }
    }) || [];
  }

  /**
   * get RepairEstimationSettings
   * @returns {Observable<any>}
   */
  createCategory(body:{categoryName:string}): Observable<any> {
    const url = `${environment.autoApi}/api/v1/Setting/category`;
    return this.http.post(url, body);
  }

  /**
   * get RepairEstimationSettings
   * @returns {Observable<any>}
   */
  createGroup(body:{categoryGuid:string, groupName:string}): Observable<any> {
    const url = `${environment.autoApi}/api/v1/Setting/group`;
    return this.http.post(url, body);
  }

  /**
   * get parameter list
   */
  getParameterForGroup(groupGuid: string): Observable<any> { 
    const url = `${environment.autoApi}/api/v1/Setting/parameters/${groupGuid}`;
    return this.http.get(url);
  }

  /**
   * get images
   */
  getImages(objectId:number, domainId:number):Observable<any>{
    const url = `${environment.mediaManagementUrl}/api/document-view/${objectId}/${domainId}`;
    return this.http.get(url)
  }

  /**
   * delete images
   */
  deleteImages(objectId:number, domainId:number, documentId:number):Observable<any>{
    const url = `${environment.mediaManagementUrl}/api/MediaDocuments/delete-document/${objectId}/${documentId}/${domainId}`;
    return this.http.delete(url);
  }

  /**
   * save parameter settings
   */
  saveGroupParameter(body:IParameterPayload):Observable<any>{
    const url = `${environment.autoApi}/api/v1/Setting/parameter`;
    return this.http.put(url, body);
  }
  
  /**
   * get tax added lookup 
   */
  getTaxAddedLookup(): Observable<any> {
    const url = `${environment.autoApi}/api/v1/Lookup/TaxType`;
    return this.http.get(url);
  }

  /**
   * get tax settings 
   * @returns 
   */
  getTaxSettings(): Observable<any> {
    const url = `${environment.autoApi}/api/v1/setting/taxSetting`;
    return this.http.get(url);
  }

  /**
   * add tax settings
   * @param payload 
   * @returns 
   */
  addTaxSettings(payload: any): Observable<any> {
    const url = `${environment.autoApi}/api/v1/setting/taxSetting`;
    return this.http.post(url, payload);
  }

  /**
   * get profit settings
   * @returns 
   */
  getProfitSettings(): Observable<any> {
    const url = `${environment.autoApi}/api/v1/setting/profitSetting`;
    return this.http.get(url);
  }

  /**
   * add profit settings
   * @param payload 
   * @returns 
   */
  addProfitSettings(payload: any): Observable<any> {
    const url = `${environment.autoApi}/api/v1/setting/profitSetting`;
    return this.http.post(url, payload);
  }

  /**
   * save parameter settings
   */
  getDashboardData(payload):Observable<any>{
    const url = `${environment.autoApi}/api/v1/Dashboard`;
    return this.http.post(url, payload);
  }

  /**
     * getManufacturerListWithoutPagination
     * @returns Observable
     * @returns
     */
  getManufacturerListWithoutPagination(isManufacturer: string = 'true'): Observable<any> {
    const url = `${environment.autoApi}/api/v1/Manufacturer`;
    const headers = new HttpHeaders({
      isManufacturer
    });
    return this.http.get(url, {
      headers
    });
  }

  /**
     * getStockStatus
     * @returns Observable
     * @returns
     */
  getStockStatus(): Observable<any> {
    const url = `${environment.autoApi}/api/v1/Lookup/StockStatus`;
    return this.http.get(url);
  }
  /**
     * getStockStatus
     * @returns Observable
     * @returns
     */
  getStatus(): Observable<any> {
    const url = `${environment.autoApi}/api/v1/Lookup/Status`;
    return this.http.get(url);
  }

  /**
     * getDashboardItemList
     * @returns Observable
     * @param pagination
     * @param queryParams
     * @returns
     */
  getDashboardItemList(pagination: ApiPagination, queryParams: string = null, categoryGuid): Observable<any> {
    const skip = (pagination.currentPage - 1) * pagination.pageSize;
    queryParams = (!queryParams) ? `$top=${pagination.pageSize}&$skip=${skip}` : `$top=${pagination.pageSize}&$skip=${skip}&${queryParams}`
    const orderBy = pagination.orderBy ? `&$orderby=${pagination.orderBy} ${pagination.sortDirection}` : '';
    const url = `${environment.autoApi}/odata/v1/Inventory?$count=true&${queryParams}${orderBy}`;
    const headers = new HttpHeaders({
      categoryGuid
    });
    return this.http.get(url, { headers });
  }

  /**
   * get movement types
   * @returns 
   */
  getMovementTypes(): Observable<any> {
    const url = `${environment.autoApi}/api/v1/Inventory/movement-types`;
    return this.http.get(url);
  }

  /**
   * get update stock reasons
   * @returns 
   */
  getUpdateStockReasons(): Observable<any> {
    const url = `${environment.autoApi}/api/v1/Lookup/Reason`;
    return this.http.get(url);
  }

  /**
   * upload stock
   * @param payload 
   */
  updateStock(payload: any, inventoryItemGuid: string): Observable<any> {
    const url = `${environment.autoApi}/api/v1/Inventory/update-stock/${inventoryItemGuid}`;
    return this.http.put(url, payload);
  }

  /**
   * upload stock file
   * @param formData 
   */
  uploadStockFile(formData: FormData): Observable<any> {
    const url = `${environment.mediaManagementUrl}/api/MediaDocuments/upload-document`;
    return this.http.post(url, formData);
  }

  /**
   * stock document mapper
   */
  stockDocumentMapper(payload: any): Observable<any> {
    const url = `${environment.autoApi}/api/v1/Inventory/document`;
    return this.http.put(url, payload);
  }

  /**
   * get inventory transactions
   * @param guid 
   */
  getInventoryTransactions(guid: string): Observable<any> {
    const url = `${environment.autoApi}/api/v1/Inventory/transaction-detail/${guid}`;
    return this.http.get(url);
  }

  /**
   * download transaction report
   * @param fileName 
   * @param mediaPartGuid 
   * @returns 
   */
  downloadTransactionDocument(fileName: string, mediaPartGuid: string): Observable<any> {
    const url = `${environment.mediaManagementUrl}/api/MediaDocuments/download/${mediaPartGuid}`;
    return this.http.get(url, { responseType: 'blob' });
  }

  /**
   * checkPartsAvailability
   * @returns 
   */
  checkPartsAvailability(payload: any): Observable<any> {
    const url = `${environment.autoApi}/api/v1/Inventory/check-availability`;
    return this.http.post(url, payload);
  }

  /**
   * reserve parts
   * @param payload 
   */
  reserveParts(payload: any, repairGuid: string, ): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/repair/updatePartDetails`;
    const headers = new HttpHeaders({
      repairGuid
    });

    return this.http.post(url, payload, { headers });
  }
  
  /**
   * update image notes
   */
  updateNotes(payload: any): Observable<any> {
    const url = `${environment.autoApi}/api/v1/Inventory/note-detail`;
    return this.http.put(url, payload);
  }

  /**
   * get image notes
   */
  getNotes(id: number): Observable<any> {
    const url = `${environment.autoApi}/api/v1/Inventory/note-detail/${id}`;
    return this.http.get(url);
  }

  /**
   * delete notes
   */
  deleteNote(id:number):Observable<any>{
    const url = `${environment.autoApi}/api/v1/Inventory/note-detail/${id}`;
    return this.http.delete(url);
  }

  /**
   * download excel file
   */
  downloadExcelFile(categoryGuid: string): Observable<any> {
    const headers = new HttpHeaders({
      categoryGuid
    });
    const url = `${environment.autoApi}/api/v1/Inventory/bulk-template`;
    return this.http.get(url, { responseType: 'blob', headers: headers, observe: 'response' });
  }

  /**
   * upload excel file
   */
  uploadExcelFile(file: File, categoryGuid: string): Observable<any> {
    const headers = new HttpHeaders({
      categoryGuid
    });

    const formData = new FormData();
    formData.append('bulkItemFile', file);

    const url = `${environment.autoApi}/api/v1/Inventory/bulk-template`;
    return this.http.post(url, formData, { headers: headers });
  }
}
