/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { AddCustomerData } from 'src/app/model/chq-new-case-form.model';
import { ApiPagination } from 'src/app/widgets/xa-paginator/xa-paginator.component';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CaseService {

  private apiUrl: string = 'cases';

  /**
   * constructor
   * @param http
   */
  constructor(private http: HttpClient) {
  }

  /**
   *
   * @param pagination
   * @returns observable
   */
  public getCases(pagination: ApiPagination, queryParams: string = null): Observable<any> {
    const select = `$select=Id, OrgId, CaseGuid, CaseNumber, CreatedDate, CreatedByUserName, OrgName, WorkProviderName,
    VehicleMake, VehicleModel, EstimateCost, StatusDescription, ExternalId, RegistrationDate, ModelYearRange, IntegrationStatus`;
    const skip = (pagination.currentPage - 1) * pagination.pageSize;
    queryParams = (!queryParams) ? `$top=${pagination.pageSize}&$skip=${skip}` : `$top=${pagination.pageSize}&$skip=${skip}&${queryParams}`
    const orderBy = `&$orderby=${pagination.orderBy} ${pagination.sortDirection}`
    const url = `${environment.caseMgmtUrl}/odata/${this.apiUrl}?${select}&${queryParams}${orderBy}`;

    return this.http.get<any>(url);
  }

  /**
   *
   * @param pagination
   * @returns observable
   */
  public getInspectionIdFromList(guid: string, orgId: number, isCaseNumber?:boolean): Observable<any> {
    let queryParams = `$filter=id eq ${guid} and OrgId eq ${orgId}&$select=Inspections`;
    if(isCaseNumber){
      queryParams = `$filter=caseNumber eq '${guid}' and OrgId eq ${orgId}&$select=Inspections`;
    }
    const url = `${environment.caseMgmtUrl}/odata/${this.apiUrl}?${queryParams}`;
    return this.http.get<any>(url);
  }

  /**
   * get CaseDetail
   * @param pagination
   * @returns observable
   */
  public getCaseDetail(caseId: string): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/${this.apiUrl}/${caseId}`;
    return this.http.get<any>(url);
  }

  /**
   * Total case count
   * @returns Observable
   */
  public getTotalCountCase(queryParams: string = ''): Observable<number> {
    const url = `${environment.caseMgmtUrl}/odata/${this.apiUrl}/$count${queryParams}`;
    return this.http.get<any>(url);
  }

  /**
   * Total case coun
   * @returns Observable
   */
  public getProviders(orgId?:any): Observable<any> {
    const orgFilter = orgId ? `?orgId=${orgId}`: '';
    const url = `${environment.autoCoreApi}/api/Partnership/orgType-by-partnership${orgFilter}`;
    return this.http.get<any>(url);
  }
  
  /**
   * Export  case 
   * @returns Observable
   */
  public exportCase(exportObj: any): Observable<number> {
    const headers = new HttpHeaders().append('accept', 'text/csv');
    const url = `${environment.caseMgmtUrl}/api/export/cases`;
    const requestObj = {
      'exportFilters': exportObj
    }
    const sequence = new Observable((observer: Observer<any>) => {
      const xhr = new XMLHttpRequest();
      this.http.post(url, requestObj, { responseType: 'blob', headers }).subscribe((res:any) => {
        const windowUrl = window.URL || window.webkitURL;
        const href = windowUrl.createObjectURL(res);
        observer.next({ fileURL: href });
        observer.complete();
      },
      () => {
        observer.error({ errorMessage: xhr.statusText });
        observer.complete();
      })
    });
    return sequence;
  }

  /**
   * Total case coun
   * @returns Observable
   */
  public getProvidersByOrgId(query): Observable<any> {
    const url = `${environment.autoCoreApi}/api/Partnership/orgTypes-by-orgid${query}`;
    return this.http.get<any>(url);
  }

  /**
   * Total tariff company data
   * @returns Observable
   */
  public getCompanyData(orgType: string, orgId?:number): Observable<any> {
    const orgFilter = orgId ? `?orgId=${orgId}`: '';
    const url = `${environment.autoCoreApi}/api/Partnership/partnership-by-org/${orgType}${orgFilter}`;
    return this.http.get<any>(url);
  }

  /**
   * Total case coun
   * @returns Observable
   */
  public getOrganizations(): Observable<any> {
    const url = `${environment.autoCoreApi}/api/Organizations/all-orgs-by-bm-user`;
    return this.http.get<any>(url);
  }

  /**
  * Function to fetch case users
   * @param params
   * @returns
   */
  public getCaseUsers(query): Observable<any> {
    const url = `${environment.autoCoreApi}/api/Users/managed-users/?${query}`;
    return this.http.get<any>(url);
  }

  /**
   * get repair history pdf
   */
  public downloadRepairEstimateHistoryPdf(referenceId: number): Observable<any> {
    const localTime = new Date().toString();
    const headers = new HttpHeaders().append('localtime', localTime)
    const url = `${environment.caseMgmtUrl}/api/casehistory/report/${referenceId}`;
    return this.http.get<any>(url, { headers });
  }

  /**
   * Save new case
   * @returns Observable
   */
  public saveNewCase(customerFormData:AddCustomerData): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/${this.apiUrl}`;
    return this.http.post(url, customerFormData);
  }

  /**
   * update  case
   * @returns Observable
   */
  public updateCase(customerFormData:AddCustomerData): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/${this.apiUrl}`;
    return this.http.put(url, customerFormData);
  }

  /**
   * update  case
   * @returns Observable
   */
  public updateCaseStatus(caseGuid: string, status: string, reason?: string, deliveryDate?: string): Observable<any> {
    const request = {
      status,
      reason,
      deliveryDate
    }
    const url = `${environment.caseMgmtUrl}/api/${this.apiUrl}/${caseGuid}/status`;
    return this.http.put(url, request);
  }

  /**
  * Function to delete case id
   * @param caseId
   * @returns
   */
  public deleteCase(caseId: string): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/${this.apiUrl}/${caseId}`;
    return this.http.delete<any>(url);
  }

  /**
  * Function to fetch case status
   * @param params
   * @returns
   */
  public getCaseStatus(): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/lookup?type=casestatus`;
    return this.http.get<any>(url);
  }

  /**
   * Total case coun
   * @returns Observable
   */
  public getBMOrganizations(): Observable<any> {
    const url = `${environment.autoCoreApi}/api/Organizations/all-orgs-by-bm-user `;
    return this.http.get<any>(url);
  }

  /**
   * Get quote and template details:
   *
   */
  public getQuoteTemplateDetails(version:number = 1, caseGuid:string, domainId:number, templateKey:string, collectionId:number, objectId:number, inspectionId:number): Observable<any> {
    const url = `${environment.xaAggregationApi}/case-communication-media-inspection/${version}/${caseGuid}/${domainId}/${templateKey}/${collectionId}/${objectId}/${inspectionId}`;
    return this.http.get<any>(url);
  }

  /**
   * update  case integration
   * @returns Observable
   */
  public updateCaseIntegrationStatus(objectGuid: string, domainId: string | number, deliveryDate?: string, reason?: string): Observable<any> {
    const request = {
      objectGuid,
      domainId,
      deliveryDate,
      comment: reason
    }
    const url = `${environment.caseMgmtUrl}/integration/quote/sendestimatetointegration`;
    return this.http.put(url, request);
  }
}
