import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AfterContentInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, Renderer2, SimpleChanges, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgModel } from '@angular/forms';
import * as dayjs from 'dayjs';
import { DxDateBoxComponent } from 'devextreme-angular';
import { ValueChangedEvent } from 'devextreme/ui/date_box'
import { Subscription, of, merge } from 'rxjs';

let nextUniqueId = 0;
enum datePickerDXFormat {
  'date' = 'dd/MM/yyyy',
  'time' = 'hh:mm aa',
  'datetime' = 'dd/MM/yyyy hh:mm aa'
}


enum datePickerFormat {
  'date' = 'DD/MM/YYYY',
  'time' = 'hh:mm A',
  'datetime' = 'DD/MM/YYYY hh:mm A'
}

@Component({
  selector: 'xa-date-picker',
  templateUrl: './xa-date-picker.component.html',
  styleUrls: [ './xa-date-picker.component.scss' ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => XaDatePicker),
      multi: true
    } ],
})

export class XaDatePicker implements OnDestroy, OnChanges, AfterContentInit, ControlValueAccessor {
  @Input() readonly?: boolean;
  @Input() required?: boolean;
  @Input() label?: string = '';
  @Input() name?: string;
  @Input() minDate?: Date;
  @Input() maxDate?: Date;
  @Input() interval?: number;
  @Input() type: 'date' | 'datetime' | 'time' = 'date';
  @Input() placeholder?: string = '';
  @Input() disable?: boolean = false;

  /** Whether the date time picker should be disabled. */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _disabled: boolean;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _value: { date: Date, format: string };
  private stateChanges = Subscription.EMPTY;
  private disabledChanges = Subscription.EMPTY;

  public disabledChange = new EventEmitter<boolean>();
  public valueChange = new EventEmitter<{ date: Date, format: string, formattedValue: string }>();
  public dropdownOpened: boolean = false;
  public opened: boolean = false;
  public _uniqueId: string = `xa-text-${nextUniqueId++}`;
  public _isInitialized: boolean = false;


  @ViewChild('input') input: DxDateBoxComponent;
  @ViewChild('elemRef') elmRef: ElementRef<HTMLInputElement>;

  //output events
  @Output() inputChange: EventEmitter<any> = new EventEmitter();

  _controlValueAccessorChangeFn: (value: any) => void = () => { };
  private onModelTouched: () => void = () => { };
  onTouched: () => any = () => { };

  /**
   * 
   * @param elmRef 
   * @param renderer 
   * @param dateTimeAdapter 
   * @param dateTimeFormats 
   */
  constructor(
    private renderer: Renderer2,
    protected changeDetector: ChangeDetectorRef
  ) {

  }

  /**
     * handle keyboard change
     */
  public handleInputOnHost(event: any): void {
    const value = event.target.value;
    this.changeInputInSingleMode(value, true);
    this.onModelTouched();
    this.onTouched();
  }

  /** Triggered when the radio button receives an interaction from the user. */
  _onInputInteraction(): void {
    const newValue = this.elmRef.nativeElement.value;
    this._controlValueAccessorChangeFn(newValue);
  }

  /**
   * get disabled
   */
  @Input()
  get disabled(): boolean {
    return this._disabled === undefined && !!this._disabled;
  }

  /**
   * set disabled
   */
  set disabled(value: boolean) {
    value = coerceBooleanProperty(value);
    if (value !== this._disabled) {
      this._disabled = value;
      this.disabledChange.next(value);
    }
  }

  /**
   * get value
   */
  get value(): any {
    return this._value;
  }

  /**
   * set value
   */
  set value(value: any) {
    if (value !== this._value) {
      this._value = value;
      this.valueChange.next(value);
      this.formatNativeInputValue();
    }
  }

  /**
   * get input value
   */
  get inputValue(): string {
    return this.value?.formattedValue || '';
  }

  /**
   * set calendar value
   */
  setCalendarData(event: ValueChangedEvent): void {
    if (event.value != event.previousValue) {
      const date = event.value;
      if (this.type?.toLowerCase() == 'date') {
        date.setHours(new Date().getHours())
        date.setMinutes(new Date().getMinutes())
        date.setSeconds(new Date().getSeconds())
        date.setMilliseconds(new Date().getMilliseconds())
      }
      setTimeout(() => {
        this.value = { date, formattedValue: this.input.instance['_changedValue'], format: this.input.instance['_formatPattern'] };
        this.formatNativeInputValue();
        this._controlValueAccessorChangeFn(this.value.date);
        this.onTouched();
      }, 1);
    }
  }

  /**
   *  ngOnChanges
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['datepicker']) {
      this.watchStateChanges();
    }
  }

  /**
   * update input value
   */
  public updateDatePicker(val: any): void {
    this.input.value = val;
  }

  /**
   * set ngOnDestroy
   */
  public ngOnDestroy(): void {
    this.disabledChange.complete();
    this.valueChange.complete();
  }

  /**
   * open picker
   */
  openPicker(): void {
    this.opened = true;
  }

  /**
   * Registers a callback to be triggered when the value value changes.
   * Implemented as part of ControlValueAccessor.
   * @param fn Callback to be registered.
   */
  registerOnChange(fn: (value: any) => void): void {
    this._controlValueAccessorChangeFn = fn;
  }

  /**
   * Registers a callback to be triggered when the control is touched.
   * Implemented as part of ControlValueAccessor.
   * @param fn Callback to be registered.
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * 
   * @param value 
   */
  public writeValue(value: any): void {
    if(!value) {
      this.value = { date: value, formattedValue: '', format: datePickerDXFormat[this.type.toLowerCase()] };
      if(this.input) this.input.value = '';
      return;
    }
    const format = datePickerFormat[this.type.toLowerCase()];
    this.value = { date: value, formattedValue: dayjs(value).format(format), format: datePickerDXFormat[this.type.toLowerCase()] };

  }

  /**
   * Registers a callback to be triggered when the control is changed.
   * Implemented as part of ControlValueAccessor.
   * @param fn Callback to be registered.
   */
  public handleChangeOnHost(event: any): void {
    const v = this.value;
    this.inputChange.emit({
      source: this,
      value: v,
      event,
      input: this.elmRef.nativeElement
    });
  }

  /**
     * Handle input change in single mode
     */
  private changeInputInSingleMode(inputValue: string, isInput?: boolean): void {
    const value = inputValue;
    const format = datePickerFormat[this.type.toLowerCase()];
    const result = dayjs(value, format);

    // if the newValue is the same as the oldValue, we intend to not fire the valueChange event
    // result equals to null means there is input event, but the input value is invalid
    if ((!result.isSame(dayjs(dayjs(this.value))) || result === null) && result.isValid()) {
      this.value = { date: result.toDate(), formattedValue: result.format(format), format: datePickerDXFormat[this.type.toLowerCase()] };
      const currentValue = result.toDate();
      if (isInput) {
        this.updateDatePicker(currentValue);
      }
      this.inputChange.emit({
        source: this,
        value: result,
        input: this.elmRef.nativeElement
      });
    }
  }

  /**
     * Set the native input property 'value'
     */
  public formatNativeInputValue(): void {
    if (!this.value) {
      return;
    }
    if (this.elmRef) {
      this.elmRef.nativeElement.value = this.value['formattedValue'];
    }
  }

  /**
   *  ngAfterContentInit
   */
  public ngAfterContentInit(): void {
    this._isInitialized = true;
    this.watchStateChanges();
  }

  /**
   *  watchStateChanges
   */
  private watchStateChanges(): void {
    this.stateChanges.unsubscribe();

    const inputDisabled = this.disabledChange ?
      this.disabledChange : of();
    this.disabledChanges = merge(inputDisabled)
      .subscribe(() => {
        this.changeDetector.markForCheck();
      });
  }

  /**
   * get generated id
   */
  get inputId(): string {
    return `${this._uniqueId}-input`;
  }

  /**get name */
  get nameId(): string {
    return this.name ?? `${this._uniqueId}`;
  }

}
