import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SupplierService {

  /**
   * constructor
   * @param http
   */
  constructor(
  private http: HttpClient
  ) {
  }

  /**
   * get country list
   * @returns
   */
  public getAllCountryList(): Observable<any> {
    const url = `${environment.autoCoreApi}/odata/Countries`;
    return this.http.get(url);
  }

  /**
   * get region list
   * @returns
   */
  public getRegions(countryId: string): Observable<any> {
    const url = `${environment.autoCoreApi}/api/Countries/county/${countryId}`;
    return this.http.get(url);
  }

  /**
   * get city list
   * @returns
   */
  public getCitys(regionlId: string): Observable<any> {
    const url = `${environment.autoCoreApi}/api/Countries/county/city/${regionlId}`;
    return this.http.get(url);
  }


  /**
   * get city list
   * @returns
   */
  public sendSupplierActivationLink(body: any, orgId:number, partnerShipId:number): Observable<any> {
    const url = `${environment.autoCoreApi}/api/message/${orgId}/${partnerShipId}/supplier-activation`;
    return this.http.post(url, body);
  }

  /**
   * Description placeholder
   *
   * @public
   * @param {*} body
   * @param {string} partnershipGuid
   * @param {number} orgId
   * @returns {Observable<any>}
   */
  public activateSupplier(body:any, partnershipGuid:string, orgId:number, token:string): Observable<any> {
    const url = `${environment.autoCoreApi}/api/external/partnership/${partnershipGuid}/${orgId}/status`;
    const headers = new HttpHeaders().append('token', decodeURIComponent(token))
    return this.http.put(url, body, { headers });
  }
}
