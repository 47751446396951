import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { XADropdownConfig } from 'src/app/model/xa-dropdown-model';
import { MonitorService } from 'src/app/services/monitor/monitor.service';


@Component({
  selector: 'send-insurer-dialog',
  templateUrl: './send-insurer-dialog.component.html',
  styleUrls: [ './send-insurer-dialog.component.scss' ]
})
export class SendInsurerDialogComponent implements OnInit {

  public photoSelectBox: XADropdownConfig = {
    name: 'photoSelectBox',
    label: 'PHOTO ATTACHMENTS',
    showRequiredLabel: false,
    placeHolder: 'Image Selection',
    labelKey: 'name',
    valueKey: 'id',
    multiple: true,
    dropdownPosition: 'fixed',
    disabled: true,
    showAllOption: true,
    allOption: { 'name': 'Select All', 'id': '' }
  };

  public documentSelectBox: XADropdownConfig = {
    name: 'photoSelectBox',
    label: 'DOCUMENT ATTACHMENTS',
    showRequiredLabel: false,
    placeHolder: 'Document Selection',
    labelKey: 'name',
    valueKey: 'id',
    multiple: true,
    dropdownPosition: 'fixed',
    disabled: true,
    showAllOption: true,
    allOption: { 'name': 'Select All', 'id': '' }
  };

  public documentList: any[] = [];
  public photoList: any[] = [];

  public selectedPhotos: number[] = [];
  public selectedDocuments: number[] = [];

  public photoBlobObject: any = {};
  public photoNameObject: any = {};

  public documentBlobObject: any = {};
  public documentNameObject: any = {};

  /**
   * constructor
   * @param dialogRef
   * @param data
   */
  constructor(public dialogRef: MatDialogRef<SendInsurerDialogComponent>,
    private readonly monitorService: MonitorService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  /**
   * ng on init
   */
  ngOnInit(): void {
    this.monitorService.logEvent('ngOnInit', [ 'EmailDialogComponent', 'addenda' ]);

    this.data.documents?.forEach((el) => {
      this.documentList.push(...el.mediaParts.filter(fl => fl.fileSize > 0).map((m) => {
        this.documentBlobObject[m.mediaPartGuid] = m.getURL;
        this.documentNameObject[m.mediaPartGuid] = m.name;

        let imageData = { type: 'image', name: m.getURL };
        if (m.contentType === 'application/pdf') {
          imageData = { type: 'svg', name: 'pdf-file' };
        }
        return { blobUrl: m.getURL, id: m.mediaPartGuid, name: m.name, imageSize: m.fileSize, parentName: el.name, icon: imageData }

      }))
    })

    if (this.documentList.length > 0) {
      this.documentSelectBox.disabled = false;
    }

    this.data.images?.forEach((el) => {
      this.photoList.push(el);
      this.photoBlobObject[el.id] = el.blobUrl;
      this.photoNameObject[el.id] = el.name;

    })

    if (this.photoList.length > 0) {
      this.photoSelectBox.disabled = false;
    }
  }

  /**
   * Remove file
   */
  removeAttachment(id: number, type: string): void {
    if (type === 'photo') {
      this.selectedPhotos = this.selectedPhotos.filter(x => x != id);
    } else if (type === 'document') {
      this.selectedDocuments = this.selectedDocuments.filter(x => x != id);
    }
  }

  /**
   * 
   */
  closeDialog(): void {
    this.dialogRef.close();
  }

  /**
   * 
   */
  sendToInsurer(): void {
    const documentData = this.documentList.filter(x => this.selectedDocuments.includes(x.id)).map((y) => { return { name: y.name, parentName: y.parentName } });

    this.dialogRef.close({ photos: this.selectedPhotos, documents: documentData });
  }
}

