import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, } from 'rxjs';
import { ApiPagination } from 'src/app/widgets/xa-paginator/xa-paginator.component';
import { environment } from 'src/environments/environment';
import { FileService } from '../file/file.service';
import * as dayjs from 'dayjs';
import { MediaCollectionService } from '../media-collection/media-collection.service';

@Injectable({
  providedIn: 'root'
})
export class ClaimsService {
  claimDashboardFilters$ = new BehaviorSubject({});
  dashboardGarageList$ = new BehaviorSubject([]);
  ClaimStatus: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  EstimateStatus: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  isTotalLoss: BehaviorSubject<any> = new BehaviorSubject<boolean>(null);
  /**
   * @constructor
   * @param {HttpClient} http
   */
  constructor(private http: HttpClient, private fileService: FileService, private mediaCollectionService: MediaCollectionService) { }

  /**
 * getCustomer
 * @returns Observable
 */
  public getCustomer(id: number): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/customer?$filter=OrgId eq ${id}`;
    return this.http.get(url);
  }

  /**
   * get claim detail
   * @param id claim guid
   * @returns observable
   */
  public getClaimDetail(id: string, headers: any = {}): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Claim/details/${id}`;
    return this.http.get(url, { headers });
  }

  /**
   * delete document
   */
  deletePaymentDocument(claimantId:string, paymentGuid:string, documentGuid:string):Observable<any>{
    const url = `${environment.fnolApiUrl}/api/Payment/document-delete/${claimantId}/${paymentGuid}/${documentGuid}`;
    return this.http.delete(url);
  }

  /**
   * get claim detail
   * @param id claim guid
   * @returns observable
   */
  public updateClaimDetails(payload: any): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Claim/update`;
    return this.http.put(url, payload);
  }

  /**
   * getClaimList
   * @returns Observable
   *
   * @param pagination
   * @param queryParams
   * @returns
   */
  getClaimList(pagination: ApiPagination, queryParams: string = null): Observable<any> {
    const skip = (pagination.currentPage - 1) * pagination.pageSize;
    queryParams = (!queryParams) ? `$top=${pagination.pageSize}&$skip=${skip}` : `$top=${pagination.pageSize}&$skip=${skip}&${queryParams}`
    const orderBy = `&$orderby=${pagination.orderBy}`
    const select = '$select=AssigneeId, AssigneeName, ClaimGuid, ClaimNo, CreatedDate, CustomerName, FnolId, Id, PolicyNo, Severity, Status, VehicleName, VehicleNumber, CreatedByUserName, IsAssignedBack, PoliceReferenceNo, CreatedUserId&';
    const url = `${environment.fnolApiUrl}/odata/v2/Claim?${select}${queryParams}${orderBy}`;
    return this.http.get(url, {
      headers: {
        version: 'v2'
      }
    });
  }

  /**
   * getClaimList
   * @returns Observable
   *
   * @param pagination
   * @param queryParams
   * @returns
   */
  getPaymentList(pagination: ApiPagination, queryParams: string = null, claimantId:string, paymentGuid?:string): Observable<any> {
    const skip = (pagination.currentPage - 1) * pagination.pageSize;
    queryParams = (!queryParams) ? `$top=${pagination.pageSize}&$skip=${skip}` : `$top=${pagination.pageSize}&$skip=${skip}&${queryParams}`
    const orderBy = `&$orderby=${pagination.orderBy} ${pagination.sortDirection}`;
    const url = `${environment.fnolApiUrl}/odata/Payment?${queryParams}${orderBy}`;
    const headers:any = {};
    if(claimantId){
      headers.objectGuid = claimantId;
    }
    if(paymentGuid){
      headers.paymentGuid = paymentGuid;
    }
    return this.http.get(url, {
      headers: {
        ...headers
      }
    });
  }

  /**
   * get recovery list
   */
  getRecoveryList(claimantId:string, filter): Observable<any> {
    const url = `${environment.fnolApiUrl}/odata/Recovery?$expand=DocumentDetails&${filter}`;
    const headers:any = {};
    if (claimantId){
      headers.objectGuid = claimantId;
    }
    return this.http.get(url, {
      headers: {
        ...headers
      }
    });
  }


  /**
   * get recovery detail
   * @param recoveryGuid 
   */
  getRecoveryDetail(recoveryGuid: string, claimantGuid: string): Observable<any> {
    //https://apim-dih-dev01.azure-api.net/fnolapi-dev/odata/Recovery?$expand=DocumentDetails&$top=20&$skip=0
    const url = `${environment.fnolApiUrl}/odata/Recovery?$expand=DocumentDetails&$filter=recoveryGuid eq ${recoveryGuid}`;
    const headers:any = {};
    if (claimantGuid){
      headers.objectGuid = claimantGuid;
    }
    return this.http.get(url, {
      headers: {
        ...headers
      }
    });

  }

  /**
   * delete payment
   */
  deletePayment(claimantId:string, paymentGuid:string):Observable<any>{
    const url = `${environment.fnolApiUrl}/api/Payment/delete/${claimantId}/${paymentGuid}`;
    return this.http.delete(url);
  }

  /**
   * delete payment
   */
  deleteRecovery(claimantId:string, recoveryGuid:string):Observable<any>{
    const url = `${environment.fnolApiUrl}/api/Recovery/delete/${claimantId}/${recoveryGuid}`;
    return this.http.delete(url);
  }

  /**
   * delete document
   */
  deleteRecoveryDocument(claimantId:string, recoveryGuid:string, documentGuid:string):Observable<any>{
    const url = `${environment.fnolApiUrl}/api/Recovery/document-delete/${claimantId}/${recoveryGuid}/${documentGuid}`;
    return this.http.delete(url);
  }

  /**
   * getClaimList
   * @returns Observable
   *
   * @param pagination
   * @param queryParams
   * @returns
   */
  getClaimantBeneficiaries(claimantId:string, coverageId?:number, coverageTypeId ?:number): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Payment/beneficiary-details`;
    const headers:any = {};
    if(coverageId){
      headers.coverageId = coverageId.toString();
    }
    if(coverageTypeId){
      headers.coverageTypeId = coverageTypeId.toString();
    }
    return this.http.get(url, {
      headers: {
        objectGuid: claimantId,
        ...headers
      }
    });
  }

  /**
     * get uploadMassUpload
     * @returns
     */
  uploadPaymentFile(obj: any, file: File[], reportProgress?: boolean, observe?: 'events'): Observable<any> {
    const formData = new FormData();
    if(file){
      file.forEach((element: any) => {
        formData.append('attachments', element);
      });
    }
    
    if(obj.paymentGuid){
      formData.append('paymentGuid', obj.paymentGuid);
    } 
    formData.append('objectGuid', obj.objectGuid);
    formData.append('coverageId', obj.coverageId);
    formData.append('coverageTypeId', obj.coverageTypeId);
    formData.append('paymentTypeId', obj.paymentTypeId);
    formData.append('paymentStatusId', obj.paymentStatusId);
    formData.append('paymentDate', obj.paymentDate);
    formData.append('amount', obj.amount);
    formData.append('beneficiaryId', obj.beneficiaryId);
    formData.append('remarks', obj.remarks);
    formData.append('bankName', obj.bankName);
    formData.append('accountNumber', obj.accountNumber);
    formData.append('referenceNumber', obj.referenceNumber);
    formData.append('notes', obj.remarks);
    const url = `${environment.fnolApiUrl}/api/Payment`;
    return this.fileService.uploadFile(url, formData, reportProgress, observe);
  }

  /**
     * get uploadMassUpload
     * @returns
     */
  savePayment(obj: any): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Payment`;
    return this.http.post(url, obj);
  }


  /**
   * save recovery
   */
  saveRecovery(obj: any, file: File[], reportProgress?: boolean, observe?: 'events'): Observable<any> {
    const formData = new FormData();
    if(file){
      file.forEach((element: any) => {
        formData.append('attachments', element);
      });
    }
    
    if(obj.recoveryGuid){
      formData.append('recoveryGuid', obj.recoveryGuid);
    } 
    formData.append('objectGuid', obj.objectGuid);
    formData.append('recoveryTypeId', obj.recoveryTypeId);
    formData.append('recoveryStatusId', obj.recoveryStatusId);
    formData.append('recoveryDate', obj.recoveryDate);
    formData.append('amount', obj.amount);
    formData.append('beneficiaryId', obj.beneficiaryId);
    formData.append('remarks', obj.remarks || '');
    formData.append('beneficiaryName', obj.beneficiaryName);
    formData.append('beneficiaryLookupTypeId', obj.beneficiaryLookupTypeId);
    const url = `${environment.fnolApiUrl}/api/Recovery`;
    return this.fileService.uploadFile(url, formData, reportProgress, observe);
  }

  /**
   * save recovery
   */
  saveRecoveryJson(obj: any): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Recovery`;
    return this.http.post(url, obj);
  }

  /**
   *
   * @param queryParams
   * @returns
   */
  getTotalCountClaim(queryParams: string = ''): Observable<any> {
    const url = `${environment.fnolApiUrl}/odata/v2/Claim/$count?${queryParams}`;
    return this.http.get(url, {
      headers: {
        version: 'v2'
      }
    });
  }

  /**
   * getListClaimReviewers
   * @returns
   */
  getListClaimReviewers(): Observable<any> {
    const url = `${environment.autoCoreApi}/api/Users/claim-reviewer`;
    return this.http.get(url);
  }

  /**
   * getListClaimReviewers
   * @returns
   */
  setAssignee(AssigneeObject: any): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Claim/assignment`;
    return this.http.put(url, AssigneeObject);
  }

  /**
   * get top 3 latest claim list
   * @returns
   */
  getTopFiveClaimList(): Observable<any> {
    const url = `${environment.fnolApiUrl}/odata/Claim?$top=5&$orderby=CreatedDate desc`;
    return this.http.get(url);
  }

  /**
    Returns an Observable that emits any data or error that occurs during the execution of the function.
    @returns {Observable<any>}
  */
  getLookup(lookupName: string): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/lookup/${lookupName}`;
    return this.http.get(url);
  }

  /**
    Returns an Observable that emits any data or error that occurs during the execution of the function.
    @returns {Observable<any>}
  */
  getLookupByType(lookupName: string): Observable<any> {
    const url = `${environment.autoCoreApi}/api/lookup/type?type=${lookupName}`;
    return this.http.get(url);
  }

  /**
    Returns an Observable that emits any data or error that occurs during the execution of the function.
    @returns {Observable<any>}
  */
  getStates(countyId: number): Observable<any> {
    const url = `${environment.autoCoreApi}/api/Countries/county/${countyId}`;
    return this.http.get(url);
  }

  /**
    Returns an Observable that emits any data or error that occurs during the execution of the function.
    @returns {Observable<any>}
  */
  getCountries(): Observable<any> {
    const url = `${environment.autoCoreApi}/api/Countries`;
    return this.http.get(url);
  }

  /**
    Returns an Observable that emits any data or error that occurs during the execution of the function.
    @returns {Observable<any>}
  */
  getCities(stateId: number): Observable<any> {
    const url = `${environment.autoCoreApi}/api/Countries/county/city/${stateId}`;
    return this.http.get(url);
  }

  /**
   * @param queryParams
   * @returns
   */
  getAssignedClaimList(pagination: ApiPagination, queryParams: string = null): Observable<any> {
    const skip = (pagination.currentPage - 1) * pagination.pageSize;
    queryParams = (!queryParams) ? `$top=${pagination.pageSize}&$skip=${skip}` : `$top=${pagination.pageSize}&$skip=${skip}&${queryParams}`
    const orderBy = `&$orderby=${pagination.orderBy}`
    const select = '$select=AssigneeId, AssigneeName, ClaimGuid, ClaimNo, CreatedDate, CustomerName, FnolId, Id, PolicyNo, Severity, Status, VehicleName, VehicleNumber, CreatedByUserName, IsAssignedBack, CreatedUserId&';
    const url = `${environment.fnolApiUrl}/odata/v2/Claim?${select}${queryParams}${orderBy}`;
    const headers = new HttpHeaders().append('isAssigned', 'true').append('version', 'v2');
    return this.http.get(url, { headers: headers });
  }

  /**
   *
   */
  getTotalCountAssignedClaim(queryParams: string = ''): Observable<any> {
    const url = `${environment.fnolApiUrl}/odata/v2/Claim/$count?${queryParams}`;
    const headers = new HttpHeaders().append('isAssigned', 'true').append('version', 'v2');
    return this.http.get(url, { headers: headers });
  }

  /**
   *
   * @returns
   */
  getCustomerByOrgId(orgId: string): Observable<any> {
    const url = `${environment.fnolApiUrl}/odata/v2/Claim?$apply=filter(OrgId eq ${orgId} AND CustomerName ne null)/groupby((CustomerName))`;
    return this.http.get(url, {
      headers: {
        version: 'v2'
      }
    });
  }

  /**
   *
   * @returns
   */
  getAssignee(): Observable<any> {
    const url = `${environment.autoCoreApi}/api/Users/org-users`;
    return this.http.get(url);
  }


  /**
   * getAssignedStatusCount
   * @returns
   */
  getAssignedStatusCount(): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Claim/statusCount`;
    return this.http.get(url);
  }

  /**
   * getPolicyDetails
   * @returns
   */
  getPolicyDetails(payload): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Claim/policyDetails`;
    return this.http.get(url, {
      headers: payload
    });
  }

  /**
   * Save new Claim
   * @returns Observable
   */
  public createClaim(claimFormData: any): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Claim/create`;
    return this.http.post(url, claimFormData);
  }

  /**
   * getClaimLookups
   * @returns
   */
  getClaimLookups(LookupName: any): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/lookup/${LookupName}`;
    return this.http.get(url);
  }

  /**
   * getGarageList
   * @returns
   */
  getGarageList(query = '', payload = {}): Observable<any> {
    const url = `${environment.autoCoreApi}/odata/garage?${query}`;
    return this.http.get(url, {
      headers: payload
    });
  }

  /**
   * getClaimsOfAGarage
   * @returns
   */
  getClaimsOfAGarage(query = ''): Observable<any> {
    const url = `${environment.fnolApiUrl}/odata/Garage?${query}`;
    return this.http.get(url);
  }

  /**
   * uploadDocument
   * @returns
   */
  uploadDocument(payload): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Claim/document`;
    const formData = new FormData();
    formData.append('claimGuid', payload.claimGuid);
    if (payload.files && payload.files.length > 0) {
      payload.files.forEach((file) => {
        formData.append('Attachments', file);
      });
    }
    return this.fileService.uploadFile(url, formData, false);
  }

  /**
   * uploadDocument
   * @returns
   */
  removeDocument(payload): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Claim/document`;
    return this.http.delete(url, {
      headers: payload
    });
  }

  /**
   * uploadDocument
   * @returns
   */
  assignGarage(type, payload): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Claim/garageAssignment `;
    return this.http[type](url, payload);
  }

  /**
   *
   * @param claimGuid
   * @param status
   * @param reason
   * @returns
   */
  updateClaimStatus(claimGuid: string, status: string, reason?: string): Observable<any> {
    const request = {
      status,
      reason
    }
    const url = `${environment.fnolApiUrl}/api/Claim/${claimGuid}/status`;
    return this.http.put(url, request);
  }

  /**
   *
   * @param inspectionId
   */
  getMissingMediaFields(inspectionId: any): Observable<any> {
    const url = `${environment.autoApi}/api/v1/Inspection/items/${inspectionId}`;
    return this.http.get(url);
  }

  /**
   * getMissingDocumentFields
   * @returns
   */
  getMissingDocumentFields(): Observable<any> {
    const url = `${environment.mediaManagementUrl}/api/MediaDocuments/documents`;
    return this.http.get(url);
  }

  /**
   *
   * @param type
   * @returns
   */
  getMissingCustomFields(type: string): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/lookup/custom/${type}`;
    return this.http.get(url);
  }

  /**
   *
   * @param params
   * @returns
   */
  sendMissingInfo(params: any): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/claim/sendMissingInformation`;
    return this.http.post(url, params);
  }

  /**
   * get missing info
   */
  getMissingInfo(claimGuid: string): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Claim/missing-information/${claimGuid}`;
    return this.http.get(url);
  }
  /**
   *
   * @param payload
   * @returns
   */
  getGarageDashboardDetail(payload: any = {}): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Claim/garagedashboarddetail`;
    return this.http.get(url, {
      headers: payload
    });
  }

  /**
   * getEstimateLog
   *
   */
  getEstimateLog(claimId: any): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/integrationrepair/estimate-log/${claimId}`;
    return this.http.get(url);
  }

  /**
   * get fraud detection vehicle
   * @param claimGuid
   * @param version
   * @param inspectionId
   * @returns
   */
  getFraudDetectionVehicle(claimGuid: string, version: string, inspectionId: number): Observable<any> {
    const url = `${environment.xaAggregationApi}/claiminspectionfrauddetails/${claimGuid}/${version}/${inspectionId}`;
    return this.http.get(url);
  }

  /**
   * get fraud detection pdf
   * @param params 
   * @returns 
   */
  getFraudDetectionPdf(params: any): Observable<any> {
    const url = `${environment.communicationApi}/api/Report/fraud-detection`;
    return this.http.post(url, params);
  }

  /**
   * download fraud detection pdf
   * @param params 
   */
  downloadFraudDetectionPdf(params): void {
    const url = `${environment.communicationApi}/api/Report/fraud-detection`;

    this.http
      .post(url, params, { responseType: 'blob' as 'json' },)
      .subscribe({
        next: (res: any) => {
          const file = new Blob([ res ], { type: res.type });

          // IE
          const nav = (window.navigator as any);
          if (nav.msSaveOrOpenBlob) {
            nav.msSaveOrOpenBlob(file);
            return
          }

          const blob = window.URL.createObjectURL(file);
          const link = document.createElement('a');

          const createdDate = dayjs(new Date()).format('MMDDYYYY');
          link.href = blob;
          link.download = `Fraud_Detection_${createdDate}.pdf`;

          // Version link.click() to work at firefox
          link.dispatchEvent(
            new MouseEvent('click', {
              bubbles: true,
              cancelable: true,
              view: window
            })
          );

          setTimeout(() => {
            // firefox
            window.URL.revokeObjectURL(blob);
            link.remove();
          }, 100);
        },
        error: (error: any) => {
          console.log(error);
        }
      });
  }

  /**
   * getEstimateDetails
   */
  getLatestEstimateDetails(claimId: any): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/integrationrepair/estimate-details/${claimId}`;
    return this.http.get(url);
  }

  /**
   * approveRejectEstimate
   */
  approveRejectEstimate(claimId: any, paramObj: any): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/integrationrepair/estimate-status/${claimId}`;
    return this.http.post(url, paramObj);
  }

  /**
   * editEstimateAddRevision
   *
   */
  editEstimateAddRevision(claimId: any, paramObj: any): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/integrationrepair/estimate-revision/${claimId}`;
    return this.http.post(url, paramObj);
  }

  /**
   * submitGarageAssignmentAction
   *
   */
  submitGarageAssignmentAction(payload, headers): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/claim/garageAssignment/notify`;
    return this.http.post(url, payload, { headers });
  }

  /**
   * submitGarageAssignmentAction
   *
   */
  requestForEstimate(claimGuid, actionType): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/claim/request/${claimGuid}/${actionType}`;
    return this.http.get(url);
  }

  /**
   * submitRegisteredGarageEstimate
   *
   */
  submitRegisteredGarageEstimate(formData, headers): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/claim/claim-estimate`;
    return this.http.post(url, formData, { headers });
  }

  /**
   * submitRegisteredGarageInvoice
   *
   */
  submitRegisteredGarageInvoice(formData, headers): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/claim/claim-invoice`;
    return this.http.post(url, formData, { headers });
  }

  /**
   * getEstimateThirdPartyRegisteredGarage
   *
   */
  getEstimateThirdPartyRegisteredGarage(claimGuid): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/claim/claim-estimate/${claimGuid}`;
    return this.http.get(url);
  }


  /**
   * get dashboard data
   * @returns
   */
  getDashboardData(payload): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Dashboard`;
    return this.http.post(url, payload);
  }

  /**
   * getInvoiceThirdPartyRegisteredGarage
   *
   */
  getInvoiceThirdPartyRegisteredGarage(claimGuid): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/claim/claim-invoice/${claimGuid}`;
    return this.http.get(url);
  }

  /**
   * getInvoiceInternalGarage
   *
   */
  getInvoiceInternalGarage(claimGuid): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/integrationrepair/invoice/${claimGuid}`;
    return this.http.get(url);
  }

  /**
   *
   */
  getAskForRevisionEmailTemplate(claimGuid: any, domainId: any): Observable<any> {
    const url = `${environment.xaAggregationApi}/claimcommunicationtemplate/${claimGuid}/${domainId}/ClaimAskForRevision`
    return this.http.get(url);
  }

  /**
   * getInvoiceInternalGarage
   *
   */
  saveGarageAssignmentSettings(payload): Observable<any> {
    const url = `${environment.autoCoreApi}/api/settings`;
    return this.http.post(url, payload);
  }

  /**
   * getGarageAssignmentSettings
   *
   */
  getGarageAssignmentSettings(settingsType: string): Observable<any> {
    const url = `${environment.autoCoreApi}/api/settings/${settingsType}`;
    return this.http.get(url);
  }

  /**
   * sendWhatsappAskForRevisionNotification
   *
   */
  sendWhatsappAskForRevisionNotification(payload): Observable<any> {
    const url = `${environment.communicationApi}/api/message`;
    return this.http.post(url, payload);
  }

  /**
   * sendEmailAskForRevisionNotification
   *
   */
  sendEmailAskForRevisionNotification(payload): Observable<any> {
    const url = `${environment.communicationApi}/api/message/email`;
    return this.http.post(url, payload);
  }

  /**
   * getGarageAssignmentSettings
   *
   */
  getAutoGarageAssignment(payload): Observable<any> {
    const url = `${environment.autoCoreApi}/api/garage/auto-garage-recommendation`;
    return this.http.get(url, {
      params: payload,
      headers: {
        rejectedGarageIds: payload.rejectedGarageIds || ''
      }
    });
  }

  /**
   * saveGaragePreference
   *
   */
  saveGaragePreference(payload): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/GaragePreference`;
    return this.http.post(url, payload);
  }

  /**
   * get fraud detection and damage severity
   */
  updateFraudDetection(claimGuid: string): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Claim/fraud-detection/${claimGuid}`;
    return this.http.put(url, null);
  }

  /**
   * update fraud detection document
   * @param claimGuid 
   * @returns 
   */
  updateFraudDetectionDocument(claimGuid: string): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Claim/document-fraud-detection/${claimGuid}`;
    return this.http.put(url, null);
  }

  /**
   * third party approve estimate
   *
   */
  thirdPartyApproveEstimate(claimGuid, status): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/claim/update-estimatestatus/${claimGuid}/${status}`;
    return this.http.put(url, {});
  }

  /**
   * third party approve estimate
   * 
   */
  getCustomerSatisfactionGraphData(feedbackType: string, payload: any): Observable<any> {
    const url = `${environment.autoCoreApi}/api/feedback/satisfaction/${feedbackType}`;
    return this.http.post(url, payload);
  }
  /**
   * get surveyor list
   */
  getSurveyorList(): Observable<any> {
    const url = `${environment.autoCoreApi}/api/Users/claim-surveyor`;
    return this.http.get(url);
  }

  /**
 * assign surveyor
 */
  assignSurveyor(payload: any): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/claim/assignment`;
    return this.http.put(url, payload);
  }

  /**
 * get garages rating
 * @param claimGuid 
 * @returns 
 */
  getGarageRatings(garageId: string): Observable<any> {
    const url = `${environment.autoCoreApi}/api/feedback/garage-rating/${garageId}`;
    return this.http.get(url);
  }

  /**
   * lock estimation 
   */
  lockEstimation(claimId: number, status: string): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/integrationrepair/${claimId}/lock/${status}`;
    return this.http.put(url, null);
  }

  /** 
   * get survey pdf 
   */
  getSurveyPdf(claimGuid: string, inspectionId: string, objectId: string): Observable<any> {
    const url = `${environment.xaAggregationApi}/claimSurveyorinspectiondamagereportdata/${claimGuid}/1/${inspectionId}/${objectId}`;
    return this.http.get(url);
  }

  /** 
   * get survey pdf 
   */
  getSettingsTypes(): Observable<any> {
    const url = `${environment.autoCoreApi}/api/Lookup/type?type=SettingType&isEnum=true`;
    return this.http.get(url);
  }

  /**
   * get dashboard data
   * @returns
   */
  getClaimAvgProcessingTime(payload): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/dashboard/avg-time`;
    return this.http.post(url, payload);
  }

  /**
     * get dashboard data
     * @returns
     */
  getClaimStatusBreakdown(payload): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/dashboard/status-breakdown`;
    return this.http.post(url, payload);
  }


  /**
   *  getAvgTurnAroundTimeThirdParty
   * 
   * @param payload 
   * @returns 
   */
  getAvgTurnAroundTimeThirdParty(payload: any): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Dashboard/garage-avg-time`;
    return this.http.post(url, payload);
  }

  /**
   *  getAvgTurnAroundTimeInternal
   * 
   * @param payload 
   * @returns 
   */
  getAvgTurnAroundTimeInternal(payload: any): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/dashboard/7`;
    return this.http.post(url, payload);
  }

  /**
   *  getPartnershipGarages
   * 
   * @param payload 
   * @returns 
   */
  getPartnershipGarages(): Observable<any> {
    const url = `${environment.autoCoreApi}/api/garage/garage-list`;
    return this.http.get(url);
  }

  /**
   *  getGaragesByPerformance
   * 
   * @param payload 
   * @returns 
   */
  getGaragesByPerformance(payload): Observable<any> {
    const url = `${environment.xaAggregationApi}/GaragePerformance`;
    return this.http.get(url, {
      headers: {
        dashboardRequest: JSON.stringify(payload)
      }
    });
  }

  /**
   *  getGaragesByCost
   * 
   * @param payload 
   * @returns 
   */
  getGaragesByCost(payload): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Dashboard/garage-avg-cost`;
    return this.http.post(url, payload);
  }

  /**
   *  saveFraudDetectionSettings
   * 
   * @param payload 
   * @returns 
   */
  saveFraudDetectionSettings(payload): Observable<any> {
    const url = `${environment.autoApi}/api/v1/Inspection/update-config`;
    return this.http.put(url, payload);
  }

  /**
   *  getFraudDetectionSettings
   * 
   * @param payload 
   * @returns 
   */
  getFraudDetectionSettings(): Observable<any> {
    const url = `${environment.autoApi}/api/v1/Inspection/fraud-config`;
    return this.http.get(url);
  }

  /**
   * create intimation
   * @param data 
   * @returns 
   */
  createIntimation(data: any): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/v2/FNOL`;
    const headers = new HttpHeaders({
      'version': 'v2'
    });
    return this.http.post(url, data, { headers: headers });
  }

  /**
   *  getIntimations
   * 
   * @param payload 
   * @returns 
   */
  getIntimations(pagination: ApiPagination, queryParams: string = null): Observable<any> {
    const skip = (pagination.currentPage - 1) * pagination.pageSize;
    queryParams = (!queryParams) ? `$top=${pagination.pageSize}&$skip=${skip}` : `$top=${pagination.pageSize}&$skip=${skip}&${queryParams}`
    const orderBy = `&$orderby=${pagination.orderBy}`
    const url = `${environment.fnolApiUrl}/odata/FNOL?$count=true&${queryParams}${orderBy}`;
    return this.http.get(url);
  }

  /**
   *  getFnolDetails
   * 
   * @param payload 
   * @returns 
   */
  getFnolDetails(fnolGuid): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/FNOL/${fnolGuid}`;
    return this.http.get(url);
  }

  /**
   *  getExternalClaimDetails
   * 
   * @param payload 
   * @returns 
   */
  getExternalClaimDetails(payload): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/claim/claimant-details`;
    return this.http.post(url, payload);
  }

  /**
   *  getReserveSetting
   * @returns 
   */
  getReserveSetting(): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Settings/reserve`;
    return this.http.get(url);
  }

  /**
   *  saveReserveSetting
   * @returns 
   */
  saveReserveSetting(payload): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Settings/reserve`;
    return this.http.post(url, payload);
  }

  /**
   *  getFinancialSummary
   * @returns 
   */
  getFinancialSummary(payload): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/financial/reserve-summary`;
    return this.http.post(url, payload);
  }

  /**
   *  getFinancialSummary
   * @returns 
   */
  updateClaimReserve(payload): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/financial/claimReserves`;
    return this.http.put(url, payload);
  }
  
  /**
   *  getFinancialSummary
   * @returns 
   */
  updateReserveWithTotalLoss(payload): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/financial/total-loss-reserve`;
    return this.http.put(url, payload);
  }
  
  /**
   * get coverage types
   * @param policyNumber 
   * @param claimNo 
   */
  getCoverageTypes(objectGuid: string, policyNumber: string, intimationTypeId?: string): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Settings/coverageType`;
    const headers = new HttpHeaders({
      'policyNo': policyNumber,
      'objectGuid': objectGuid,
      'subIntimationTypeId': intimationTypeId || ''
    });
    return this.http.get(url, { headers: headers })
  }

  /**
   *getValuationFromXAData
   */
  getValuationFromXAData(vin: string): Observable<any> {
    const url = `${environment.autoApi}/api/v2/Vehicles/getVehicleValue/${vin}`;
    const headers = new HttpHeaders({
      'version': 'v2',
    });
    return this.http.get(url, { headers: headers })
  }
  /**
   *getTotalLossThreshold
   */
  getTotalLossThreshold(claimGuid: string, lossDate?: string): Observable<any> {
    let url = `${environment.fnolApiUrl}/api/claim/total-loss/${claimGuid}`;
    if(lossDate) url += `?lossDate=${lossDate}`
    return this.http.get(url)
  }

  /**
   * getBeneficiaryList
   */
  getBeneficiaryList(pagination: ApiPagination, queryParams: string = null): Observable<any> {
    const skip = (pagination.currentPage - 1) * pagination.pageSize;
    queryParams = (!queryParams) ? `$top=${pagination.pageSize}&$skip=${skip}` : `$top=${pagination.pageSize}&$skip=${skip}&${queryParams}`
    const orderBy = `&$orderby=${pagination.orderBy}`
    const url = `${environment.autoCoreApi}/odata/beneficiaryDetail?$count=true&${queryParams}${orderBy}`;
    return this.http.get(url)
  }

  /**
   * get beneficiary lookup partnership or beneficiary
   * @returns 
   */
  getBeneficiaryLookup(): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/lookup/BeneficiaryLookupType`;
    return this.http.get(url);
  }

  /**
   * add beneficiary
   */
  addBeneficiary(obj: any): Observable<any> {
    const url = `${environment.autoCoreApi}/api/beneficiaryDetail`;
    return this.http.post(url, obj);
  }

  /**
   * update beneficiary
   */
  updateBeneficiary(obj: any): Observable<any> {
    const url = `${environment.autoCoreApi}/api/beneficiaryDetail`;
    return this.http.put(url, obj);
  }

  /**
   * get beneficiary detail
   * @param id 
   * @returns 
   */
  getBeneficiaryDetail(id: any): Observable<any> {
    const url =`${environment.autoCoreApi}/odata/beneficiaryDetail?$count=true&$filter=id eq ${id}`
    return this.http.get(url)
  }

  /**
   * delete beneficiary detail
   * @param guid 
   */
  deleteBeneficiaryDetail(guid: string): Observable<any> {
    const url =`${environment.autoCoreApi}/api/beneficiaryDetail/${guid}`;
    return this.http.delete(url);
  }

  /**
   * getLOPURL
   * @param guid 
   */
  getLpoURL(guid: string): Observable<any> {
    const url =`${environment.fnolApiUrl}/api/claim/lpo-document/${guid}`;
    return this.http.get(url);
  }

  /**
   * getLOPURL
   * @param guid 
   */
  getThirdPartyPartnership(lookupType, orgId): Observable<any> {
    const url =`${environment.autoCoreApi}/odata/Partnership?$filter=PartnershipTypeLookupName eq '${lookupType}' and   PartnershipOrgDTOs/any(c: c/OrgId eq ${orgId})`;
    return this.http.get(url);
  }


  /** 
   * get ScheduleFrequencies
   */
  getScheduleFrequencies(): Observable<any> {
    const url = `${environment.autoCoreApi}/api/Lookup/type?type=ScheduleFrequency&isEnum=true`;
    return this.http.get(url);
  }

  /**
   * upload Docs
   */
  uploadDocs(files:any, mediaCollectionGuid, claimId): Promise<any> {
    let uploadCount = 0;
    return new Promise((resolve, reject)=> {
      if(files?.length > 0) {
        const uploadedFiles:any[] = [];
        for (let i = 0; i < files?.length; i++) {
          const obj = {
            'mediaCollectionId': mediaCollectionGuid,
            'domainId': 4100, // make domain id dynamic,
            'objectId': claimId,
            'mediaTemplateId': files[i].mediaTemplateGuid,
            'mediaPartTemplateId': files[i].mediaPartTemplateGuid,
          }
          this.mediaCollectionService.uploadDocument(files[i].file, obj).subscribe({ next: (res)=> {
            uploadedFiles.push({ 
              name: `${files[i].name}`, 
              blobUrl: res.data.blobUrl, 
              imageSize: files[i].size,
              mediaGuid: res.data.mediaGuid,
              mediaPartGuid: res.data.mediaPartGuid 
            });
            uploadCount++;
            if(uploadCount === files.length) {
              resolve(uploadedFiles);
            }
          }, error: (err)=> {
            reject(err);
          } })
        }
      }
    })
  }

  /**
   * updateRepairIntimationNotificationStatus
   * @param {*} bookingGuid
   * @param {string} status
   * @returns {Observable<any>}
   */
  updateRepairIntimationNotificationStatus(notificationGuid: string, bookingGuid: string, status: number): Observable<any> {
    const url = `${environment.fnolApiUrl}/api/Integration/claimrepairnotification/${bookingGuid}/${notificationGuid}/${status}`;
    return this.http.put(url, {});
  }
}
