// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  ai: {
    enableRecursion: true,
    recursionTimeout: 15000,
    maxRetryCount: 10
  },
  financeURL: 'https://apim-dih-dev01.azure-api.net/finance-integration-dev',
  baseUrl: 'https://carheal-dev.xagroup.com',
  caseMgmtUrl: 'https://apim-dih-dev01.azure-api.net/case-management-api-dev',
  bookingMgmtUrl: 'https://apim-dih-dev01.azure-api.net/booking-management-api-dev',
  autoCoreApi: 'https://apim-dih-dev01.azure-api.net/autocoreapi-dev',
  autoApi: 'https://apim-dih-dev01.azure-api.net/AutoAPI',
  communicationApi: 'https://apim-dih-dev01.azure-api.net/ComAPI',
  xaAggregationApi: 'https://apim-dih-dev01.azure-api.net/xaaggregationapi-dev',
  subscriptionKey: '66aceaedc1d54914b28bcc1ea79688ac',
  mediaManagementUrl: 'https://apim-dih-dev01.azure-api.net/mediamanagement-api-dev',
  fnolApiUrl: 'https://apim-dih-dev01.azure-api.net/fnolapi-dev',
  b2cSettings: {
    authorityDomain: 'xagrouptest.b2clogin.com',
    b2cOAuthDomain: 'xagrouptest.onmicrosoft.com',
    caseManagementApiScope: 'https://xagrouptest.onmicrosoft.com/f0edb403-95eb-45db-a09b-02b10b84c227/XASystemRepairDevAPI',
    caseManagementAppClientId: 'f0edb403-95eb-45db-a09b-02b10b84c227',
    resetPasswordPolicySettings: {
      policy: 'B2C_1A_PASSWORDRESET_DEV',
      scope: 'openid',
      nonce: 'defaultNonce',
      response_type: 'id_token',
      promptLogin: true
    }
  },
  appInsights: {
    instrumentationKey: 'e08af91c-24c7-4ff5-be2c-6aeaf9dc130a',
    connectionString:
      'InstrumentationKey=e08af91c-24c7-4ff5-be2c-6aeaf9dc130a;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/',
  },
  signalR: {
    notifyUrl: 'https://apim-dih-dev01.azure-api.net/ComAPI/Notify',
    listenerMethodNameForUser: 'SendMessageToGroups',
    subscribeMethodName: 'SuscribeToUserNotification',
    repairNotifyUrl: 'https://apim-dih-dev01.azure-api.net/case-management-api-dev/notify',
    repairListenerMethodName: 'receivemessage',
    bookingListenerMethodName: 'receivebookingmessage'
  },
  googleMapsKey: 'AIzaSyCv5LbsiFn1zEl6BN9S8v-YGnqH1t2-wn8',
  externalB2CSignupPolicy: {
    'XA': 'B2C_1A_SIGNIN_DEV',
    'TATA': 'B2C_1A_SIGNIN_DEV_TATA_KC',
    'MG': 'B2C_1A_SIGNIN_DEV_MG',
    'LIVA': 'B2C_1A_SIGNUP_SIGNIN_LIVA_DEV',
    'XA360': 'B2C_1A_SIGNUP_SIGNIN_XA360_MOBILE_OTP'
  },
  keyCloakLogoutUrl: 'https://sso-dev-vme.api.tatamotors.com/auth/realms/iconnectit-pv/protocol/openid-connect/logout?post_redirect_uri=',
  platformConfiguration: {
    ApiKey: 'uzHWNZc+8d+8zCeDPQRI6g==',
    ApplicationId: 'E5A919B5-92B5-47F8-95E6-E638FE118090',
    AppIdSign: 'zF5VoDuTFY1RmnNiw9gyoX9w9nDccDuObNO3dC2IRNnxLtxUK1XTuP5vPMjMa47a+Q9HfdL9D8UCDixhC/yF0w=='
  },
  tenantList: 'https://stfnoldev001.blob.core.windows.net/images/TenantDetails/Dev/TenantDetails.json'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
