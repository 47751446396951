import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RepairEstimateService {

  private gtEstimateUrl: string = 'gtmotive';

  /**
   * constructor
   * @param http
   */
  constructor(private http: HttpClient) {
  }

  /**
   * get gt Estimate  case
   * @returns Observable
   */
  public getEstimate(caseId: string, inspectionId: number, vehicleId: number, domainId:number, localTime:string): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/${this.gtEstimateUrl}/estimate/${caseId}/${inspectionId}/${vehicleId}/${domainId}`;
    const headers = new HttpHeaders().append('localtime', localTime);
    return this.http.get(url, { headers });
  }

  /**
   * update/read gt Estimate  case
   * @returns Observable
   */
  public readUpdateEstimate(caseId: string, inspectionId: number, vehicleId: number, httpVerb: string, domainId:number, localTime:string): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/${this.gtEstimateUrl}/estimate`;
    const headers = new HttpHeaders().append('localtime', localTime);
    const postData = {
      'caseGuid': caseId,
      'inspectionId': inspectionId,
      'vehicleId': vehicleId,
      domainId
    }
    return (httpVerb == 'put') ? this.http.put(url, postData, { headers }) : this.http.get(url);
  }

  /**
   * create Gt Estimate  case
   * @returns Observable
   */
  public createEstimate(caseId: string, inspectionId: number, vehicleId: number, domainId: number): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/${this.gtEstimateUrl}/estimate`
    const postData = {
      'caseGuid': caseId,
      'inspectionId': inspectionId,
      'vehicleId': vehicleId,
      domainId
    }
    return this.http.post(url, postData);
  }

  /**
   * create Gt Estimate from repair
   * @returns Observable
   */
  public createEstimateFromRepair(repairId: string): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/${this.gtEstimateUrl}/estimate/repair/${repairId}`;
    return this.http.post(url, null);
  }

  /**
   * get download url for pdf
   */
  getDownloadUrl(caseGuid: string): Observable<any> {
    const localTime = new Date().toString();
    const headers = new HttpHeaders().append('localtime', localTime)
    const url = `${environment.caseMgmtUrl}/api/gtmotive/estimate/report/${caseGuid}`;
    return this.http.get<any>(url, { headers });
  }

  /**
   * get download url for differnt pdf
   */
  getSpecificDownloadUrl(caseGuid: string, reportType:string, localTime:string=''): Observable<any> {
    const headers = new HttpHeaders().append('localtime', localTime)
    const url = `${environment.caseMgmtUrl}/api/cases/repair/estimate/report/${caseGuid}/${reportType}`;
    return this.http.get<any>(url, { headers });
  }

  /**
   * get xa report url for pdf
   */
  getXADownloadUrl(caseGuid: string, localTime:string=''): Observable<any> {
    const headers = new HttpHeaders().append('localtime', localTime)
    const url = `${environment.caseMgmtUrl}/api/cases/repair/estimate/report/${caseGuid}`;
    return this.http.get<any>(url, { headers });
  }


  /**
    Retrieves the repair estimate for a given repair GUID, inspection ID, and vehicle ID.
    @param {string} repairGuid - The GUID of the repair.
    @param {number} inspectionId - The ID of the inspection.
    @param {number} vehicleId - The ID of the vehicle.
    @returns {Observable} - An Observable that emits the repair estimate.
  */
  getRepairEstimate(repairGuid: string, inspectionId: number, vehicleId: number): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/manualestimate/${repairGuid}/${inspectionId}/${vehicleId}`;
    return this.http.get<any>(url);
  }

  /**
   * getEstimateLog
   * @param {string} repairOrCaseGuid
   * @returns {Observable<any>}
   */
  getEstimateLog(serviceType, estimateId: number): Observable<any>{
    const url = `${environment.caseMgmtUrl}/api/estimateLog/${estimateId}`;
    return this.http.get<any>(url);
  }

  /**
   * getEstimateLog
   * @param {string} repairOrCaseGuid
   * @returns {Observable<any>}
   */
  downloadEstimateReportCSV(caseGuid): Observable<any>{
    const url = `${environment.caseMgmtUrl}/api/cases/repair/estimate/csvreport/${caseGuid}`;
    return this.http.get<any>(url);
  }

  /**
   * get Addenda Estimate By RepairId
   *
   * @param {string} repairGuid
   * @param {number} calculationTypeId
   * @returns {Observable<any>}
   */
  getAddendaEstimateByRepairId(repairGuid:string, calculationTypeId:number): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/addendaestimate/${repairGuid}/${calculationTypeId}`;
    return this.http.get(url)
  }

  /**
   * save Addenda Estimate
   *
   * @param {*} obj
   * @param {string} repairGuid
   * @param {number} calculationTypeId
   * @returns {Observable<any>}
   */
  saveAddendaEstimate(obj:any, repairGuid:string, calculationTypeId:number): Observable<any> {
    const url = `${environment.caseMgmtUrl}/api/addendaestimate/${repairGuid}/${calculationTypeId}`;
    return this.http.post(url, obj)
  }

  /**
   * get DamageSeverities
   *
   * @returns {Observable<any>}
   */
  getDamageSeverities(): Observable<any> {
    const url = `${environment.autoApi}/api/v1/DamageSeverities`;
    return this.http.get(url);
  }

  /**
   * get Vehicle Section
   *
   * @returns {Observable<any>}
   */
  getVehicleSection(): Observable<any> {
    const url = `${environment.autoApi}/api/v1/Lookup/vehicleSection`;
    return this.http.get(url);
  }

  /**
   * get Standard Parts odata
   *
   * @param {string} query
   * @returns {Observable<any>}
   */
  getStandardParts(query:string, BodyTypeId, VehicleSectionId): Observable<any> {
    const url = `${environment.autoApi}/odata/v1/StandardParts${query}`;
    // Define the headers
    const headers = new HttpHeaders({
      'BodyTypeId': BodyTypeId,
      'VehicleSectionId': VehicleSectionId,
    // Add more headers as needed
    });
    return this.http.get(url, { headers });
  }
  /**
   * getEstimateLog
   * @param {string} repairOrCaseGuid
   * @returns {Observable<any>}
   */
  updateEstimateV2(repairGuid, payload): Observable<any>{
    const url = `${environment.caseMgmtUrl}/api/addendaestimate/${repairGuid}`;
    return this.http.put<any>(url, payload);
  }

  /**
   * getEstimateLog
   * @param {string} repairOrCaseGuid
   * @returns {Observable<any>}
   */
  generateAiEstimate(inspectionId, objectId, version): Observable<any>{
    const url = `${environment.autoApi}/api/v${version}/Inspection/ai-estimation/${inspectionId}/${objectId}`;
    return this.http.get<any>(url);
  }

  /**
   * getEstimateLog
   * @param {string} repairOrCaseGuid
   * @returns {Observable<any>}
   */
  markChargeUpSaleNotUpSale(repairGuid, payload): Observable<any>{
    const url = `${environment.caseMgmtUrl}/api/addendaestimate/${repairGuid}`;
    return this.http.patch<any>(url, payload);
  }
}
