<chq-step-header [chqModel]="aiAnalysisHeader"
                 [backTitle]="'back_ai'"
                 *ngIf="!isReviewSection && !showRepairRecommendation"
                 [hideHeader]="true"
                 [hideBack]="isRepairModule || isClaimModule || fnolGUID !== ''"
                 [showButton]="!isRepairModule && !isClaimModule && fnolGUID === ''"
                 (back)="handleBack($event)"></chq-step-header>
<div>
    <ng-container *ngIf="!showRepairRecommendation">
        <div class="main-container"
             [ngClass]="{'no-border': isReviewSection, 'detail-section': !isReviewSection }">

            <div class="info-container"
                 *ngIf="!isReviewSection">

                <div class="header-div">
                    <h3 class="header-label text-black">
                        {{currentStep?.stepName}}
                    </h3>
                    <mat-icon [svgIcon]="'checked'"
                              aria-hidden="false"
                              aria-label="checked icon"></mat-icon>
                </div>
                <div class="image-wrapper">
                    <div class="image-column">
                        <div class="block-image">
                            <span>ORIGINAL IMAGE</span>
                            <div class="car-image-container">
                                <img [src]="currentStep?.placeHolderUrl"
                                     (click)="imageDialog('ORIGINAL IMAGE',currentStep?.placeHolderUrl);"
                                     (load)="imageLoading[currentStep?.stepName + '_' + currentStep?.index]=true"
                                     alt="image" />
                                <chq-custom-loader [showLoading]="!imageLoading[currentStep?.stepName+ '_' + currentStep?.index]"></chq-custom-loader>
                            </div>
                        </div>
                        <div class="block-image">
                            <span>ANALyzed IMAGE</span>
                            <div class="car-image-container">
                                <img [src]="currentStep?.url || currentStep?.placeHolderUrl"
                                     (click)="imageDialog('ANALyzed IMAGE',currentStep?.url || currentStep?.placeHolderUrl);"
                                     (load)="imageLoading[currentStep?.stepName+ '_' + currentStep?.index]=true"
                                     alt="image" />
                                <chq-custom-loader [showLoading]="!imageLoading[currentStep?.stepName+ '_' + currentStep?.index]"></chq-custom-loader>
                            </div>
                        </div>

                    </div>
                    <div class="table-container">
                        <div class="header-container">
                            <div class="header">
                                <h4>{{'vehicle' | translate}}</h4>
                                <div class="header-wrapper">
                                    <div class="text-wrapper align-start">
                                        <label>Type:&nbsp; </label>
                                        <label>{{caseDetail?.vehicle?.vehicleMake ??
                                            caseDetail?.vehicleMake}}&nbsp;{{caseDetail?.vehicle?.vehicleModel ??
                                            caseDetail?.vehicleModel}}</label>
                                    </div>
                                    <div class="text-wrapper align-start">
                                        <label>View:&nbsp; </label>
                                        <label>{{currentStep?.stepName}}</label>
                                    </div>
                                </div>
                            </div>
                            <!-- Repair Category -->
                            <div *ngIf="aiAnalysisResult.length > 0 && !currentStep.isEmpty && displayCategory"
                                 class="header flex-basis-20">
                                <h4>Repair Category</h4>
                                <div class="header-wrapper">
                                    <div class="text-wrapper">
                                        <img [src]="currentRepairCategory?.categorizationIconUrl"
                                             alt="repair category logo">
                                        <label>{{currentRepairCategory?.categorization}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="!isTechnician && !fromRepairerView"
                             class='add-analysis-wrapper'>
                            <chq-button (click)='this.addAnalysis()'
                                        [buttonModel]="addAnalysisBtn"></chq-button>
                        </div>
                        <div class="table-wrapper"
                             *ngIf="aiAnalysisResult.length > 0 && !currentStep.isEmpty">
                            <div class="table-header-wrapper row">
                                <h4 class="border-right single-margin"
                                    [ngClass]="{'col-3': !isTechnician, 'col-4': isTechnician}">
                                    {{'part_wise_damage' | translate}}
                                </h4>
                                <h4 class="border-right" 
                                [ngClass]="{'col-3': isClaimModule || fromRepairerView, 'col-2': !isClaimModule && !fromRepairerView }">{{'part_conf' | translate}}</h4>
                                <h4 class="col-2 border-right" *ngIf="!isClaimModule && !fromRepairerView">{{'damage_conf' | translate}}</h4> 
                                <h4 class="border-right"
                                    [ngClass]="{'col-3': !isTechnician && !fromRepairerView && !isClaimModule, 'col-4': isTechnician ||  fromRepairerView || isClaimModule }">{{'recommendation' | translate}}</h4>
                                <h4 *ngIf="!isTechnician  && !fromRepairerView"
                                    class="col-2 border-right">{{'actions' | translate}}</h4>
                            </div>
                            <div class="row data-cols"
                                 *ngFor="let row of aiAnalysisResult">
                                <span class="border-right single-margin"
                                      [ngClass]="{'col-3': !isTechnician && !fromRepairerView, 'col-4': isTechnician || fromRepairerView}">{{row.partWiseDetails}}</span>
                                <span class="border-right"
                                [ngClass]="{'col-3': isClaimModule || fromRepairerView, 'col-2': !isClaimModule && !fromRepairerView }">{{row.partConfidence ? ((row.partConfidence * 100 |
                                    number:'1.0-0')
                                    + '%'):''}}</span>
                                <span class="col-2 border-right" *ngIf="!isClaimModule && !fromRepairerView">{{row.damageConfidence ? ((row.damageConfidence * 100 |
                                    number:'1.0-0')+
                                    '%'):''}}</span> 
                                <span class="border-right"
                                      [ngClass]="{'col-3': !isTechnician && !fromRepairerView && !isClaimModule, 'col-4': isTechnician  ||  fromRepairerView || isClaimModule}">{{row.damageRecommendation}}</span>
                                <div *ngIf="!isTechnician && displayEdit && !fromRepairerView"
                                     class='col-2 action-btn'>
                                    <mat-icon (click)='editAnalysis(row)'
                                              svgIcon="edit"></mat-icon>
                                    <mat-icon (click)='deleteAnalysis(row)'
                                              class='delete'
                                              svgIcon="trash"></mat-icon>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="aiAnalysisResult.length === 0 || currentStep.isEmpty">
                            <label class="no-data">{{'no_data_available' | translate}}</label>
                        </div>
                    </div>

                </div>
            </div>
            <div class="vehicle-status-container"
                 *ngIf="!isReviewSection && !fromRepairerView">
                <chq-vertical-image-preview [aiAnalysis]="true"
                                            (refresh)="refreshData()"
                                            [page]="this.inspection"
                                            [steps]="imageStatusSteps"
                                            (selection)="setCurrentStep($event);"></chq-vertical-image-preview>
            </div>
            <div *ngIf="!isReviewSection"
                 class="footer-full-wrapper">
                <div class="footer-image-wrapper">
                    <div class="image-wrapper">
                        <img src="assets/img/{{isRepairModule? 'addenda-repair' : isClaimModule ? 'addenda-claims' : isQuoteAssessmentModule ? 'addenda' : 'addenda-quote'}}.png"
                             alt="image" />
                    </div>
                </div>
                <div class="footer-button-wrapper"
                     *ngIf="!isClaimModule && !isQuoteAssessmentModule && !fromRepairerView">
                    <chq-button *ngIf="!hideNext && !isRepairStage"
                                [buttonModel]="nextButton"></chq-button>
                </div>
            </div>
            <chq-review-section #chqReviewSection
                                [deletedIds]="deletedId"
                                [minimumPhotosRequired]="minPhotoRequired"
                                (triggerPDF)="createPdf();"
                                [footerOptions]="footer"
                                *ngIf="isReviewSection && !showRepairRecommendation"
                                (refreshEvent)="refreshData()"
                                [header]="aiAnalysisHeader"
                                (selection)="handleReviewAction($event);"
                                [steps]="imageStatusSteps"
                                [isRepairStage]="isRepairStage"
                                [originalSteps]="originalSteps"
                                [isMultipleUpload]="isBulkUpload"
                                [backTitle]="'back_ai'"
                                (damageAnalysisComplete)="damageAnalysisComplete($event)"
                                [inspectionId]="inspectionId"
                                [sendRequest]="sendRequest"
                                [repairOrCaseData]="caseDetail">
                <div *ngIf="isReviewSection && !notAnalysedFiles"
                     class="repair-recommendation-wrapper">
                    <chq-button [buttonModel]="repairRecommendationBtn"></chq-button>
                </div>
            </chq-review-section>

        </div>
    </ng-container>
</div>
<repair-recommendation *ngIf="showRepairRecommendation"
                       [damageRecommendations]="damageReportData?.damageRecommendations"
                       (backEvent)="showRepairRecommendation = false"
                       [damageReport]="damageReportData?.damageDetails"></repair-recommendation>
<div *ngIf="showRepairRecommendation"
     class="footer-full-wrapper">
    <div class="footer-image-wrapper">
        <div class="image-wrapper">
            <img *ngIf="fnolGUID === ''"
                 src="assets/img/{{isRepairModule? 'addenda-repair' : isClaimModule ? 'addenda-claims' : isQuoteAssessmentModule ? 'addenda' :'addenda-quote'}}.png"
                 alt="png" />
        </div>
    </div>
    <div class="footer-button-wrapper">
        <chq-button [buttonModel]="pdfButton"></chq-button>
    </div>
</div>

<chq-pdf-generator *ngIf="caseDetail || claimDetail"
                   [damageParts]='damageParts'
                   [damageRecommendations]="damageRecommendationList"
                   [isRepair]="isRepairModule"
                   class="margin-pdf"
                   [uploadSteps]="pdfImageSteps"
                   [caseDetail]="caseDetail"
                   #elementRef
                   [isExternal]="isExternal"
                   [isClaim]="isClaimModule"
                   [isFNOL]="fnolGUID !== ''"
                   [claimDetail]="claimDetail"
                   [isQuoteAssessment]="isQuoteAssessmentModule"></chq-pdf-generator>
