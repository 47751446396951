<ng-container *ngIf="alreadySubmitted; else normalFlow">
    <div class="submit-request-missing-info">
        <div class="header">
            <div class="title-container">
                <div class="heading">
                    <span class="title">{{ 'submitted' | translate}}</span>
                </div>
            </div>
        </div>
        <div class="linear-gradient-effect"></div>
        <div class="completed-container">
            <div class="msg">
                <span>{{'thank_you_msg' | translate}}</span>
                <span>{{'already_submitted_message' | translate}}</span>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #normalFlow>
    <div class="submit-request-missing-info">
        <div class="header">
            <div class="title-container">
                <div class="heading">
                    <span class="title"
                          *ngIf="!isSubmitted">{{ 'requirement_list' | translate}}</span>
                    <span class="title"
                          *ngIf="isSubmitted">{{ 'submitted' | translate}}</span>
                </div>
            </div>

        </div>
        <div class="submit-missing-info"
             *ngIf="!isSubmitted else completed">
            <div class="sub-heading"
                 *ngIf="infoReRequested">{{'missing_details_sub_heading_re_request' | translate}}</div>
            <div class="sub-heading"
                 *ngIf="!infoReRequested">{{'missing_details_sub_heading' | translate}}</div>
            <div class="content first-content"
                 *ngIf="controls?.length > 0">
                <div class="title">
                    <span>{{'personal_details' | translate}}</span>
                </div>
                <dynamic-form [formControls]="controls"
                              class="form-container"
                              [form]="quoteCustomerFormGroup"></dynamic-form>

            </div>

            <div class="content first-content"
                 *ngIf="vehicleCtrlList?.length > 0">
                <div class="title">
                    <span>{{'vehicle_detail' | translate}}</span>
                </div>
                <dynamic-form [formControls]="vehicleCtrlList"
                              class="form-container"
                              [form]="quoteVehicleFormGroup"></dynamic-form>

            </div>

            <div *ngIf="inspectionId && imageFilters?.length>0"
                 class="w-100">
                <div class="title"
                     *ngIf="newPhoto?.imageStatusSteps?.length > 0 &&  nonVideo> 0">
                    <span>{{'vehicle_images' | translate}}</span>
                </div>
                <new-photos [currentInspectionID]="inspectionId"
                            [currentDomainId]="domainId"
                            [fnolGUID]="'noNeed'"
                            [hideEstimate]="true"
                            [photosHeader]="photosHeader"
                            [hideBulk]="true"
                            [hideVideo]="true"
                            [hideAi]="true"
                            #newPhoto
                            [displayOutline]="true"
                            (stepLoad)="stepLoad($event, 'photos')"
                            [currentObjectId]="objectId"
                            [quoteAssessmentId]="caseGuid"
                            [isReviewSection]="true"
                            [filteredSteps]="imageFilters"
                            (videoInspectionItemDetails)="videoInspectionItemDetails($event)"
                            [currentVehicle]="{}"
                            submitSection="photos"></new-photos>
            </div>
            <div class="video-container"
                 *ngIf="inspectionId && captureVideo && newPhoto?.videoInspectionItemTemplate">
                <div class="title">
                    <span>{{'video_upload' | translate}}</span>
                </div>
                <video-uploader #videoUploader
                                (fileChanged)="handleFileChange($event)"
                                [uploadStatus]="videoStatus"
                                [showInternalCamera]="true"
                                [videoUrl]="
                                videoUrl"></video-uploader>
            </div>
            <div class="w-100"
                 *ngIf="showDocument">
                <div class="title">
                    <span>{{'vehicle_documents' | translate}}</span>
                </div>
                <new-documents [currentObjectId]="objectId"
                               [documentHeader]="newDocumentHeader"
                               (documentUpload)="handleOcrTags($event);"
                               [filteredSteps]="documentFilters"
                               [isReviewSection]="true"
                               (stepLoad)="null"
                               #newDocuments
                               [currentDomainId]="domainId"
                               submitSection="document"></new-documents>
            </div>
            <div class="content btn-content">
                <xa-button [disabled]="isVideoUploading"
                           (click)="submitForm();">{{'submit' | translate}}</xa-button>
            </div>
        </div>
    </div>
    <ng-template #completed>
        <div class="linear-gradient-effect"></div>
        <div class="completed-container">
            <img alt="completed"
                 src="assets/img/completed_submitted.png" />
            <div class="msg">
                <span>{{'thank_you_msg' | translate}}</span>
                <span>{{'success_message' | translate}}</span>
            </div>
        </div>
    </ng-template>
</ng-template>