import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { firstValueFrom } from 'rxjs';
import { allowExtension, photosSizeLimit, repairEstimatedStatuses } from 'src/app/config/constants/app.constants';
import { CLAIM_STATUS, GARAGE_REGISTRATION_STATUS } from 'src/app/config/constants/claims.constants';
import { CommentDialogComponent } from 'src/app/dialogs/comment-dialog/comment-dialog.component';
import { ImgDialogComponent } from 'src/app/dialogs/img-dialog/img-dialog.component';
import { RejectClaimDialogComponent } from 'src/app/dialogs/reject-claim-dialog/reject-claim-dialog.component';
import { FooterModel, Modules, UploadStepModel } from 'src/app/model/chq-upload-model';
import { ChqWidgetsButtonModel } from 'src/app/model/chq-widgets-button-model';
import { ChqWidgetsHeaderModel } from 'src/app/model/chq-widgets-header-model';
import { customerSurveyorMenus, menuItem } from 'src/app/model/menu';
import { FraudDetectionComponent } from 'src/app/modules/claim/claim-management/fraud-detection/fraud-detection.component';
import { CalendarService } from 'src/app/services/calendar/calendar.service';
import { CaseService } from 'src/app/services/case/case.service';
import { ClaimsService } from 'src/app/services/claims/claims.service';
import { CommonService } from 'src/app/services/common/common.service';
import { InspectionService } from 'src/app/services/inspection/inspection.service';
import { MediaCollectionService } from 'src/app/services/media-collection/media-collection.service';
import { MonitorService } from 'src/app/services/monitor/monitor.service';
import { RepairEstimateService } from 'src/app/services/repair-estimate/repair-estimate.service';
import { ChqTabsComponent } from '../chq-tabs/chq-tabs/chq-tabs.component';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { saveAs } from 'file-saver';
import { ConfigurationService } from 'src/app/config/configuration/service/configuration.service';
import { QuoteManagementService } from 'src/app/services/quote-management/quote-management.service';
import { CASE_STATUS } from 'src/app/config/constants/quote.assessment.constants';
@Component({
  selector: 'chq-review-section',
  templateUrl: './chq-review-section.component.html',
  styleUrls: [ './chq-review-section.component.scss' ]
})
export class ChqReviewSectionComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() steps: UploadStepModel[];
  @Input() originalSteps: UploadStepModel[];
  @Input() footerOptions: FooterModel;
  @Input() isMultipleUpload: boolean;
  @Input() currentAdditionalModeMultiple: boolean;
  @Input() header: ChqWidgetsHeaderModel;
  @Input() isRepairStage: boolean;
  @Input() bothOptionEnabled:boolean;
  @Input() hideAI: boolean;
  @Input() isQuoteAssessmentModule: boolean;
  @Output() selection: EventEmitter<{ 'step': UploadStepModel, 'mode': string, 'type'?: string }> = new EventEmitter();
  @Output() refreshEvent: EventEmitter<void> = new EventEmitter();
  @Output() triggerPDF: EventEmitter<void> = new EventEmitter();
  @Output() handleDeleteAllEvent: EventEmitter<void> = new EventEmitter();
  @Output() backToUpload: EventEmitter<any> = new EventEmitter();
  @Output() updateAiEstimateStatus: EventEmitter<any> = new EventEmitter();
  @Input() deletedIds: any[] = [];
  @Input() maximumImages: number;
  @Input() inspectionId: number;
  @Input() displayOutline:boolean;
  userPermission: any;
  @Input() minimumPhotosRequired: number = 0;
  @Input() backTitle: string;
  @Input() activateRepairButton: boolean;
  @Input() filteredSteps: string[];
  @Input() submitSection: string;
  @Input() quoteAssessmentStatus:string;
  @Input() repairOrCaseData:any;

  hideBack = false;
  hideDelete = false;

  @Output() notify: EventEmitter<any> = new EventEmitter();
  @Output() completeRepair: EventEmitter<any> = new EventEmitter();
  @Output() damageAnalysisComplete: EventEmitter<any> = new EventEmitter();
  @Input() isDamageAnalysisComplete: boolean;

  stepsWithAdditionalStep: UploadStepModel[] = [];
  currentDate = new Date();
  public isScrolling: any;

  landscape: MediaQueryList = window.matchMedia('(orientation: landscape)');
  isLandscape: boolean = true;
  currentPageName: string = '';
  showFraudDetectionButton: boolean = false;

  @Input() showNotify: boolean;
  @Input() showComplete: boolean;
  @Input() isRepairComplete: boolean;

  public isRepairCreatedFromCase: boolean = false;
  public bookingId: string = '';

  showEditDeleteButtonsClaim: boolean = true;
  canShowApproveRejectButton: boolean = false;
  showAssignBackButton: boolean = false;

  fromRepairView: boolean = false;
  displayAIButton: boolean = false;

  claimStatus: any = CLAIM_STATUS;
  customerSurveyorMenus: menuItem[] = _.cloneDeep(customerSurveyorMenus);
  isClaimSurveyor: boolean = false;
  enableSurveyor: boolean = false;
  allAnalysisFailed = false;

  @ViewChild('tabSurveyor') tabSurveyor: ChqTabsComponent;
  showAdditionalAttachments: boolean = false;
  surveyorAttachments: any = [];
  showAdditionalSurveyorButton: boolean = false;

  public cancelButtonModel: ChqWidgetsButtonModel = {
    label: 'Cancel Booking',
    type: 'outline-danger',
    onclick: this.cancelBooking.bind(this)
  }

  public submitForReviewModel: ChqWidgetsButtonModel = {
    label: 'Submit for review',
    type: 'primary',
    onclick: this.submissionForReviewClaim.bind(this)
  }

  public onlySurveyorImages: boolean = false;
  /**
   * show delete
   */
  get showDelete(): boolean {
    if (this.footerOptions?.repairId && this.footerOptions?.currentStatus?.toLowerCase() === 'draft') {
      return true;
    }
    return false;
  }
  @Input() sendRequest: number;

  /**
   * constructor
   * @param router
   */
  constructor(private router: Router, public commonService: CommonService,
    private mediaManagementService: MediaCollectionService,
    private inspectionService: InspectionService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private monitorService: MonitorService,
    private estimateService: RepairEstimateService,
    private calendarService: CalendarService,
    private caseService: CaseService,
    private claimService: ClaimsService,
    private configurationService: ConfigurationService,
    private quoteAssessmentService: QuoteManagementService,
    private translateService: TranslateService) {
    const bookingId$ = this.commonService.bookingId.subscribe({
      next: (bookingId: string) => {
        this.bookingId = bookingId;
      }
    });
    this.commonService.userProfileData.subscribe((res) => {
      if (res?.data) {
        const { userPermission } = res.data;
        this.userPermission = userPermission;
      }
    });

    this.commonService.onlySurveyorImages.subscribe((res) => {
      if (res) {
        this.onlySurveyorImages = res;
      }
    })
    if (this.router.url.indexOf('/repair-stages') !== -1) {

    
      this.isRepairStage = true;
    }
  }


  /**
   * show approve reject buttons
   */
  showApproveRejectButtons(): boolean {
    if ([ CLAIM_STATUS.EstimateApproved,
      CLAIM_STATUS.EstimateRejected ].includes(this.footerOptions?.currentStatus)) {
      return true;
    }

    return false;
  }

  /**
   * is camera flag enabled
   */
  get isCameraEnabled(): boolean {
    return this.displayOutline && !this.isMultipleUpload && (this.currentPageName === 'photos' || this.submitSection === 'photos');
  }

  /**
   * check gtEstimate permission
   */
  checkGTEstimatePermission(): boolean {
    const { orgPackages } = this.userPermission;
    const repairEstimatePackage = orgPackages.filter((orgPackage: any) => {
      return orgPackage.automotiveServiceName === 'Repair Estimate';
    });

    if (repairEstimatePackage && repairEstimatePackage.length > 0) {
      const { permissions } = repairEstimatePackage[0];
      const gtEstimatePermission = permissions.filter((perm: any) => {
        return perm.permissionName === 'gt.estimate' || perm.permissionName === 'gt.estimate.vinquery';
      });
      if (gtEstimatePermission && gtEstimatePermission.length > 0) {
        return true;
      }
    }
    return false;
  }

  /**
   * claim permission
   * @param permissionName
   */
  checkClaimPermission(permissionName: string): boolean {

    const automotiveService = this.userPermission?.automotiveServices;
    const permissionList = automotiveService?.find(x => x.automotiveServiceName.toLowerCase() === 'addenda claims')?.permissions;

    return this.commonService.hasPermission(permissionName, permissionList);
  }

  /**
   * claim permission
   * @param permissionName
   */
  checkOrgPackagesPermission(serviceName: string, permissionName: string, userPermission?: string): boolean {

    const orgPackages = this.userPermission?.orgPackages;
    const permissionList = orgPackages?.find(x => x.automotiveServiceName.toLowerCase() === serviceName)?.permissions;

    if (this.commonService.hasPermission(permissionName, permissionList)) {
      if (this.checkClaimPermission(userPermission) || !userPermission) {
        return true;
      }
    }

    return false;
  }

  public backButtonModel: ChqWidgetsButtonModel = {
    label: 'Back to Photo Selection',
    type: 'outline'
  }

  public notifyButtonModel: ChqWidgetsButtonModel = {
    label: 'notify',
    type: 'outline',
    icon: 'send',
    onclick: () => {
      this.notify.emit()
    }
  }

  public repairEstimateModel: ChqWidgetsButtonModel = {
    label: 'Create Estimate',
    type: 'outline'
  }

  public aiGenerateEstimate: ChqWidgetsButtonModel = {
    label: 'generate_ai_estimate',
    type: 'primary',
    icon: 'save-checked',
    onclick: this.generateAiEstimate.bind(this)
  }

  public showAiGenerateEstimate: boolean = false;

  public bookButton: ChqWidgetsButtonModel = {
    label: 'Book In',
    type: 'primary'
  }

  public AIButton: ChqWidgetsButtonModel = {
    label: 'AI Analysis',
    type: 'secondary',
    icon: 'chip'
  }

  public DownloadAllButton: ChqWidgetsButtonModel = {
    label: 'download_all',
    type: 'primary',
    icon: '',
    onclick: this.downloadZip.bind(this)
  }


  public uploadButtonModel: ChqWidgetsButtonModel = {
    label: 'Upload',
    type: 'primary',
    icon: 'upload'
  }


  public nextButtonModel: ChqWidgetsButtonModel = {
    label: 'Next add photos',
    type: 'primary'
  }

  public retryButtonModel: ChqWidgetsButtonModel = {
    label: 'Refresh',
    type: 'outline',
    icon: 'refresh'
  }

  public pdfButtonModel: ChqWidgetsButtonModel = {
    label: 'Create PDF',
    type: 'outline',
    icon: 'pdf'
  }

  //claim buttons
  public rejectButtonModel: ChqWidgetsButtonModel = {
    label: 'reject',
    type: 'outline-danger',
  }

  public approveButtonModel: ChqWidgetsButtonModel = {
    label: 'approve',
    type: 'primary',
    onclick: this.approve.bind(this)
  }

  public settleClaimButtonModel: ChqWidgetsButtonModel = {
    label: 'settle_claim',
    type: 'primary',
    onclick: this.settleClaim.bind(this)
  }

  public submitForApprovalButtonModel: ChqWidgetsButtonModel = {
    label: 'Submit for Approval',
    type: 'primary',
    onclick: this.submitForApproval.bind(this)
  }

  public selfApproveButtonModel: ChqWidgetsButtonModel = {
    label: 'approve',
    type: 'primary',
    onclick: this.approve.bind(this)
  }

  public fraudDetectionButton: ChqWidgetsButtonModel = {
    label: 'Fraud Detection Report',
    type: 'outline',
    icon: 'save-checked',
    onclick: this.openFraudDetectionPopup.bind(this)
  }

  public assignBackApprovalButtonModel: ChqWidgetsButtonModel = {
    label: 'Assign Back',
    type: 'outline',
    icon: 'save-checked',
    onclick: this.assignBack.bind(this)
  }
  //

  /**
   * handle showAI button
   */
  showAI($event: { back: boolean }): void {
    this.footerOptions.handleBack($event);
  }

  /**
   * handle showAI button
   */
  triggerPDFButton(): void {
    this.triggerPDF.emit();
  }

  /**
   * get status
   */
  get validStatuses(): boolean {
    const validStatuses = [ 'draft', 'uploaddocuments', 'uploadphotos' ];
    return validStatuses.includes(this.footerOptions.currentStatus.toLowerCase());
  }

  /**
   * editing permissions
   */
  editPermissions(): boolean {
    const validStatuses = [ CLAIM_STATUS.Submitted, CLAIM_STATUS.ClaimSubmittedForApproval ];

    if (this.footerOptions.currentStatus === CLAIM_STATUS.Approved || this.footerOptions.currentStatus === CLAIM_STATUS.Rejected) {
      return false;
    }
    if (validStatuses.includes(this.footerOptions.currentStatus)) {
      // if not claim manager hide all the buttons for the above status
      if (!this.checkClaimPermission('claim.selfapproval')) {
        return false;
      }
    }

    if (this.commonService.claimAssigneeId) {

      if (this.commonService.userId === this.commonService.claimCreatedId
        && [ CLAIM_STATUS.AssignedBackFromApproval, CLAIM_STATUS.AssignedBackFromReview ].includes(this.footerOptions.currentStatus)) {
        return true;
      }
      // if assignee is not user id then he cannot edit unless he is a manager.
      if (this.commonService.userId !== this.commonService.claimAssigneeId && !this.checkClaimPermission('claim.selfapproval')) {
        return false;
      }
    }

    return true;
  }

  /**
   * handle button
   */
  handleEstimateButton(): void {
    const repairId = this.route.parent?.snapshot.paramMap.get('id');

    if (repairEstimatedStatuses.includes(this.footerOptions?.currentStatus?.toLowerCase())) {
      this.router.navigate([ `/repair/booking/${repairId}/estimate` ]);
    } else {

      if (!this.toggleEstimateButton(this.steps) || !this.toggleEstimateButton(this.originalSteps)) {
        const uploaded = this.steps?.filter((step: UploadStepModel) => {
          return step.uploadInProgress === true;
        });
        if (uploaded?.length > 0) {
          this.commonService.showToast(0, 'wait_image_upload');
        } else {
          this.commonService.showToast(0, `All ${this.minimumPhotosRequired} sides images are mandatory and cannot skip to next section.`);
        }
        this.commonService.hideLoading();
        return;
      } else if (!this.footerOptions?.showBack) {
        const uploaded = this.steps?.filter((step: UploadStepModel) => {
          return step.uploadInProgress === true;
        });
        if (uploaded?.length > 0) {
          this.commonService.showToast(0, 'wait_image_upload');
          this.commonService.hideLoading();
          return;
        }
      }

      if (this.checkGTEstimatePermission() && this.validStatuses && !this.footerOptions.caseId) {
        this.commonService.showLoading();
        this.estimateService.createEstimateFromRepair(repairId).subscribe({
          next: (resp: any) => {
            if (resp) {
              if (resp?.data?.url) {
                location.href = resp.data.url;
                sessionStorage.setItem('update', '1');
              }
            }
            this.commonService.hideLoading();
          },
          error: () => {
            this.commonService.hideLoading();
          }
        });
      } else {
        this.router.navigate([ `/repair/booking/${repairId}/estimate` ]);
      }
    }
  }

  /**
   *
   * @param url
   */
  download(document): void {
    let documentUrl = '';
    let documentName = document.procedureSteps === 'Bulk Upload Image' ? document.procedureSteps : (document.stepName.indexOf('Additional')=== -1 && document.parentName ? (document.parentName):document.stepName);
    if (document.image) {
      documentUrl = document.image;
    } else if (document.url) {
      documentUrl = document.url;
    }
    const bookingId = this.bookingId ?? `${document.domainId}${document.objectId}`; 
    console.log(document, 'document', bookingId, this.steps, this.header, this.originalSteps );
    documentName += `_${bookingId}${this.commonService.getFileExtension(document.contentType)}`;

    if (documentUrl) {
      this.commonService.download(documentUrl, documentName);
    }
  }


  /**
   * edit uploaded image
   * @param uploadStepModel
   */
  edit(uploadStepModel: UploadStepModel): void {
    if (uploadStepModel.analysisStatus) {
      if (uploadStepModel.analysisStatus?.toLowerCase() === 'analysed') {
        this.selection.emit({ 'step': uploadStepModel, 'mode': 'edit' });
      }
    } else {
      this.selection.emit({ 'step': uploadStepModel, 'mode': 'edit' });
    }
  }

  /**
   * get unanalysed images
   */
  get unAnalysedFiles(): boolean {
    return this.steps.filter((currentStep: UploadStepModel) => {
      return (currentStep.analysisStatus === 'Analysis in Progress');
    }).length > 0;
  }

  /**
   * get unanalysed images
   */
  get analysedFiles(): boolean {
    return this.steps.filter((currentStep: UploadStepModel) => {
      return (currentStep.analysisStatus === 'Analysed');
    }).length > 0;
  }

  /**
 * get all not analysed images
   */
  get notAnalysedFiles(): boolean {
    if(this.steps.length > 0) {
      this.allAnalysisFailed = this.steps.every(el=> el.analysisStatus?.toLowerCase() === 'analysis failed')
      if(this.allAnalysisFailed) {
        this.aiGenerateEstimate.label = 'create_estimate';
        this.aiGenerateEstimate.icon = '';
      } else {
        this.aiGenerateEstimate.label = 'generate_ai_estimate';
        this.aiGenerateEstimate.icon = 'save-checked';
      }
    }
    return this.steps.length === 0 || this.steps.filter((currentStep: UploadStepModel) => {
      return (currentStep.analysisStatus?.toLowerCase() === 'analysis in progress');
    }).length > 0;
  }

  /**
   * set loading
   */
  setImageLoading(name: string): void {
    if (this.imageLoading[name]) {
      return;
    }
    this.imageLoading[name] = true;
  }

  /**
   * show AI
   */
  get showAIButton(): boolean {
    if (this.footerOptions?.showBack) {
      return false;
    }
    return true;
  }

  /**
   * navigates to next page
   */
  navigateTo(): void {
    this.commonService.showLoading();
    if (this.currentPageName === 'photos') {
      if (!this.toggleEstimateButton(this.steps) || !this.toggleEstimateButton(this.originalSteps)) {
        const uploaded = this.steps.filter((step: UploadStepModel) => {
          return step.uploadInProgress === true;
        });
        if (uploaded.length > 0) {
          this.commonService.showToast(0, 'wait_image_upload');
        } else {
          this.commonService.showToast(0, `All ${this.minimumPhotosRequired} sides images are mandatory and cannot skip to next section.`);
        }
        this.commonService.hideLoading();
        return;
      } else if (!this.footerOptions?.showBack) {
        const uploaded = this.steps.filter((step: UploadStepModel) => {
          return step.uploadInProgress === true;
        });
        if (uploaded.length > 0) {
          this.commonService.showToast(0, 'wait_image_upload');
          this.commonService.hideLoading();
          return;
        }
      }
    } else {
      const uploaded = this.steps.filter((step: UploadStepModel) => {
        return step.uploadInProgress === true;
      });
      if (uploaded.length > 0) {
        this.commonService.showToast(0, 'wait_document_upload');
        this.commonService.hideLoading();
        return;
      }
    }
    if (this.footerOptions.status && !this.footerOptions.isClaimModule) {
      this.caseService.updateCaseStatus(this.footerOptions.caseId, this.footerOptions.status).subscribe({
        next: () => {
          if (this.footerOptions.finalRedirection) {
            this.footerOptions.finalRedirection();
          } else {
            this.router.navigate([ this.footerOptions.route ]);
          }
        },
        complete: () => {
          this.commonService.hideLoading();
        }
      })
    } else {
      this.commonService.hideLoading();
      if (this.footerOptions.finalRedirection) {
        this.footerOptions.finalRedirection();
      } else {
        this.router.navigate([ this.footerOptions.route ]);
      }
    }
  }


  /**
   * submission for review for claim
   */
  submissionForReviewClaim(): void {
    this.damageAnalysisComplete.emit({ 'uploading': this.uploading, 'status': CLAIM_STATUS.Submitted });
  }

  /**
   * handle delete all
   */
  handleDeleteAll(): void {
    const files = this.steps.filter((step: UploadStepModel) => {
      return step.url;
    })

    const uploaded = this.steps.filter((step: UploadStepModel) => {
      return step.uploadInProgress === true;
    });
    if (uploaded.length > 0) {
      this.commonService.showToast(0, 'wait_image_upload');
      return;
    } else if (files.length === 0) {
      this.commonService.showToast(0, 'No images present.');
      return;
    }

    this.commonService.openDeleteDialog('Photos', 'Are you sure you want to delete all these images?')
      .afterClosed().subscribe((data) => {
        if (data) {
          this.commonService.showLoading();
          this.inspectionService.deleteAllImages(this.inspectionId).subscribe({
            next: () => {
              this.handleDeleteAllEvent.emit();
              this.commonService.hideLoading();
            },
            error: () => {
              this.commonService.hideLoading();
            }
          });
        }
      });
  }

  public imageLoading: any = {};

  /**
   * delete uploaded image
   */
  deleteUpload(uploadStepModel: UploadStepModel): void {
    if ((this.footerOptions?.repairId && this.footerOptions?.from == Modules.quote && this.commonService.repairPermission)
      || (this.footerOptions?.caseId && this.footerOptions?.from == Modules.repair && this.commonService.quotePermission && this.footerOptions.isRepairCreatedFromCase)) {
      this.commonService.showChangeWarningMessage(this.showDeleteConfirmation.bind(this, uploadStepModel),
        this.footerOptions.from == 'Quote' ? 'Repair' : 'Quote');
    } else {
      this.showDeleteConfirmation(uploadStepModel);
    }
  }

  /**
   * show delete confirmation
   */
  showDeleteConfirmation(uploadStepModel: UploadStepModel): void {
    const message = (this.currentPageName == 'photos' && this.footerOptions.showBack === true) ?
      'By deleting this photo it will delete the photo from the AI analysis report only' : '';
    this.commonService.openDeleteDialog((this.currentPageName == 'photos') ? 'photo' : this.currentPageName, message)
      .afterClosed().subscribe((data) => {
        if (data) {
          this.deleteImage(uploadStepModel);
        }
      });
  }

  /**
   * insert/update comment
   */
  showCommentDialog(uploadStepModel: UploadStepModel, module?: string): void {
    let note = '';
    let hideUpdate = false;

    if (module == 'claim' || this.fromRepairView) {
      hideUpdate = true;
    }

    if (uploadStepModel.inspectionItem?.inspectionNote?.length > 0) {
      note = uploadStepModel.inspectionItem?.inspectionNote[0].text;
    }

    this.monitorService.logEvent('changePhoneNumber', [ 'CommentDialogComponent', 'addenda-repair' ]);
    this.dialog.open(CommentDialogComponent, {
      data: {
        notes: note,
        hideUpdate: hideUpdate
      },
      panelClass: 'comment-modalbox',

      autoFocus: false
    })
      .afterClosed().subscribe({
        next: (data: any) => {
          if (!data) {
            return;
          }
          if (data.delete) {
            this.commonService.showLoading();
            this.inspectionService.deleteComment(uploadStepModel.inspectionItem?.id).subscribe({
              next: () => {
                uploadStepModel.inspectionItem.inspectionNote = [];
                this.commonService.hideLoading();
                this.commonService.showInfoToast(5000, 'Comment has been deleted successfully.');
              },
              error: () => {
                this.commonService.hideLoading();
              }
            });
            return;
          }
          const body = {
            'inspectionId': this.inspectionId,
            'inspectionItemId': uploadStepModel.inspectionItem.id,
            'notes': data.comment
          }
          this.commonService.showLoading();
          this.inspectionService.updateComment(body).subscribe({
            next: () => {
              if (!uploadStepModel.inspectionItem.inspectionNote) {
                uploadStepModel.inspectionItem.inspectionNote = [];
              }
              const inspectionNote = uploadStepModel.inspectionItem.inspectionNote[0];
              if (!inspectionNote) {
                uploadStepModel.inspectionItem.inspectionNote.push({ 'text': data.comment });
              } else {
                uploadStepModel.inspectionItem.inspectionNote[0].text = data.comment;
              }
              this.commonService.hideLoading();
              this.commonService.showInfoToast(5000, 'Comment has been saved successfully.');
            },
            error: () => {
              this.commonService.hideLoading();
            }
          });
        }
      });
  }

  /**
   * should additional be displayed
   */
  checkInAdditional(): void {
    const currentPhotos = this.steps?.filter((currentStep: UploadStepModel) => {
      return (currentStep.isAdditionalDocumentFromReviewPageOnly != true || currentStep.image || currentStep.url);
    });

    const uploadedPhotos = this.steps?.filter((currentStep: UploadStepModel) => {
      return (currentStep.image || currentStep.url);
    });

    if (uploadedPhotos?.length === 0) {
      this.hideDelete = true;
    }

    if (this.footerOptions) {
      if (this.currentPageName === 'photos') {
        this.header = { ...this.header, showTitle: true };
      }
      if (uploadedPhotos?.length === 0 && this.currentPageName == 'photos') {
        this.footerOptions.showUploadOption = true;
      } else {
        this.footerOptions.showUploadOption = false;
        if (this.currentPageName !== 'photos') {
          this.header = { ...this.header, showTitle: false };
        }
      }
    }

    if (this.currentPageName === 'document') {
      this.commonService.mediaTemplates = currentPhotos;
    }
    if (this.isClaimSurveyor) {
      this.stepsWithAdditionalStep = currentPhotos;
      return;
    }

    if (currentPhotos?.length != this.steps?.length || ((this.isMultipleUpload || this.currentAdditionalModeMultiple)
      && currentPhotos?.length < (this.maximumImages || 20))) {
      if (!this.currentPageName) {
        this.currentPageName = this.router.url.indexOf('photos') !== -1 ? 'photos' : 'documents'
      }
      this.stepsWithAdditionalStep = currentPhotos.concat([ {
        stepName: this.currentPageName == 'photos' ? 'Additional Image' : 'Additional Documents',
        uploadStatus: 'notUploaded',
        url: '',
        image: '',
        index: -1,
        isAdditionalDocumentFromReviewPageOnly: true,
        sizeLimit: this.steps[0]?.sizeLimit || photosSizeLimit,
        allowExtension: this.steps[0]?.allowExtension || allowExtension,
        mandatory: false,
        isMassUpload: this.isMultipleUpload,
        isUploadSuccess: false,
        uploadInProgress: false,
        isUploadFailed: false,
        isSkipped: false,
        isSkipEnabled: true,
        templateGuid: this.steps[0]?.templateGuid || '',
        partTemplateGuid: this.steps[0]?.partTemplateGuid || '',
        placeHolderUrl: '/assets/icons/additional-image.svg'
      } ]);
      if (uploadedPhotos?.length !== 0) {
        this.hideBack = this.isRepairStage ? true : false;
      }
    } else {
      this.stepsWithAdditionalStep = currentPhotos;
      this.hideBack = true;
    }
  }

  /**
   * get inprogress
   */
  get uploading(): boolean {
    if (this.footerOptions?.showBack) {
      return false;
    }

    const inprogressPhotos = this.steps?.filter((currentStep: UploadStepModel) => {
      return currentStep.uploadInProgress === true;
    });

    return inprogressPhotos?.length > 0;
  }

  /**
   * get mising
   */
  get missing(): boolean {
    return location.href.includes('submit-missing-info');
  }

  /**
   * get review documents
   */
  get photos(): UploadStepModel[] {

    const currentPhotos = this.steps?.filter((currentStep: UploadStepModel) => {
      return (currentStep.isAdditionalDocumentFromReviewPageOnly != true || currentStep.image || currentStep.url);
    });
    if (!this.footerOptions?.showBack) {
      const inprogressPhotos = this.steps?.filter((currentStep: UploadStepModel) => {
        return currentStep.uploadInProgress === true;
      });
      this.commonService.inProgressSteps.next(inprogressPhotos?.length);
    }

    if (this.filteredSteps && this.filteredSteps?.length > 0) {
      return this.steps.filter((currentStep: UploadStepModel) => {
        return this.filteredSteps.includes(currentStep.stepName) || this.filteredSteps.includes(currentStep.parentName);
      });
    }

    if (currentPhotos?.length != this.steps?.length || ((this.isMultipleUpload ||
      this.currentAdditionalModeMultiple) && !this.footerOptions?.showBack && currentPhotos?.length < (this.maximumImages || 20))) {
      return this.stepsWithAdditionalStep;
    }

    return currentPhotos;

  }

  /**
   * open image dialog
   */
  imageDialog(state: string, url: string | ArrayBuffer, name: string): void {
    if (this.footerOptions?.showBack) {
      return;
    }
    const dialogRef = this.dialog.open(ImgDialogComponent, {
      data: {
        state,
        title: name,
        image: url
      }, autoFocus: false
    });
    dialogRef.afterClosed().subscribe();
  }

  /**
   *  uploaded image
   */
  upload(uploadStepModel: UploadStepModel, type?: string): void {
    this.selection.emit({ 'step': uploadStepModel, 'mode': 'upload', 'type': type || '' });
  }

  /**
   *  delete image
   */
  deleteImage(uploadStepModel: UploadStepModel, callback?:()=>void): void {
    this.commonService.showLoading();
    if(this.submitSection) this.currentPageName = this.submitSection;
    if (this.currentPageName === 'document') {
      const { uploadedGuid, uploadedPartGuid, objectId, domainId } = uploadStepModel;

      this.mediaManagementService.deleteMediaTemplate(uploadedGuid, uploadedPartGuid, objectId, domainId).subscribe((resp: any) => {
        this.selection.emit({ 'step': uploadStepModel, 'mode': 'delete' });
        this.steps = [ ...this.steps ];

        if (this.footerOptions?.isClaimModule && !this.commonService.claimCreatedFromSurveyor) {
          //this.claimService.updateFraudDetectionDocument(this.footerOptions.claimId).subscribe();
          this.commonService.documentFraudStatus.next('Outdated');
        }

        if(callback){
          callback();
        }

        this.commonService.hideLoading();
      }, () => {
        this.commonService.hideLoading();
      })
    } else {
      if (uploadStepModel.inspectionItem.id) {
        this.inspectionService.deleteInspection(uploadStepModel.inspectionItem.id).subscribe((resp: any) => {
          this.selection.emit({ 'step': uploadStepModel, 'mode': 'delete' });
          if (uploadStepModel.procedureSteps === 'Bulk Upload Image') {
            this.steps = [ ...this.steps.filter((st: UploadStepModel) => st.inspectionItem?.id !== uploadStepModel.inspectionItem?.id) ];
          } else {
            const stepItem = this.steps?.find(el => el.inspectionItem?.id === uploadStepModel?.inspectionItem?.id);
            if (stepItem) {
              stepItem.image = stepItem.placeHolderUrl;
            }
          }

          if(callback){
            callback();
          }

          if (this.footerOptions?.isClaimModule && !this.commonService.claimCreatedFromSurveyor) {
            this.claimService.updateFraudDetection(this.footerOptions.claimId).subscribe();
          } else if (this.header?.isQuoteAssessmentModule) {
            this.quoteAssessmentService.updateFraudDetection(this.footerOptions.quoteAssessmentId)?.subscribe();
          }
          
          //Show generate estimate button
          if (this.footerOptions?.aiEstimationStatus) this.footerOptions.aiEstimationStatus = null;
          this.updateAiEstimateStatus?.emit({ status: null })
          this.commonService.hideLoading();
        }, () => {
          this.commonService.hideLoading();
        });
      } else {
        this.selection.emit({ 'step': uploadStepModel, 'mode': 'delete' });
        this.commonService.hideLoading();
      }

    }
  }

  /**
   * display analysis
   */
  displayAnalysis(): void {
    this.showAI({ back: false });
  }


  /**
   * display assign back button
   */
  displayAssignBackButton(): boolean {
    const condition1 = ((this.commonService.fnolId === '' || this.commonService.fnolId === '0')
      && this.footerOptions.currentStatus === 'ReviewInProgress' && this.currentPageName === 'photos'
      && this.commonService.claimWorkflowStage?.includes('3Stage') && this.checkClaimPermission('claim.submitforapproval'))

    const condition2 = !this.commonService.claimWorkflowStage?.includes('1Stage') &&
      (this.footerOptions.currentStatus === 'ApprovalReviewInProgress'
        && this.currentPageName === 'photos' && this.checkClaimPermission('claim.selfapproval'))

    return condition1 || condition2;

  }

  /**
   * display a flag indicating show/hide of  submit for approval button
   */
  displaySubmitForApprovalButton(): boolean {

    //claim filed as claim maker and submitted for review 3 stage; the user should be claim reviewer
    const fromClaimMaker = this.checkClaimPermission('claim.submitforapproval')
      && [ CLAIM_STATUS.ReviewInProgress, CLAIM_STATUS.AssignedBackFromApproval ].includes(this.footerOptions.currentStatus)
      && this.currentPageName === 'photos';

    // claim filed as claim reviewer in stage 2 and user should be claim reviewer
    const fromClaimReviewerStage2 = [ CLAIM_STATUS.UploadPhotos, CLAIM_STATUS.DetailsUpdated, CLAIM_STATUS.UploadDocuments, CLAIM_STATUS.AssignedBackFromApproval ].includes(this.footerOptions.currentStatus)
      && this.currentPageName === 'photos'
      && this.commonService.claimWorkflowStage?.includes('2Stage')
      && this.checkClaimPermission('claim.submitforapproval')

    //claim filed as claim reviewer from stage 3, the created user id must be equal to the current user id
    //and the permission should be submit for approval
    const fromClaimReviewerStage3 = (this.commonService.claimCreatedId === this.commonService.userId)
      && this.checkClaimPermission('claim.submitforapproval') && this.commonService.claimWorkflowStage?.includes('3Stage')
      && [ CLAIM_STATUS.UploadPhotos, CLAIM_STATUS.DetailsUpdated, CLAIM_STATUS.UploadDocuments, CLAIM_STATUS.AssignedBackFromApproval ].includes(this.footerOptions.currentStatus)
      && this.currentPageName === 'photos'

    return fromClaimMaker || fromClaimReviewerStage2 || fromClaimReviewerStage3;
  }

  /**
   * display submit for review
   */
  displaySubmitForReview(): boolean {

    const validStatuses = [
      CLAIM_STATUS.UploadDocuments,
      CLAIM_STATUS.DetailsUpdated,
      CLAIM_STATUS.UploadPhotos,
      CLAIM_STATUS.AssignedBackFromReview
    ];

    //
    const fromClaimMaker = this.checkClaimPermission('claim.submitted')
      && (validStatuses.includes(this.footerOptions.currentStatus))
      && this.currentPageName === 'photos'

    return fromClaimMaker;
  }

  /**
   * goBack to selection
   */
  goBackToSelection(): void {
    this.backToUpload.emit();
  }

  /**
   * ngOnInit hook
   */
  async ngOnInit(): Promise<void> {

    if (!this.commonService.pageName.closed) {
      this.commonService.pageName.subscribe((name) => {
        if (name) {
          this.currentPageName = name;
        }
      });
    }

    this.commonService.inProgressSteps.next(0);

    this.AIButton = { ...this.AIButton, onclick: this.displayAnalysis.bind(this) };

    this.repairEstimateModel = { ...this.repairEstimateModel, onclick: this.handleEstimateButton.bind(this) };

    this.backButtonModel = { ...this.backButtonModel, onclick: this.goBackToSelection.bind(this) };

    if (this.router.url.indexOf('/repairer-view/') !== -1) {
      this.fromRepairView = true;
    }

    if (this.currentPageName == 'photos') {
      this.landscape.addEventListener('change', () => {
        this.setOrientation();
      });

      this.setOrientation();
    }

    this.steps?.forEach((val: UploadStepModel, index: number) => {
      this.imageLoading[val.index + val.stepName] = false;
    });

    if(this.displayOutline){
      this.uploadButtonModel = { ...this.uploadButtonModel, label: 'capture_image' };
    }

    this.checkInAdditional();
    this.nextButtonModel = {
      label: this.footerOptions?.text,
      icon: this.footerOptions?.icon,
      type: 'primary',
      onclick: this.navigateTo.bind(this)
    }

    if (this.footerOptions?.isClaimModule) {

      if (this.currentPageName != 'photos') {
        this.footerOptions.hideNext = false;
      } else if (this.checkClaimPermission('claim.selfapproval') || this.checkClaimPermission('claim.submitforapproval')) {
        // if manager and reviewer show go to garage button
        this.footerOptions.hideNext = false;
      } else {
        // for other users don't show garages
        this.footerOptions.hideNext = true;
      }

      this.showEditDeleteButtonsClaim = this.editPermissions();
      this.canShowApproveRejectButton = this.showApproveRejectButtons();
      this.showAssignBackButton = this.displayAssignBackButton();
      this.displayAIButton = this.checkOrgPackagesPermission('artificial intelligence', 'damage.analysis');
      if (this.commonService.fraudStatus
        && !this.commonService.claimCreatedFromSurveyor
        && !this.onlySurveyorImages
        && this.checkOrgPackagesPermission('artificial intelligence', 'fraud.detection', 'frauddetection.view')) {
        this.showFraudDetectionButton = true;
      }
    }

    this.retryButtonModel.onclick = this.refresh.bind(this)
    this.pdfButtonModel.onclick = this.triggerPDFButton.bind(this)

    if (repairEstimatedStatuses.includes(this.footerOptions?.currentStatus.toLowerCase())) {
      this.repairEstimateModel.label = 'View Estimate';
    }
    if (this.checkOrgPackagesPermission('artificial intelligence estimation', 'ai.estimation')) {
      //TODO : might need to add permission check
      this.showAiGenerateEstimate = true;
    }
  }


  /**
   * ngAfterViewInit hook
   */
  async ngAfterViewInit(): Promise<void> {
    if (this.footerOptions?.isClaimModule) {
      if (this.currentPageName == 'photos' && this.footerOptions.hasSurveyorOrgPackagePermission) {
        if (this.commonService.claimCreatedFromSurveyor || this.onlySurveyorImages) {

          this.customerSurveyorMenus[0] = {
            ...this.customerSurveyorMenus[0], disabled: true, active: false
          }

          this.enableSurveyor = true;

          this.customerSurveyorMenus[1] = {
            ...this.customerSurveyorMenus[1], disabled: false, active: true
          }
        } else {
          await this.hasSurveyorImages();
        }
      }

      if (!this.footerOptions.hasSurveyorOrgPackagePermission) {
        //Enable button so that on click we can show upgrade up
        this.customerSurveyorMenus[1].disabled = false;
      }

      this.showAdditionalSurveyorButton = (this.commonService.surveyorAttachments?.length > 0)
        && this.footerOptions.hasSurveyorOrgPackagePermission
        && (this.isClaimSurveyor || this.commonService.claimCreatedFromSurveyor || this.onlySurveyorImages)
    }
  }

  /**
   * ngOnChanges
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    this.setOrientation();
    if (changes && changes['steps']) {
      this.checkInAdditional();
      this.originalSteps = this.steps;
      this.steps.forEach((val: UploadStepModel, index: number) => {
        if (this.footerOptions?.showBack && this.imageLoading[val.index + val.stepName] === undefined) {
          this.imageLoading[val.index + val.stepName] = false;
        } else if (!this.imageLoading[val.index + val.stepName]) {
          this.imageLoading[val.index + val.stepName] = true;
        }
      });

      const imageUploadSuccess = this.steps?.find(x => x.isUploadSuccess || x.url);
      if (imageUploadSuccess) {
        this.AIButton = { ...this.AIButton, type: 'secondary' };
        this.DownloadAllButton = { ...this.DownloadAllButton, type: 'primary' };
      } else {
        this.AIButton = { ...this.AIButton, type: 'disabled' };
        this.DownloadAllButton = { ...this.DownloadAllButton, type: 'disabled' };
      }
    }

    if(changes && changes['repairOrCaseData']) {
      if(this.repairOrCaseData && this.repairOrCaseData?.estimateSource === 'GTMotive') {
        this.aiGenerateEstimate.type = 'disabled'
      }
    }

    if (this.footerOptions.isClaimModule) {
      if ((this.footerOptions?.currentStatus?.toLowerCase() === 'uploadphotos') && this.currentPageName == 'photos') {
        this.nextButtonModel = {
          ...this.nextButtonModel, type: 'primary'
        }
      }
    }

    if (repairEstimatedStatuses.includes(this.footerOptions.currentStatus.toLowerCase())) {
      this.repairEstimateModel.label = 'View Estimate';
    }
  }

  /**
   * trigger refresh
   */
  refresh(): void {
    this.refreshEvent.emit();
  }

  /**
   * is case pending
   */
  get currentStatus(): boolean {
    if(this.quoteAssessmentStatus && this.quoteAssessmentStatus?.toLowerCase() === 'completed') {
      return false;
    }
    if (!this.footerOptions?.currentStatus) {
      return true;
    }
    return [ 'closed', 'complete', CLAIM_STATUS.Complete.toLowerCase(), CASE_STATUS.Completed.toLocaleLowerCase() ].indexOf(this.footerOptions?.currentStatus?.toLowerCase()) === -1;
  }

  /**
   * set the orientation
   */
  private setOrientation(): void {
    if (this.landscape.matches) {
      //set style landscape
      this.isLandscape = true;
    } else {
      //set style portrait
      this.isLandscape = false;
    }
  }

  /**
   * has surveyor images
   */
  async hasSurveyorImages(): Promise<void> {
    this.commonService.showLoading();
    firstValueFrom(this.claimService.getSurveyPdf(this.footerOptions.claimId, this.inspectionId?.toString(), this.commonService.claimId.toString())).then((response: any) => {
      if (response?.length > 1) {
        if (response[2]) {
          const damageDetailsSurveyor = response[2].damageDetails || [];

          if (damageDetailsSurveyor.length > 0) {
            this.enableSurveyor = true;
            this.customerSurveyorMenus[1].disabled = false;
          }
        }
      }
      this.commonService.hideLoading();
    }).catch(() => {
      this.commonService.hideLoading();
    });
  }


  /**
   * toggle estimate button
   * @param steps
   */
  toggleEstimateButton(steps): boolean {
    const currentPhotos = this.steps.filter((currentStep: UploadStepModel) => {
      return currentStep.url;
    });

    if (this.footerOptions?.showBack) {
      if ((steps?.length + this.deletedIds?.length) < this.minimumPhotosRequired && this.isMultipleUpload) {
        return false;
      } else {
        return true;
      }
    } else if (this.currentPageName === 'photos' && steps && steps.length > 0) {
      if (currentPhotos.length < this.minimumPhotosRequired) {
        return false;
      } else {
        return true;
      }
    } else if (steps && steps.length === 0 && this.minimumPhotosRequired > 0) {
      return false;
    }
    return true;
  }

  /**
   * Cancel booking
   */
  cancelBooking(): void {
    this.commonService.openDeleteDialog()
      .afterClosed().subscribe((data) => {
        if (data) {
          this.commonService.showLoading();
          this.calendarService.deleteRepair(this.footerOptions.repairId).subscribe({
            next: () => {
              this.commonService.hideLoading();
              this.router.navigate([ '/repair/booking-journal' ]);
            }
          });
        }
      });
  }

  //claim section

  /**
    Opens a dialog box for the reject claim operation component with the specified data and panel class.
    @returns {void}
  */
  openRejectClaimPopup(): void {
    const dialogRef = this.dialog.open(RejectClaimDialogComponent, {
      data: {
        'claimGuid': this.footerOptions.claimId
      },
      height: 'auto',
      panelClass: 'operation-panel',
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe({
      next: (response: any) => {
        if (response) {
          if (this.footerOptions?.assignedGarageType === GARAGE_REGISTRATION_STATUS.internal) {
            this.claimService.lockEstimation(this.commonService.claimId, CLAIM_STATUS.Rejected).subscribe();
          }
          this.footerOptions.currentStatus = CLAIM_STATUS.Rejected;
          this.canShowApproveRejectButton = this.showApproveRejectButtons();
        }
      }
    });
  }

  /**
     * Approve claim
     */
  approve(): void {
    const dialogRef = this.commonService.openConfirmYesNoDialogBig(this.translateService.instant('confirm_action'), this.translateService.instant('msg_approve_claim'));
    dialogRef.afterClosed().subscribe((action: boolean) => {
      if (action) {
        this.commonService.showLoading();
        this.claimService.updateClaimStatus(this.footerOptions.claimId, 'Approved', 'Claim Approved').subscribe({
          next: (response) => {
            if (response) {
              if (this.footerOptions?.assignedGarageType === GARAGE_REGISTRATION_STATUS.internal) {
                this.claimService.lockEstimation(this.commonService.claimId, CLAIM_STATUS.Approved).subscribe();
              }
              this.footerOptions.currentStatus = CLAIM_STATUS.Approved;
              this.canShowApproveRejectButton = this.showApproveRejectButtons();
            }
            this.commonService.hideLoading();
          },
          error: () => {
            this.commonService.hideLoading();
          }
        });
      }
    });
  }

  /**
     * settleClaim
     */
  settleClaim(): void {
    const dialogRef = this.commonService.openConfirmYesNoDialogBig(this.translateService.instant('confirm_action'), this.translateService.instant('msg_settle_claim'));
    dialogRef.afterClosed().subscribe((action: boolean) => {
      if (action) {
        this.commonService.showLoading();
        this.claimService.updateClaimStatus(this.footerOptions.claimId, 'Completed', 'Settled').subscribe({
          next: (response) => {
            if (response) {
              this.footerOptions.currentStatus = CLAIM_STATUS.Complete;
            }
            this.commonService.hideLoading();
          },
          error: () => {
            this.commonService.hideLoading();
          }
        });
      }
    });
  }

  /**
     * submitForApproval
     */
  submitForApproval(): void {
    this.damageAnalysisComplete.emit({ 'uploading': this.uploading, 'status': CLAIM_STATUS.ClaimSubmittedForApproval });
  }

  /**
   * open fraud detection popup
   */
  openFraudDetectionPopup(): void {
    this.dialog.open(FraudDetectionComponent, {
      width: '100%',
      height: '100%',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fraud-detection-panel',
      disableClose: true,
      data: {
        claimId: this.footerOptions?.claimId,
        inspectionId: this.inspectionId
      },
      autoFocus: false
    });
  }

  /**
   * assign back
   */
  assignBack(): void {
    const dialogRef = this.commonService.openConfirmYesNoDialogBig(this.translateService.instant('assign_back'), this.translateService.instant('msg_assign_back'));
    dialogRef.afterClosed().subscribe((action: boolean) => {
      if (action) {
        this.commonService.showLoading();
        this.claimService.updateClaimStatus(this.footerOptions.claimId, CLAIM_STATUS.AssignedBack).subscribe({
          next: (response) => {
            if (response) {
              this.footerOptions.currentStatus = CLAIM_STATUS.AssignedBack;
              this.showAssignBackButton = this.displayAssignBackButton();
            }
            this.commonService.hideLoading();
          },
          error: () => {
            this.commonService.hideLoading();
          }
        });
      }
    });
  }

  /**
   * edit image for claim
   * @param uploadStepModel
   */
  editImageClaim(uploadStepModel: UploadStepModel, mode: string): void {

    // Bug 25826: Claim Manager is able to edit Claims created from FNOL/File a Claim without assigning
    if ((this.checkClaimPermission('claim.selfapproval')
      && (this.commonService.claimWorkflowStage.includes('2Stage') || this.commonService.claimWorkflowStage.includes('3Stage'))
      && ([ CLAIM_STATUS.ClaimAuthenticated ].includes(this.footerOptions.currentStatus)))
    ) {
      const dialog = this.commonService.openConfirmYesNoDialogBig('', 'msg_manager_review');

      dialog.afterClosed().subscribe((data) => {
        if (data) {
          this.claimService.updateClaimStatus(this.footerOptions.claimId, CLAIM_STATUS.ApprovalReviewInProgress).subscribe({
            next: (response) => {
              if (response) {
                this.commonService.showInfoToast(3000, `Status set to ${CLAIM_STATUS.ApprovalReviewInProgress}`);
                this.footerOptions.currentStatus = CLAIM_STATUS.ApprovalReviewInProgress;

                if (mode === 'edit') {
                  this.edit(uploadStepModel);
                } else if (mode === 'delete') {
                  this.deleteUpload(uploadStepModel);
                } else if (mode === 'upload') {
                  this.upload(uploadStepModel);
                }

              } else {
                this.router.navigate([ '/claim/my-claim' ]);
              }
            }
          });
        } else {
          this.router.navigate([ '/claim/my-claim' ]);
        }
      }
      );
    } else if (mode === 'edit') {
      this.edit(uploadStepModel);
    } else if (mode === 'delete') {
      this.deleteUpload(uploadStepModel);
    } else if (mode === 'upload') {
      this.upload(uploadStepModel);
    }
  }
  //

  /**
   * switch image type
   * @param $event
   */
  switchImageType($event: any): void {
    if ($event.newTabTitle == $event.prevTabTitle || !$event.prevTabTitle) {
      return;
    }

    if ($event.newTabTitle?.toLowerCase().indexOf('surveyor') != -1 && !this.footerOptions?.hasSurveyorOrgPackagePermission) {
      this.commonService.openConfirmYesNoDialogBig('information', 'upgrade_subscription', '', 'OK', '', false);
      return;
    }

    this.isClaimSurveyor = ($event.newTabTitle?.toLowerCase().indexOf('surveyor') != -1) ? true : false;
    this.header = { ...this.header, isClaimSurveyor: this.isClaimSurveyor };

    if (!this.isClaimSurveyor) {
      this.showAdditionalAttachments = false;
      this.showAdditionalSurveyorButton = false;
    } else {
      this.showAdditionalSurveyorButton = true;
    }

    this.hideBack = true;
    this.commonService.customerSurveyorInspection.next($event);
  }

  /**
   * show additional surveyor images
   */
  showAdditionalSurveyorImages(): void {
    if (this.surveyorAttachments?.length == 0) {
      this.commonService.surveyorAttachments?.forEach((element: string) => {
        if (element) {
          const fileName = element.substring(0, element.indexOf('?'));
          const extension = fileName.split('.').pop()?.toLowerCase();
          this.surveyorAttachments.push({
            'url': element,
            'ext': extension
          })
        }

      });

    }
    this.showAdditionalAttachments = true;
  }

  /**
   * go back to review
   */
  backToReview(): void {
    this.showAdditionalAttachments = false;

    this.customerSurveyorMenus[0] = {
      ...this.customerSurveyorMenus[0], active: false
    }

    this.enableSurveyor = true;

    this.customerSurveyorMenus[1] = {
      ...this.customerSurveyorMenus[1], active: true
    }
  }


  /**
   * generateAiEstimate
   */
  generateAiEstimate(): void {
    if(this.allAnalysisFailed) {
      const repairId = this.route.parent?.snapshot.paramMap.get('id');
      this.router.navigate([ `/repair/booking/${repairId}/estimate` ]);
      return;
    }
    if (repairEstimatedStatuses.includes(this.footerOptions?.currentStatus.toLowerCase())) {
      this.commonService.openConfirmYesNoDialog('confirm_to_continue', 'warning_message_regenerate_ai_estimate')
        .afterClosed().subscribe((action: boolean) => {
          if (action) {
            this.callAPIToGenerateAiEstimate();
          }
        });
    } else {
      this.callAPIToGenerateAiEstimate();
    }
  }

  /**
   * callAPIToGenerateAiEstimate
   */
  callAPIToGenerateAiEstimate(): void {
    this.monitorService.logEvent('generateAiEstimate', [ 'ChqReviewSectionComponent', 'addenda-repair' ]);
    this.commonService.showLoading();
    firstValueFrom(this.estimateService.generateAiEstimate(this.inspectionId, this.footerOptions?.objectId, 1)).then((response) => {
      this.commonService.hideLoading();
      const repairId = this.route.parent?.snapshot.paramMap.get('id');
      this.router.navigate([ `/repair/booking/${repairId}/estimate` ]);
    }).catch((err) => {
      this.commonService.hideLoading();
      this.monitorService.logException(err, SeverityLevel.Error);
    });
  }

  /**
   * downloadZip
   */
  downloadZip(): void {
    let promise;
    const bookingId = this.bookingId ?? `${this.footerOptions?.domainId}${this.footerOptions?.objectId}`; 
    if (this.currentPageName === 'photos') {
      promise = firstValueFrom(this.inspectionService.getAllPhotosDownloadURL(this.inspectionId, bookingId))
    } else if (this.currentPageName === 'documents' || this.currentPageName === 'document') {
      promise = firstValueFrom(this.mediaManagementService.getAllDocumentDownloadURL(this.bookingId, this.footerOptions?.domainId))
    }
    if (promise) {
      this.commonService.showLoading();
      promise.then((response) => {
        this.commonService.hideLoading();
        // console.log('URL is', response?.data)
        saveAs(response?.data)
      }).catch((err) => {
        this.commonService.hideLoading();
        this.monitorService.logException(err, SeverityLevel.Error);
      });
    }

  }

  /**
   * return if step is mandatory
   * @param step 
   */
  isStepMandatory(step): boolean {
    if(this.currentPageName === 'document') {
      return step.mandatory;
    } else {
      return !step.isSkipEnabled;
    }
  }

  /**
   * return if any one step is mandatory
   * @param step 
   */
  isMandatory(): boolean {
    if(this.currentPageName === 'document') {
      return this.photos?.some(x => x.mandatory);
    } else {
      return this.photos?.some(x => !x.isSkipEnabled);
    }
  }
}
